<template>
  <div class="header">
    <div class="container-fluid">
      <a href="javascript:void(0);"><img class="ps-md-3" src="./../../assets/images/logo/logo-white.png"></a>
    </div>
  </div>
  <div class="content">
    <div class="row gx-0 align-items-center">
      <div class="col-md-6">
        <form @submit.prevent="loginUser">
          <h2>{{ $t('welcomeBack') }}</h2>
          <h3>{{ $t('login') }}</h3>
          <div class="email">
            <label>{{ $t('emailAddress') }}<sup>*</sup></label>
            <input v-model="form.email" type="email" required>
          </div>
          <div class="password">
            <label>{{ $t('password') }}<sup>*</sup></label>
            <input v-model="form.password" type="password" required>
          </div>
          <router-link :to="{ name : 'forget' }">{{ $t('forgetPassword') }}?</router-link>
          <button :disabled='processing' type="submit">
            <span v-if="processing">{{ $t('pleaseWait') }}</span>
            <span v-else>{{ $t('login') }}</span>
          </button>
        </form>
      </div>
      <div class="col-md-6 overflow-hidden d-none d-md-block">
        <div class="slider-parent">
          <input type="radio" value="" name="slider" id="no1" :checked="current === 'sales'">
          <label for="no1"></label>
          <input type="radio" value="" name="slider" id="no2" :checked="current === 'stats'">
          <label for="no2"></label>
          <input type="radio" value="" name="slider" id="no3" :checked="current === 'customers'">
          <label for="no3"></label>
          <div class="slider-child">
            <div class="content" style="width: 50%">
              <h2>Likvio - Allt i ett för ekonomin</h2>
              <p>Likvio ger dig möjligheten att sköta ekonomin i en modern och lättnavigerad portal, samt automatiser
                stora delar av den annars manuella avtalsfaktureringen.</p><img
                src="./../../assets/images/login/slide1.png">
            </div>
            <div class="content" style="width: 50%">
              <h2>Lättförstådda grafer och statistik</h2>
              <p>Likvio ger dig tillgång till övergriplig statistik i färgglada och informativa grafer. Se historisk
                försäljning, vilka artiklar som har högst vinstmarginaler och vilka kunder som ofta är sena med att
                betala fakturor.</p><img src="./../../assets/images/login/slide2.png">
            </div>
            <div class="content" style="width: 50%">
              <h2>Kund- och artikelspecifika segment</h2>
              <p>Skapa segment av kunder och/eller artiklar för att enkelt se över och hantera specifika grupper av
                produkter och kunder på samma gång.</p><img src="./../../assets/images/login/slide3.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NotificationModalVue/>
</template>

<script>
import {mapGetters} from 'vuex'
import NotificationModalVue from '../../components/NotificationModal.vue'

export default {
  components: {NotificationModalVue},
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      processing: false,
      current: 'sales',
      slide: ['sales', 'stats', 'customers']
    }
  },
  computed: {
    ...mapGetters({
      USER_TOKEN: 'auth/USER_TOKEN'
    })
  },
  mounted() {
    if (this.USER_TOKEN) {
      this.$router.replace({name: 'dashboard'})
    }
    let self = this
    setInterval(function () {
      self.setCurrent()
    }, 3000)
  },
  methods: {
    loginUser() {
      if (this.form.email === '' || this.form.password === '') {
        // this.$services.helpers.notification(this.$t('all_fields_are_required'), 'error', this)
        this.$toast.warning(this.$t('all_fields_are_required'));
      } else {
        this.processing = true
        const payload = {
          email: this.form.email,
          password: this.form.password
        }
        this.$store.dispatch('auth/loginUser', payload)
            .then(res => {
              this.processing = false
              // this.$services.helpers.notification(this.$t('login_successfully'), 'success', this)
              this.$toast.success(this.$t('login_successfully'));
              setTimeout(() => {
                this.$router.replace({name: 'dashboard'})
              }, 2000)
            })
            .catch(err => {
              this.processing = false
              // this.$services.helpers.notification(this.$t('loginError'), 'error', this)
              //this.$toast.error(this.$t('loginError'));
              this.$toast.warning(err.response.data.message);
            })
      }
    },
    setCurrent() {
      let currentIndex = this.slide.indexOf(this.current)
      if (currentIndex === this.slide.length - 1) {
        this.current = 'sales'
      } else {
        this.current = this.slide[currentIndex + 1]
      }
    }
  }
}
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
* {
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
} */

.header {
  padding: 10px 0;
  background: #333269;
  border-radius: 0px;
  max-width: 100%;
}

.content form {
  width: 80%;
  margin: 0 auto;
  padding: 50px 45px;
}

@media (max-width: 991.98px) {
  .content form {
    width: 95%;
  }
}

.content form h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #333269;
}

.content form h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
  margin-bottom: 50px;
}

.content form .password,
.content form .email {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content form label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-bottom: 10px;
}

.content form label sup {
  color: #fb4b4b;
  margin-left: 3px;
}

.content form input {
  background: #f0eff5;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 6px;
  margin-bottom: 20px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  text-indent: 10px;
}

.content form input:focus {
  border: none;
  outline: none;
}

.content form a {
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content form a:hover {
  color: #4947b0;
}

.content form button {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 36px;
  background: #5855d6;
  border-radius: 4px;
  color: #ffffff;
  height: 55px;
  text-align: center;
  border: none;
  margin: 20px 0;
  display: block;
  width: 100%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .slider-parent {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.content .slider-parent input {
  display: none;
}

.content .slider-parent input:checked + label {
  background: #fffdfd;
}

.content .slider-parent input:checked:nth-of-type(1) ~ .slider-child {
  left: 0;
}

.content .slider-parent input:checked:nth-of-type(2) ~ .slider-child {
  left: -100%;
}

.content .slider-parent input:checked:nth-of-type(3) ~ .slider-child {
  left: -199%;
}

.content .slider-parent label {
  height: 15px;
  width: 15px;
  background-color: rgba(192, 190, 255, 0.53);
  border-radius: 50%;
  z-index: 33;
  cursor: pointer;
  -webkit-transition: background 0.4s ease-out;
  -o-transition: background 0.4s ease-out;
  transition: background 0.4s ease-out;
  position: absolute;
  bottom: 25px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.content .slider-parent label:nth-of-type(1) {
  left: -webkit-calc(50% - 25px);
  left: calc(50% - 25px);
}

.content .slider-parent label:nth-of-type(2) {
  left: 50%;
}

.content .slider-parent label:nth-of-type(3) {
  left: -webkit-calc(50% + 25px);
  left: calc(50% + 25px);
}

.content .slider-parent label:hover {
  background: #fffdfd;
}

.content .slider-parent .slider-child {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 300%;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .slider-parent .slider-child .content {
  background-image: url("./../../assets/images/login/New Project.png");
  padding: 50px 45px;
  background-size: cover;
  background-position: center;
  padding-bottom: 75px;
}

.content .slider-parent .slider-child .content h2 {
  font-weight: bold;
  font-size: 42px;
  line-height: 58px;
  color: #ffffff;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .content .slider-parent .slider-child .content h2 {
    font-size: 38px;
    line-height: 54px;
    margin-bottom: 20px;
  }
}

.content .slider-parent .slider-child .content p {
  font-size: 18.3904px;
  line-height: 29px;
  color: #cbd4e1;
  margin-bottom: 30px;
}

.content .slider-parent .slider-child .content img {
  max-width: 100%;
}
</style>