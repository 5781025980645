import Landing from "@/views/Landing/Landing";
import Login from "@/views/Auth/Login";
import ForgetPassword from "@/views/Auth/ForgetPassword";
import ResetPassword from "@/views/Auth/ResetPassword";
import CompleteProfile from "@/views/Auth/CompleteProfile";
import AccessDeniedPage from "../../views/AccessPage/AccessDeniedPage";
import MainComponent from "@/views/Clients/Main";

let routes = [
    {
        path: "",
        name: "index-page",
        component: Landing,
        meta: {
            title: "Likvio",
            page: "Likvio",
        },
    },
    {
        path: "/blog",
        name: "blog-page",
        meta: {
            title: "Likvio Blog",
            page: "Likvio",
        },
        component: () =>
            import(/* webpackChunkName: "chunk" */ "@/views/Landing/Blog/Blog"),
    },

    {
        path: "/blog/:id",
        name: "blog-detail-page",
        meta: {
            title: "Likvio Blog",
            page: "Likvio",
        },
        component: () =>
            import(/* webpackChunkName: "chunk" */ "@/views/Landing/Blog/BlogDetail"),
    },

    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            title: "Sign In",
            page: "Likvio",
        },
    },
    {
        path: "/forget-password",
        name: "forget",
        component: ForgetPassword,
        meta: {
            title: "Request Password link",
            page: "Likvio",
        },
    },
    {
        path: "/reset-password",
        name: "reset",
        component: ResetPassword,
        meta: {
            title: "Reset Password",
            page: "Likvio",
        },
    },
    {
        path: "/complete-profile",
        name: "finalize",
        component: CompleteProfile,
        meta: {
            title: "Complete Your Account",
            page: "Likvio",
        },
    },
    {
        path: "/access-denied",
        name: "access-denied",
        component: AccessDeniedPage,
        meta: {
            title: "Access denied page",
            page: "Likvio Access denied ",
        },
    },
    {
        path: "/coming-soon",
        name: "coming-soon",
        meta: {},
        component: () =>
            import(/* webpackChunkName: "chunk" */ "@/views/ComingSoon/ComingSoon"),
    },
    {
        path: "/main",
        meta: {requireAuth: true},
        component: MainComponent,
        children: [
            {
                path: "",
                name: "dashboard",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Dashboard"),
            },
            {
                path: "faq",
                name: "faq",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/FAQ"),
            },

            {
                path: "support",
                name: "support",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Support"),
            },

            {
                path: "alert-inbox",
                name: "alertinbox",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/AlertInbox"),
            },
            {
                path: "alert-message/:id",
                name: "alertmessage",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/AlertFullMessage"
                        ),
            },
            {
                path: "alert-archived",
                name: "alertarchived",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/AlertArchived"
                        ),
            },
            {
                path: "event-logs",
                name: "eventlogs",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/EventLog"),
            },
            {
                path: "event-message/:id",
                name: "eventmessages",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/EventFullLog"
                        ),
            },
            {
                path: "customers",
                name: "customers",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Customers"),
            },
            {
                path: "create-customers",
                name: "createCustomers",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerCreateBasic"
                        ),
            },
            {
                path: "edit-customers/:id",
                name: "editCustomers",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerEditBasic"
                        ),
            },
            {
                path: "create-customer-invoice",
                name: "createCustomerInvoice",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerInvoiceCreate"
                        ),
            },
            {
                path: "edit-customer-invoice",
                name: "editInvoice",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerInvoiceEdit"
                        ),
            },
            {
                path: "contracts",
                name: "contracts",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Contracts"),
            },
            {
                path: "create-contract",
                name: "createContract",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CreateContract"
                        ),
            },
            {
                path: "edit-contract/:num",
                name: "editContract",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/EditContract"
                        ),
            },
            {
                path: "recurring-contract-update",
                name: "recurring-contract-update",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/recurringContractUpdate"
                        ),
            },
            {
                path: "video-guides",
                name: "video-guides",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/videoGuides"
                        ),
            },
            {
                path: "users",
                name: "users",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Users"),
            },
            {
                path: "account-settings",
                name: "accountSettings",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/UserAccountSettings"
                        ),
            },
            {
                path: "configurations",
                name: "configurations",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/Configurations"
                        ),
            },
            {
                path: "invoice-configuration-guide",
                name: "invoiceGuide",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ConfigInvoiceGuide"
                        ),
            },
            {
                path: "contracts-configuration-guide",
                name: "contractGuide",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ConfigContractGuide"
                        ),
            },
            {
                path: "invoices",
                name: "invoices",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Invoices"),
            },
            {
                path: "invoices-fortnox",
                name: "invoicesFortnox",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/InvoiceFortnox"
                        ),
            },
            {
                path: "create-invoice",
                name: "createInvoice",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/InvoiceCreate"
                        ),
            },
            {
                path: "edit-invoice/:id",
                name: "editInvoice",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/InvoiceEdit"),
            },
            {
                path: "articles",
                name: "products",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Products"),
            },
            {
                path: "add-article-basic",
                name: "addproductbasic",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductAddBasic"
                        ),
            },
            // {
            //     path: 'add-article-stock',
            //     name: 'addproductstock',
            //     meta: { },
            //     component: () => import(/* webpackChunkName: "chunk" */ '@/views/Clients/ProductAddStock')
            // },
            {
                path: "update-article-basic/:id",
                name: "editproductbasic",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductEditBasic"
                        ),
            },
            // {
            //     path: 'update-article-stock/:id',
            //     name: 'editproductstock',
            //     meta: { },
            //     component: () => import(/* webpackChunkName: "chunk" */ '@/views/Clients/ProductEditStock')
            // },
            {
                path: "plan-details",
                name: "planDetails",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/BillingPlanDetails"
                        ),
            },
            {
                path: "billing-cancellation",
                name: "billingCancel",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/BillingCancellation"
                        ),
            },
            {
                path: "scheduled",
                name: "scheduled",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Scheduled"),
            },
            {
                path: "billings",
                name: "billing",
                meta: {},
                component: () =>
                    import(/* webpackChunkName: "chunk" */ "@/views/Clients/Billing"),
            },
            {
                path: "product-segment",
                name: "productSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductSegmentBox"
                        ),
            },
            {
                path: "edit-product-segment/:id",
                name: "editProductSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductSegmentEditBox"
                        ),
            },
            {
                path: "product-segment/:id/add-products",
                name: "addProductToSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/AddProductSegment"
                        ),
            },
            {
                path: "edit-product-segment-all/:id",
                name: "editProductSegmentAll",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductSegmentEditAll"
                        ),
            },
            {
                path: "create-product-segment",
                name: "createProductSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/ProductSegmentCreate"
                        ),
            },
            {
                path: "customer-segment",
                name: "customersegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegment"
                        ),
            },
            {
                path: "all-customer-segment",
                name: "allcustomersegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegmentAll"
                        ),
            },
            {
                path: "edit-customer-segment/:id",
                name: "editCustomerSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegmentEdit"
                        ),
            },
            {
                path: "edit-customer-segment-settings/:id",
                name: "editCustomerSegmentSettings",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegmentEditSetting"
                        ),
            },
            {
                path: "customer-segment/:id/add-customers",
                name: "customerSegmentAddCustomer",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegmentUpdate"
                        ),
            },
            {
                path: "create-customer-segment",
                name: "createCustomerSegment",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/CustomerSegmentCreate"
                        ),
            },

            {
                path: "statistics",
                name: "statistics",
                meta: {},
                component: () =>
                    import(
                        /* webpackChunkName: "chunk" */ "@/views/Clients/Statistics/Statistics"
                        ),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () =>
            import(/* webpackChunkName: "chunk" */ "@/views/Unavailable/404"),
        meta: {
            title: "Unavailable | Likvio",
        },
    },
];

export default routes;
