export default {
    UPDATE_CURRENT_USER(state, payload) {
        state.currentUser = payload.data;
    },
    UPDATE_USERS(state, payload) {
        state.users = payload;
    },
    UPDATE_CONTRACTS(state, payload) {
        if (payload.data) {
            payload.data.forEach((element) => {
                element.checked = false;
            });
        }
        state.contracts = payload;
        state.contractsCopy = payload;
    },
    UPDATE_TAGS(state, payload) {
        state.Tags = payload;
    },
    TOGGLE_ALL_CONTRACT_CHECKBOX(state, payload) {
        state.contracts.data.forEach((element) => {
            element.checked = payload;
        });
    },
    TOGGLE_CONTRACT_CHECKBOX(state, payload) {
        const newContractIndex = state.contracts.data.findIndex(
            (el) => el.id === payload.id
        );
        const newContractArray = [...state.contracts.data];
        newContractArray[newContractIndex] = {
            ...newContractArray[newContractIndex],
            checked: payload.value,
        };
        state.contracts.data = newContractArray;
    },
    UPDATE_SINGLE_CONTRACTS(state, payload) {
        state.singleContract = payload.data;
    },
    UPDATE_CUSTOMERS(state, payload) {
        if (payload.data) {
            payload.data.forEach((element) => {
                element.checked = false;
            });
        }
        state.customers = payload;
        state.customersCopy = payload;
    },
    GET_SCHEDULES(state, payload) {
        state.schedules = payload;
    },
    TOGGLE_ALL_CUSTOMER_CHECKBOX(state, payload) {
        state.customers.data.forEach((element) => {
            element.checked = payload;
        });
    },
    TOGGLE_CUSTOMER_CHECKBOX(state, payload) {
        const newCustomerIndex = state.customers.data.findIndex(
            (el) => el.id === payload.id
        );
        const newCustomerArray = [...state.customers.data];
        newCustomerArray[newCustomerIndex] = {
            ...newCustomerArray[newCustomerIndex],
            checked: payload.value,
        };
        state.customers.data = newCustomerArray;
    },
    UPDATE_SINGLE_CUSTOMER(state, payload) {
        state.singleCustomer = payload.data;
    },
    UPDATE_INVOICE_FORTNOX(state, payload) {
        if (payload.results) {
            payload.results.forEach((element) => {
                element.checked = false;
            });
        }
        state.invoiceFortnox = payload;
        // state.invoiceFortnox = payload.data
    },
    UPDATE_INVOICE(state, payload) {
        if (payload.data) {
            payload.data.forEach((element) => {
                element.checked = false;
            });
        }
        state.invoice = payload;
        state.invoiceCopy = payload;
    },
    TOGGLE_ALL_INVOICE_CHECKBOX(state, payload) {
        state.invoice.data.forEach((element) => {
            element.checked = payload;
        });
    },
    TOGGLE_INVOICE_CHECKBOX(state, payload) {
        const newInvoiceIndex = state.invoice.data.findIndex(
            (el) => el.id === payload.id
        );
        const newInvoiceArray = [...state.invoice.data];
        newInvoiceArray[newInvoiceIndex] = {
            ...newInvoiceArray[newInvoiceIndex],
            checked: payload.value,
        };
        state.invoice.data = newInvoiceArray;
    },
    UPDATE_SINGLE_INVOICE(state, payload) {
        state.singleInvoice = payload.data;
    },
    UPDATE_BILLING(state, payload) {
        state.billing = payload.data;
    },
    UPDATE_BILLING_HISTORY(state, payload) {
        state.billingHistory = payload.data;
    },
    UPDATE_USER_SUBSCRIPTION(state, payload) {
        state.userSubscription = payload.data;
    },
    UPDATE_NOTIFICATION_ALERT(state, payload) {
        state.notificationAlert = payload.data;
    },
    UPDATE_NOTIFICATION_EVENTS(state, payload) {
        state.notificationEvent = payload.data;
    },
    UPDATE_SINGLE_EVENTS(state, payload) {
        state.singleNotificationEvent = payload.data;
    },
    UPDATE_ARTICLES(state, payload) {
        if (payload.data) {
            payload.data.forEach((element) => {
                element.checked = false;
            });
        }
        state.productArticles = payload;
        state.productArticlesCopy = payload;
    },
    TOGGLE_ALL_ARTICLE_CHECKBOX(state, payload) {
        state.productArticles.data.forEach((element) => {
            element.checked = payload;
        });
    },
    TOGGLE_ARTICLE_CHECKBOX(state, payload) {
        const newArticleIndex = state.productArticles.data.findIndex(
            (el) => el.article_number === payload.id
        );
        const newArticleArray = [...state.productArticles.data];
        newArticleArray[newArticleIndex] = {
            ...newArticleArray[newArticleIndex],
            checked: payload.value,
        };
        state.productArticles.data = newArticleArray;
    },
    UPDATE_SINGLE_ARTICLE(state, payload) {
        state.singleArticle = payload.data;
    },
    UPDATE_SINGLE_NOTIFICATION_ALERT(state, payload) {
        state.singleAlertMessage = payload.data;
    },
    UPDATE_CONTRACT_CONFIG(state, payload) {
        state.contractConfig = payload;
    },
    UPDATE_INVOICE_CONFIG(state, payload) {
        state.invoiceConfig = payload;
    },
    UPDATE_SEGMENT_ARTICLES(state, payload) {
        state.articleSegment = payload;
    },
    UPDATE_SEGMENT_ARTICLE_INFO(state, payload) {
        state.articleSegmentInfo = payload;
    },
    UPDATE_CUSTOMER_SEGMENT(state, payload) {
        state.customerSegments = payload;
    },
    UPDATE_SINGLE_CUSTOMER_SEGMENT(state, payload) {
        state.singleCustomerSegment = payload;
    },
    UPDATE_COST_CENTER(state, payload) {
        state.costCenter = payload;
    },
    UPDATE_CURRENCY(state, payload) {
        state.currency = payload;
    },
    UPDATE_PRICELIST(state, payload) {
        state.priceList = payload;
    },
    UPDATE_TERMS_OF_DELIVERY(state, payload) {
        state.termOfDelivery = payload;
    },
    UPDATE_TERMS_OF_PAYMENT(state, payload) {
        state.termOfPayment = payload;
    },
    UPDATE_WAY_OF_DELIVERY(state, payload) {
        state.wayOfDelivery = payload;
    },
    UPDATE_PROJECT(state, payload) {
        state.projects = payload;
    },
    UPDATE_NOTIFICATION(state, payload) {
        state.notification = payload;
    },
    UPDATE_CURRENT_CUSTOMER(state, payload) {
        state.currentCustomer = payload;
    },

    SORT_ASC(state, payload) {
        let stateList = {
            contract: state.contracts.data,
            contractCopy: state.contractsCopy.data,
            invoice: state.invoice.data,
            invoiceCopy: state.invoiceCopy.data,
            customers: state.customers.data,
            customersCopy: state.customersCopy.data,
            product: state.productArticles.data,
            productCopy: state.productArticlesCopy.data,
        };
        if (stateList[payload["copy"]]) {
            if (stateList[payload["copy"]].length > 0) {
                let single = stateList[payload["copy"]][0];
                let sortValue = single[payload["item"]];
                if (typeof sortValue === "number") {
                    stateList[payload["state"]] = stateList[payload["copy"]].sort(
                        (a, b) => {
                            return a[payload["item"]] - b[payload["item"]];
                        }
                    );
                } else if (typeof sortValue === "string") {
                    if (isNaN(parseInt(sortValue))) {
                        stateList[payload["state"]] = stateList[payload["copy"]].sort(
                            (a, b) => {
                                return a[payload["item"]] === b[payload["item"]]
                                    ? 0
                                    : a[payload["item"]] > b[payload["item"]]
                                        ? 1
                                        : -1;
                            }
                        );
                    } else {
                        stateList[payload["state"]] = stateList[payload["copy"]].sort(
                            (a, b) => {
                                return a[payload["item"]] - b[payload["item"]];
                            }
                        );
                    }
                } else if (typeof sortValue === "object") {
                    let filter = stateList[payload["copy"]].filter(
                        (item) => item[payload["item"]] !== null
                    );
                    stateList[payload["state"]] = filter.sort((a, b) => {
                        return a[payload["item"]].name === b[payload["item"]].name
                            ? 0
                            : a[payload["item"]].name > b[payload["item"]].name
                                ? 1
                                : -1;
                    });
                } else {
                    stateList[payload["state"]] = stateList[payload["copy"]];
                }
            }
        }
    },
    SORT_DESC(state, payload) {
        let stateList = {
            contract: state.contracts.data,
            contractCopy: state.contractsCopy.data,
            invoice: state.invoice.data,
            invoiceCopy: state.invoiceCopy.data,
            customers: state.customers.data,
            customersCopy: state.customersCopy.data,
            product: state.productArticles.data,
            productCopy: state.productArticlesCopy.data,
        };
        if (stateList[payload["copy"]]) {
            if (stateList[payload["copy"]].length > 0) {
                let single = stateList[payload["copy"]][0];
                let sortValue = single[payload["item"]];
                if (typeof sortValue === "number") {
                    stateList[payload["state"]] = stateList[payload["copy"]].sort(
                        (a, b) => {
                            return b[payload["item"]] - a[payload["item"]];
                        }
                    );
                } else if (typeof sortValue === "string") {
                    if (isNaN(parseInt(sortValue))) {
                        stateList[payload["state"]] = stateList[payload["copy"]].sort(
                            (a, b) => {
                                return a[payload["item"]] === b[payload["item"]]
                                    ? 0
                                    : a[payload["item"]] < b[payload["item"]]
                                        ? 1
                                        : -1;
                            }
                        );
                    } else {
                        stateList[payload["state"]] = stateList[payload["copy"]].sort(
                            (a, b) => {
                                return b[payload["item"]] - a[payload["item"]];
                            }
                        );
                    }
                } else if (typeof sortValue === "object") {
                    let filter = stateList[payload["copy"]].filter(
                        (item) => item[payload["item"]] !== null
                    );
                    stateList[payload["state"]] = filter.sort((a, b) => {
                        return a[payload["item"]].name === b[payload["item"]].name
                            ? 0
                            : a[payload["item"]].name < b[payload["item"]].name
                                ? 1
                                : -1;
                    });
                } else {
                    stateList[payload["state"]] = stateList[payload["copy"]];
                }
            }
        }
    },
    UPDATE_PLAN(state, payload) {
        state.planDetails = payload.results[0];
    },
    UPDATE_SUBSCRIPTION_PACKAGE(state, payload) {
        state.subscriptionPackages = payload.results;
    },
    UPDATED_SCHEDULE_LIST(state, payload) {
        state.scheduleList.results = payload.data
    },
    UPDATE_CONTRACT_FILTER(state, payload) {
        state.contractFilter = {...state.contractFilter, ...payload}
    },
    UPDATE_CUSTOMER_FILTER(state, payload) {
        state.customerFilter = {...state.customerFilter, ...payload}
    },
    UPDATE_INVOICE_FILTER(state, payload) {
        state.invoiceFilter = {...state.invoiceFilter, ...payload}
    },
    UPDATE_SCHEDULE_FILTER(state, payload) {
        state.scheduledFilter = {...state.scheduledFilter, ...payload}
    },
    UPDATE_ARTICLE_FILTER(state, payload) {
        state.articleFilter = {...state.articleFilter, ...payload}
    },
     UPDATE_RECURRING_CONTRACT_FILTER(state, payload) {
        state.recurringUpdateFilter = {...state.recurringUpdateFilter, ...payload}
    },
    CLEAR_FILTER(state, payload) {
        if (payload === 'invoice') {
            state.invoiceFilter = {}
        } else if (payload === 'customer') {
            state.customerFilter = {}
        } else if (payload === 'recurring-contract-update') {
            state.recurringUpdateFilter = {}
        } else if (payload === 'scheduled') {
            state.scheduledFilter = {}
        } else if (payload === 'article') {
            state.articleFilter = {}
        } else {
            state.contractFilter = {}
        }

    },
};
