<template>
    <div class="header">
      <div class="container-fluid"><a href="../../index.html"><img class="ps-md-3" src="./../../assets/images/logo/logo-white.png"></a></div>
    </div>
    <div class="content">
      <div class="container">
        <form @submit.prevent="initialiseForgetPassword">
            <router-link :to="{ name : 'login' }"> 
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z" fill="#5855D6"></path>
            </svg>{{ $t('go_to_login') }}
            </router-link>
          <h2>{{ $t('resetYourPassword') }}</h2>
          <p>{{ $t('reset_password_text') }}</p>
          <div class="email">
            <label>{{ $t('emailAddress') }}<sup>*</sup></label>
            <input type="email" v-model="email" required>
          </div>
          <button type="submit" :disabled="processing">
              <span v-if="processing">{{ $t('sending_email') }}</span>
              <span v-else>{{ $t('send_email') }}</span>
              
            </button>
        </form>
      </div>
    </div>
    <NotificationModalVue />
</template>

<script>
import NotificationModalVue from '../../components/NotificationModal.vue'

export default {
    name : 'ForgetPassword',
    components : { NotificationModalVue },
    data () {
        return {
            email : '',
            processing : false
        }
    },
    methods : {
        initialiseForgetPassword () {
            if (this.email === '') {
                // this.$services.helpers.notification('Email address is required', 'error', this)
                this.$toast.warning(this.$t('email_required'));
            } else if (!this.$services.helpers.validateEmailAddress(this.email)) {
                // this.$services.helpers.notification('Invalid email address', 'error', this)
                this.$toast.error(this.$t('invalid_email'));
            } else {
                this.processing = true
                this.$store.dispatch('auth/forgetUserPassword', { email : this.email })
                .then(_ => {
                    this.processing = false
                    // this.$services.helpers.notification('Confirmation link sent', 'success', this)
                    this.$toast.success(this.$t('email_sent'));
                    setTimeout(() => {
                        this.$router.replace({ name : 'login' })
                    }, 2000)
                })
                .catch(err => {
                    this.processing = false
                    this.$services.helpers.notification(err.response.data.message, 'error', this)
                })
            }
        }
    }
}
</script>

<style scoped>
.header {
  padding: 10px 0;
  background: #333269;
  border-radius: 0px;
  max-width: 100%;
}

.content form {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 50%;
  margin: 50px auto;
  padding: 50px 45px;
}
@media (max-width: 991.98px) {
  .content form {
    width: 65%;
  }
}
@media (max-width: 767.98px) {
  .content form {
    width: 95%;
  }
}
.content form > a {
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.content form > a svg {
  margin-bottom: 2px;
}
.content form > a svg path {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.content form > a:hover {
  color: #4947b0;
}
.content form > a:hover svg path {
  fill: #4947b0;
}
.content form h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #333269;
}
.content form h2 ~ p {
  font-size: 18px;
  line-height: 28px;
  color: #64748b;
  margin-bottom: 50px;
  display: block;
}
.content form .email {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.content form label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-bottom: 10px;
}
.content form label sup {
  color: #fb4b4b;
  margin-left: 3px;
}
.content form input {
  background: #f0eff5;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 6px;
  margin-bottom: 20px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  text-indent: 10px;
}
.content form input:focus {
  border: none;
  outline: none;
}
.content form button {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 36px;
  background: #5855d6;
  border-radius: 4px;
  color: #ffffff;
  height: 55px;
  text-align: center;
  border: none;
  margin: 20px 0;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  display: block;
  width: 100%;
}
.content form button:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>