export default {
    GET_CURRENT_USER(state) {
        return state.currentUser;
    },
    GET_USERS(state) {
        return state.users;
    },
    GET_CUSTOMERS(state) {
        return state.customers;
    },
    GET_SCHEDULES(state) {
        return state.schedules;
    },
    GET_SINGLE_CUSTOMER(state) {
        return state.singleCustomer;
    },
    GET_CONTRACTS(state) {
        return state.contracts;
    },
    GET_SINGLE_CONTRACT(state) {
        return state.singleContract;
    },
    GET_INVOICE_FORTNOX(state) {
        return state.invoiceFortnox;
    },
    GET_INVOICE(state) {
        return state.invoice;
    },
    GET_SINGLE_INVOICE(state) {
        return state.singleInvoice;
    },
    GET_BILLING(state) {
        return state.billing;
    },
    GET_BILLING_HISTORY(state) {
        return state.billingHistory;
    },
    GET_USER_SUBSCRIPTION(state) {
        return state.userSubscription;
    },
    GET_NOTIFY_ALERT(state) {
        return state.notificationAlert;
    },
    GET_NOTIFY_ALERT_READ_STATUS(state) {
        return state.notificationAlert.filter((item) => item.read === false);
    },
    GET_SINGLE_EVENT(state) {
        return state.singleNotificationEvent;
    },
    GET_NOTIFY_EVENT(state) {
        return state.notificationEvent;
    },
    GET_ARTICLES(state) {
        return state.productArticles;
    },
    GET_SINGLE_ARTICLE(state) {
        return state.singleArticle;
    },
    GET_SINGLE_ALERT_MESSAGE(state) {
        return state.singleAlertMessage;
    },
    GET_CONFIG_CONTRACT_CONFIG(state) {
        return state.contractConfig;
    },
    GET_CONFIG_INVOICE_CONFIG(state) {
        return state.invoiceConfig;
    },
    GET_SEGMENT_ARTICLE(state) {
        return state.articleSegment;
    },
    GET_SEGMENT_ARTICLE_INFO(state) {
        return state.articleSegmentInfo;
    },
    GET_CUSTOMER_SEGMENT(state) {
        return state.customerSegments;
    },
    GET_SINGLE_CUSTOMER_SEGMENT(state) {
        return state.singleCustomerSegment;
    },
    GET_COST_CENTER(state) {
        return state.costCenter;
    },
    GET_CURRENCY(state) {
        return state.currency;
    },
    GET_PRICELIST(state) {
        return state.priceList;
    },
    GET_TERM_OF_DELIVERY(state) {
        return state.termOfDelivery;
    },
    GET_TERM_OF_PAYMENT(state) {
        return state.termOfPayment;
    },
    GET_WAY_OF_DELIVERY(state) {
        return state.wayOfDelivery;
    },
    GET_PROJECT(state) {
        return state.projects;
    },
    GET_CONTRACT_CHECKED(state) {
        if (state.contracts.data) {
            const filter = state.contracts.data.filter(
                (item) => item.checked === true
            );
            if (filter.length > 0) {
                return {state: true, data: filter};
            } else {
                return {status: false, data: []};
            }
        }
    },
    GET_CUSTOMER_CHECKED(state) {
        if (state.customers.data) {
            const filter = state.customers.data.filter(
                (item) => item.checked === true
            );
            if (filter.length > 0) {
                return {state: true, data: filter};
            } else {
                return {status: false, data: []};
            }
        }
    },
    GET_INVOICE_CHECKED(state) {
        if (state.invoice.data) {
            const filter = state.invoice.data.filter((item) => item.checked === true);
            if (filter.length > 0) {
                return {state: true, data: filter};
            } else {
                return {status: false, data: []};
            }
        }
    },
    GET_ARTICLE_CHECKED(state) {
        if (state.productArticles.data) {
            const filter = state.productArticles.data.filter(
                (item) => item.checked === true
            );
            if (filter.length > 0) {
                return {state: true, data: filter};
            } else {
                return {status: false, data: []};
            }
        }
    },
    GET_INVOICE_FORTNOX_CHECKED(state) {
        if (state.invoiceFortnox.results) {
            const filter = state.invoiceFortnox.results.filter(
                (item) => item.checked === true
            );
            if (filter.length > 0) {
                return {state: true, data: filter};
            } else {
                return {status: false, data: []};
            }
        }
    },
    GET_TAGS(state) {
        return state.Tags;
    },
    GET_PLAN_DETAIL(state) {
        return state.planDetails;
    },
    GET_SUBSCRIPTION_PACKAGE(state) {
        return state.subscriptionPackages;
    },
    GET_VAT_CONSTANT(state) {
        return state.vatConstant;
    },
    GET_CURRENT_CUSTOMER(state) {
        return state.currentCustomer;
    },
    GET_SCHEDULES_LIST(state) {
        return state.scheduleList
    },
    GET_CONTRACT_FILTER(state) {
        return state.contractFilter
    },
    GET_CUSTOMER_FILTER(state) {
        return state.customerFilter
    },
    GET_INVOICE_FILTER(state) {
        return state.invoiceFilter
    },
    GET_SCHEDULE_FILTER(state) {
        return state.scheduledFilter
    },
    GET_ARTICLE_FILTER(state) {
        return state.articleFilter
    },
    GET_RECURRING_CONTRACT_FILTER(state) {
        return state.recurringUpdateFilter
    },
};
