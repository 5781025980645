import {createI18n} from "vue-i18n";

const messages = {
    en: {
        hello: "Hello",
        welcomeBack: "Welcome Back",
        go_to_login: "Go to the login page",
        loginError: "Login credentials are invalid",
        fieldRequired: "All fields are required",
        emailAddress: "Email Address",
        password: "Password",
        forgetPassword: "Forgot your Password",
        pleaseWait: "Please wait...",
        login: "Login",
        resetYourPassword: "Reset your password",
        reset_password: "Reset password",
        enter_password: "Skriv in ditt nya lösenord",
        reset_password_text:
            "Please input the email address connected to your Likvio account. We will send you an email with a link to reset your password",
        send_email: "Send email",
        sending_email: "Sending email",
        homePage: "Home Page",
        contracts: "Contracts",
        schedules: "Scheduled events",
        customers: "Customers",
        customer: "Customer",
        customerSegment: "Customer Segment",
        invoices: "Invoices",
        noxFinanceInvoice: "Nox Finance Invoice",
        products: "Products",
        productSegment: "Product Segment",
        statisticsData: "Statistics/Data",
        billing: "Billing Page",
        scheduled: "Scheduled",
        settings: "Settings",
        configuration: "Configuration",
        users: "Users",
        re_auth_text:
            " Unfortunately the connection to Fortnox has been interrupted, which means that Likvio cannot access your Fortnox\n" +
            "        data. Kindly click here to re-connect to Fortnox to avoid further interruptions/deviations in this service. We\n" +
            "        apologies for the inconvenience",
        re_connect_now: "Re-connect now",
        package_restriction_text:
            "Your current subscription plan does not include access to this module. Click the Upgrade button below to get the subscription management page.",
        go_back: "Go to Home",
        upgrade_plan: "Upgrade Plan",
        no_permission: "You are not permitted to access this page.",
        take_me_back: "Go to Home.",
        free_offer: "We offer a 7 days free trial of any of our premium packages",
        billed_since: "Billed since",
        received_payment_since: "Received payments since",
        latest_event: "Latest event",
        contract_close_to_ending: "Contracts close to ending",
        contract: "Contract",
        end_date: "End Date",
        alerts: "Alerts",
        invoice_left: "Invoice Left",
        type: "Type",
        filter: "Filter",
        clearFilter: "Clear Filter",
        event: "Event",
        occurred_at: "Occurred at",
        see_all_contract: "See All Contracts",
        see_all_events: "See All Events",
        see_all_alerts: "See All Alerts",
        no_unarchived_alert: "You currently do not have any unarchived alerts",
        no_latest_contract_ending:
            "You currently do not have any contracts close to ending",
        no_latest_event: "No event currently recorded on your account",
        upcoming: "Upcoming",
        active: "Active",
        inactive: "Inactive",
        terminated: "Terminated",
        search: "Search",
        customize: "Customize",
        all: "All",
        eventType: "Event type",
        handled: "Already handled",
        applyFilter: "Apply filter",
        selectType: "Select type of event",
        contractCancel: "Contract cancellation",
        contractPriceChange: "Price update in contract",
        invoiceSendout: "Invoice sendout",
        deleteEventConfirmation: "Are you sure you want to delete this shcheduled event for document",
        changeType: "Type of change",
        scheduleRowChange: "Schedule change on invoice row",
        dateOfAction: "Event date",
        increaseDiscount: "Increase discount",
        decreaseDiscount: "Decrease discount",
        removeDiscount: "Remove discount",
        increaseDeliveredQuantity: "Increase delivered quantity",
        decreaseDeliveredQuantity: "Decrease delivered quantity",
        increasePrice: "Increase price",
        decreasePrice: "Decrease price",
        changeArticle: "Change article",
        removeRow: "Remove invoice row",
        newValue: "New value",
        changeCurrentValue: "Change current value with...",
        of: "of",
        tags: "Tags",
        apply: "Apply",
        applying: "Applying",
        actions: "Actions",
        increase_invoice_count: "Increase invoice count",
        finished: "Finished",
        create_invoice: "Create invoice",
        create_contract: "Create contract",
        deleted: "Deleted",
        vat_included: "Vat included",
        period_end: "Period end",
        period_start_from: "Period start from",
        period_end_from: "Period end from",
        period_end_to: "Period end to",
        period_start_to: "Period start to",
        search_for_an_article: "Search for an article",
        contract_length: "Contract length",
        cost_center: "Cost center",
        gross: "Gross",
        category: "Category",
        invoice_discount: "Invoice discount",
        external_invoice_reference2: "External invoice reference 2",
        your_order_number: "Your order number",
        contribution_value: "Contribution value",
        project: "Project",
        customer_name: "Customer name",
        customer_details: "Customer details",
        invoice_interval: "Invoice interval",
        language: "Language",
        exclude_from_auto_renewal: "Exclude from auto renewal",
        continuous: "Continuous",
        customer_number: "Customer number",
        our_reference: "Our reference",
        document_number: "Document number",
        ocr_number: "OCR-NUMBER",
        voucher_numbers: "VOUCHER NUMBERS",
        contract_date: "Contract date",
        period_start: "Period start",
        invoices_remaining: "Invoice remaining",
        total_vat: "Total vat",
        contribution_percent: "Contribution percent",
        your_reference: "Your reference",
        house_work: "House work",
        terms_of_payment: "Terms of payment",
        external_invoice_reference1: "External invoice reference 1",
        last_invoice_date: "Last invoice date",
        net: "Net",
        currency: "Currency",
        freight: "Freight",
        total: "Total",
        create_new_contract: "Create new contract",
        start: "Start",
        end: "End",
        contract_detail: "Contract details",
        name: "Name",
        invoice_address: "Invoice address",
        invoice_address2: "Invoice address 2",
        postal_code: "Postal code",
        country: "Country",
        city: "City",
        phone: "Phone",
        organization_number: "Organization-/ personal number",
        description: "Description",
        unit: "Unit",
        price_per_unit: "Price per unit",
        discount: "Discount",
        vat: "Vat",
        account: "Account",
        article_number: "Article number",
        searchArticle: "Search for an article",
        price_template: "Price template",
        invoice_text: "Invoice text",
        comment: "Comment",
        yes: "Yes",
        no: "No",
        terms_of_delivery: "Terms of delivery",
        way_of_delivery: "Way of delivery",
        price_list: "Price list",
        other_detail: "Other details",
        print_template: "Print template",
        remarks: "Remarks",
        total_exclude_vat: "Total excl. VAT",
        invoice_rows: "Invoice Rows",
        customer_detail: "Customer detail",
        edit_contract: "Edit contract",
        terminating: "terminating",
        terminate_contract: "Terminate contract",
        updating: "Updating",
        update_contract: "Update contract",
        information: "information",
        enabled: "Enabled",
        invoice_administration_fee: "Invoice administration fee",
        delivery_phone1: "Delivery phone 1",
        email_offer: "Email offer",
        email_order_bcc: "Email order bcc",
        email_invoice_cc: "Email invoice cc",
        organisation_number: "Organization- / personal number",
        visiting_zip_code: "Visiting zip code",
        delivery_country: "Delivery country",
        visiting_country: "Visiting country",
        fax: "Fax",
        visiting_city: "Visiting city",
        email_invoice_bcc: "Email invoice bcc",
        email_invoice: "Email invoice",
        address1: "Address 1",
        phone1: "Phone 1",
        gln: "GLN",
        segment: "Segment",
        delivery_zip_code: "Delivery zip code",
        external_reference: "External reference",
        email_order_cc: "Email order cc",
        delivery_phone2: "Delivery phone 2",
        delivery_fax: "Delivery fax",
        delivery_city: "Delivery city",
        delivery_address1: "Delivery address 1",
        delivery_address2: "Delivery address 2",
        phone2: "Phone 2",
        email: "Email",
        as_email: "Email",
        vat_number: "Vat number",
        delivery_country_code: "Delivery country code",
        zip_code: "Zip code",
        email_offer_bcc: "Email offer bcc",
        delivery_name: "Delivery name",
        gln_delivery: "GLN delivery",
        delivery_address: "Delivery address",
        address2: "Address 2",
        email_offer_cc: "Email offer cc",
        visiting_address: "Visiting address",
        email_order: "Email order",
        loading: "Loading",
        create_new_customer: "Create new customer",
        basic_detail: "Basic details",
        invoice_detail: "Invoice details",
        company: "Company",
        private: "Private",
        organization: "Organization",
        personal: "personal",
        number: "number",
        web_address: "Web address",

        // stats page translations (switch values)
        more: "More",
        most: "Most",
        least: "Least",
        best: "Best",
        worst: "Worst",
        highest: "Highest",
        lowest: "Lowest",
        earning: "Earning",
        highest_earning: "Highest Earning",
        lowest_earning: "Lowest Earning",
        articles: "Articles",
        most_sold_articles: "Most sold articles",
        least_sold_articles: "Least sold articles",

        // stats page translations (chart titles)
        contracts_created_12_months: "Contracts created last 12 months",
        average_recurring_billing: "Average recurring billing",
        contracts_per_length: "Number of contracts per contract length",
        most_common_invoice_intervals: "Most common invoice intervals",

        sales_prognosis_coming_12_months: "Sales prognosis coming 12 months",
        sales_prognosis_for_article: "Sales prognosis for article",
        sales_prognosis_for_customer: "Sales prognosis for customer",
        sales_prognosis_for_segment: "Sales prognosis for segment",

        highest_recurring_billings_per_customer:
            "Highest recurring billings per customer",
        lowest_recurring_billings_per_customer:
            "Lowest recurring billings per customer",
        sales_for_article: "Sales for article",
        sales_for_customer: "Sales for customer",
        sales_for_segment: "Sales for segment",
        late_paying_customers: "Late paying customers",
        profit: "Profit",
        benefit: "Benefit",
        cost: "Cost",
        benefit_cost_ratio: "benefit-cost ratio",
        historically_most_late_paying_customers:
            "Historically the most late paying customers",
        historically_least_late_paying_customers:
            "Historically the least late paying customers",

        // stats page translations (x and y axes)
        number_of_contracts: "Number of contracts",
        amount_billed: "Amount billed",
        amount_received: "Amount received",
        month: "Month",
        period: "Period",
        created: "Created",
        billed: "Billed",
        sales: "Sales",
        late: "Late",

        faq: "FAQ",
        support: "Support",

        frequently_asked_questions: "Frequently asked questions",
        contact_support: "Contact Support",
        enter_subject: "Enter subject",
        enter_message: "Enter message",
        message: "Message",
        submit: "Submit",

        detail: "Detail",
        note: "Note",
        continue: "Continue",
        cancel: "Cancel",
        invoice: "Invoice",
        payment_and_delivery_terms: "Payment and delivery terms",
        invoicing: "Invoicing",
        show_price_vat_included: "Prices incl VAT",
        references: "References",
        accounting: "Accounting",
        type_of_vat: "Type of vat",
        sales_account: "Sales account",
        e_invoice_detail: "E-invoice details",
        default_delivery_types: "Default delivery types",
        do_not_send_nox: "Do not send to customer",
        e_invoice: "E-invoice",
        print: "Print",
        copy: "Copy",
        confidential: "Confidential",
        for: "for",
        delivery: "Delivery",
        quote: "Quote",
        order: "Order",
        invoice_remark: "Invoice remark",
        templates: "template",
        preselected_templates: "Preselected template",
        cash_invoice: "Cash invoice",
        create_customer: "Create customer",
        creating: "Creating",
        back: "Back",
        default_delivery_type: "Default delivery type",
        visiting_country_code: "Visiting country code",
        edit_customer: "Edit customer",
        delete_customer: "Delete customer",
        gln_number_for_delivery: "GLN number for delivery",
        update_customer: "Update customer",
        booked: "Bookkept",
        not_book: "Unbooked",
        unpaid: "Overdue",
        fully_paid: "Fully paid",
        voided: "Voided",
        overdue_unpaid_invoices: "Unpaid & overdue invoices",
        days_since_due_date: "Days since due date",
        administration_fee: "Administration fee",
        administration_fee_vat: "Administration fee vat",
        basis_tax_reduction: "Basis tax reduction",
        comments: "Comment",
        eu_quarterly_report: "Eu quarterly report",
        freight_vat: "Freight vat",
        invoice_reference: "Invoice reference",
        last_remind_date: "Last remind date",
        not_completed: "Not completed",
        outbound_date: "Outbound date",
        payment_way: "Payment way",
        reminders: "Reminders",
        round_off: "Round off",
        tax_reduction: "Tax reduction",
        tax_reduction_type: "Tax reduction type",
        time_basis_reference: "Time basis reference",
        total_to_pay: "Total to pay",
        voucher_year: "Voucher year",
        warehouse_ready: "Warehouse ready",
        failed_to_pause: "Failed to pause",
        file: "File",
        credit: "Credit",
        url_tax_reduction_list: "Url tax reduction list",
        delivery_date: "Delivery date",
        currency_rate: "Currency rate",
        cancelled: "Cancelled",
        sent: "Sent",
        voucher_number: "Voucher number",
        credit_invoice_reference: "Credit invoice reference",
        invoice_period_end: "Invoice period end",
        currency_unit: "Currency unit",
        invoice_type: "Invoice type",
        id: "ID",
        accounting_method: "Accounting method",
        order_reference: "Order reference",
        due_date: "Due date",
        invoice_period_start: "Invoice period start",
        nox_finans: "Fortnox Finans",
        offer_reference: "Offer reference",
        final_pay_date: "Final pay date",
        balance: "Balance",
        voucher_series: "Voucher series",
        contract_reference: "Contract reference",
        invoice_date: "Invoice date",
        ocr: "OCR",
        url: "Url",
        send: "Send",
        bookkeep: "Bookkeep",
        customize_invoice: "Customization (Invoice)",
        select_table_header_multiple: "Select table header (multiple)",
        send_invoices: "Send invoices",
        send_method: "Send method",
        sending: "Sending",
        letter: "Letter",
        register_payment: "Register payment",
        payment_amount: "Payment amount",
        failed_invoices: "Failed invoices",
        mode_of_payment_account: "Mode of payment account",
        the: "The",
        following: "following",
        failed: "failed",
        total_excluding_vat: "Total excluding vat",
        edit_invoice: "Edit invoice",
        credit_invoice: "Credit invoice",
        delivery_detail: "Delivery detail",
        send_via_nox_finans: "Send via Fortnox Fakturaservice",
        processing: "Processing",
        saving: "saving",
        save_invoice: "Save invoice",
        bookkeeping: "Bookkeeping",
        construction_account: "Construction account",
        depth: "Depth",
        eu_account: "Eu Account",
        euvat_account: "Euvat Account",
        export_account: "Export Account",
        height: "Height",
        housework: "House work",
        housework_type: "House work type",
        purchase_account: "Purchase account",
        stock_warning: "Stock warning",
        weight: "Weight",
        purchase_price: "Purchase price",
        unit_sold: "Unit sold",
        other_cost: "Other cost",
        stock_value: "Stock value",
        supplier_number: "Supplier number",
        quantity_in_stock: "Quantity in stock",
        stock_goods: "Stock goods",
        manufacturer_article_number: "Manufacturer article number",
        disposable_quantity: "Disposable quantity",
        stock_place: "Stock place",
        reserved_quantity: "Reserved quantity",
        cost_calculation_method: "Cost calculation method",
        supplier_name: "Supplier name",
        direct_cost: "Direct cost",
        width: "Width",
        stock_change_account: "Stock change account",
        manufacturer: "Manufacturer",
        expired: "Expired",
        default_stock_point: "Default stock point",
        webshop_article: "Webshop article",
        ean: "EAN",
        freight_cost: "Freight cost",
        amount_sold: "Amount sold",
        stock_account: "Stock account",
        bulky: "Bulky",
        sales_price: "Sales price",
        customization: "Customization",
        assigning: "Assigning",
        assign: "Assign",
        product: "Product",
        save: "Save",
        create_product: "Create product",
        update_product: "Update product",
        stock_detail: "Stock detail",
        supplier: "Supplier",
        item_type: "Item type",
        goods: "GOODS",
        services: "SERVICE",
        external_webshop: "External webshop",
        discontinued: "Discontinued",
        stock_item: "Stock item",
        account_detail: "Account detail",
        in_warehouse: "In warehouse",
        available_amount: "Available amount",
        edit_product: "Edit product",
        prices: "Prices",
        price: "Price",
        article: "Article",
        margin: "Margin",
        adding: "Adding",
        add: "Add",
        delivered: "Delivered",
        quantity: "Quantity",
        ongoing: "Ongoing",
        delete_product: "Delete product",
        delete_confirmation_text: "Are you sure you want to delete",
        void_confirmation_text: "Are you sure you want to cancel",
        cancel_delete_request: "No, take me back",
        accept_delete_request: "Yes, I'm sure",
        billing_detail_and_history: "Billing details and history",
        billing_details: "Billing details",
        billing_email: "Billing email",
        update_billing_detail: "Update billing detail",
        overdue: "Overdue",
        paid: "Paid",
        company_name: "Company name",
        amount_due: "Amount due",
        date: "Date",
        current_plan: "Current plan",
        plan_detail: "Plan details",
        you: "You",
        are: "are",
        currently: "currently",
        on: "on",
        plan: "plan",
        paying: "paying",
        your: "your",
        current: "current",
        tier: "tier",
        cover: "cover",
        up: "up",
        as: "as",
        a: "a",
        to: "to",
        signed: "signed",
        monthly: "monthly",

        binding: "binding",
        average: "average",
        covers: "covers",
        months: "months",
        plan_details: "Available packages",
        subscription: "subscription",
        details: "Details",
        cancel_subscription_text_1:
            "By clicking confirm cancellation below, you confirm that your subscription will end on",
        cancel_subscription_text_2: "and your account get deleted",
        cancel_subscription_text_3:
            "Please let us know the reason for your cancellation",
        cancel_reason_1: "I don't have the finances to pay for this service",
        cancel_reason_2: "The service is too expensive",
        cancel_reason_3: "We're leaving Fortnox",
        others: "others",
        cancelling: "Cancelling",
        confirm_cancellation: "Confirm cancellation",
        contract_period_start: "Contract period start",
        the_day_of_month: "the day of the month",
        workday_of_month: "last/first workday of month",
        days_before_period_start: "days before period start date",
        send_invoice_with_fortnox_finance:
            "Send invoices with Fortnox Fakturaservice",
        use_eprint_when_customer_missing_email:
            "Send the invoice through Fortnox print service when the customer is missing email address",
        use_eprint_for_print: "Use Fortnox print service instead of regular print",
        auto_create_invoice:
            "Let the application automatically send contract invoices",
        send_all_invoices_through: "Send all invoice through",
        send_with_condition: "Send invoices with",
        amount: "Amount",
        with_fortnox_finans: "with Fortnox Fakturaservice",
        create_invoice_based_on: "Create invoice based on",
        auto_create_invoice_by_system:
            "Let the application automatically create contract invoices",
        use_customer_default_send_method:
            "Use customer default send method when sending invoices",
        send_failed_nox_as_reg:
            "Invoices that cannot be sent through Fortnox Fakturaservice should be sent directly to the customer",
        send_invoice: "Send invoices",
        some: "Some",
        let_application_renew_contract:
            "Let the application automatically renew contracts before termination",
        renew_contract: "Renew contracts",
        apply_current_price_new_contract:
            "Apply current article prices for articles in the new contract",
        row_with_inactive_article_text:
            "What should be done for rows containing an article that is no longer active?",
        row_with_inactive_article_text_option_1:
            "Include it in new contract as a row without an article number",
        row_with_inactive_article_text_option_2: "Exclude it from the new contract",
        exclude_contract_text: "to specify contracts that are not to be renewed",
        click_here: "Click here",
        cancelled_contract: "Cancelled contracts",
        cancelled_contract_text_1:
            "We consider the following to be cancelled contracts",
        cancelled_contract_text_2: "Continuous contracts that are terminated",
        cancelled_contract_text_3: "Continuous contracts that are inactivated",
        cancelled_contract_text_4:
            "Fixed contracts that are termianted prior to end date",
        cancelled_contract_text_5:
            "Fixed contracts that are termianted on end date with no new contract for the same customer",
        all_contract_that_are: "All contracts that are",
        with_comments_containing_text:
            "with a contract comment containing the text",
        save_changes: "Save changes",
        add_config: "Add config",
        customer_type: "Customer type",
        contract_configuration: "Contract configuration",
        invoice_configuration: "Invoice configuration",
        go_to_information_quide: "Go to the information guide",
        fortnox_finans_invoices: "Fortnox Finances Invoices",
        auto_pause_unpaid_and_overdue_invoices:
            "Automatically pause unpaid and overdue Fortnox Finance invoices",
        pause_invoice_for: "Pause the invoices for",
        days: "days",
        pause_invoice_days_after_due_date: "days after due date",
        apply_this_to: "Apply this to",
        invoice_for_customer_segment: "Invoices for customers in segment",
        invoice_with_terms_of_payment: "Invoices with terms of payment",
        lost_revenue: "Lost revenue",
        we_consider_invoice_unpaid: "We consider invoices unpaid",
        we_consider_invoice_unpaid_1: "days after due date to be lost revenue",
        notifications: "Notifications",
        receive_alert: "I want to receive notifications/alerts",
        an_invoice_is_unpaid: "An invoice is unpaid",
        receive_notification_days_after_due_date: "days after due date",
        page: "page",
        upgrade: "upgrade",
        visit: "Visit",
        user: "user",
        accounts: "account",
        role: "Role",
        access: "access",
        current_plan_help_text: "You are currently on a plan that allows for",
        add_more_users: "add more users than that",
        invite_user: "Invite a new user",
        user_removed: "User successfully removed",
        invite_sent: "Invite successfully sent",
        invite_new_member_text: "Invite a new member to your team!",
        inviting: "Inviting",
        paused: "Paused",
        closed: "Closed",
        open: "Open",
        stop: "Stop",
        next_event: "Next event",
        next_event_date: "Next event date",
        pause_invoice: "Pause invoice",
        pause_until: "Pause until",
        segment_details: "Segment Details",
        create_new_customer_segment: "Create a new customer segment",
        shipping_charge: "Shipping charge",
        invoice_charge: "Invoice charge",
        complete: "Complete",
        edit_segment: "Edit segment",
        edit: "Edit",
        update_settings: "Update settings",
        go_back_to_segment_overview: "Go back to segment overview",
        update_segment: "Update segment",
        remove_segment: "Remove segment",
        add_customer_to_segment: "Add customers to segment",
        add_product_to_segment: "Add products to segment",
        see_all_segment_setting: "See all segment settings",
        e_document_distribution_way: "E-document distribution ways",
        segment_agreement_text:
            "I understand that the above settings will be applied to all customers in this segment.",
        no_of_customers: "Number of customers",
        no_of_customers_text: "Currently consisting of",
        segment_name: "Segment name",
        no_of_products: "Number of products",
        product_segment: "Product segment",
        product_type: "Product type",
        sales_account_eu_vat: "Sales account eu vat",
        sales_account_se: "Sales account se",
        sales_account_export: "Sales account export",
        product_segment_agreement:
            "I understand that the above settings will be applied to all products in this segment.",
        assign_product: "Assign product",
        add_product_to: "Add product to",
        create_segment: "Create segment",
        previous: "Previous",
        create_new_product_segment: "Create product segment",
        subject: "Subject",
        payments: "Payments",
        received: "Received",
        payments_received: "Payments received",
        alert: "Alert",
        see_full_message: "See full message",
        added_products: "Added products",
        inbox: "Added products",
        see_archived_alert: "See archived alerts",
        empty_alert: "You currently do not have any alert notification",
        unarchived_message: "Are you sure you want to un-archive",
        archived_message: "Are you sure you want to archive",
        unarchived_alert: "Successfully unarchived the alert",
        deleted_alert: "Successfully deleted alert",
        archived_alert: "Successfully archived the alert",
        alert_message: "Alert message",
        unarchiving: "Un-Archiving",
        archiving: "Archiving",
        archive: "Archive",
        go_back_inbox: "Go back to inbox",
        archive_message: "Archive message",
        product_detail: "Product detail",
        create_invoices_on_the: "Create invoice on the",
        let_application_send_invoice:
            "Let the application automatically send contract invoices",
        all_invoice: "All invoices",
        go_back_to_configuration: "Go back to configuration page",
        invoice_configuration_page: "Invoice configuration guide",
        contract_configuration_page: "Contract configuration guide",
        remove_from_segment: "Remove from segment",
        add_invoice_row: "Add invoice row",
        quarterly: "Quarterly",
        semi_annually: "Semi-annually",
        yearly: "Yearly",
        account_settings: "Account settings",
        update_your_information: "Update your information",
        full_name: "Full Name",
        phone_number: "Phone Number",
        change_password: "Change Password",
        new_password: "New Password",
        confirm_password: "Confirm Password",
        current_password: "Current Password",
        update_password: "Update Password",
        update_profile: "Update Profile",
        update_profile_picture: "Update Profile Picture",
        current_profile_picture: "Current Profile Picture",
        choose_a_new_picture: "Choose a new picture",
        go_back_to_home_page: "Go back to home page",
        void: "Void",
        preselected_template: "Preselected template",
        you_are_currently_on_the: "You are currently on the",
        plan_paying: "plan paying",
        as_a_monthly_average_your_current_tier_cover_up_to:
            "as a monthly average.Your current tier cover up to",
        active_contract: "active contract.",
        you_signed_up_for: "You signed up for",
        months_binding_on: "month binding on",
        none: "None",
        equal: "Equal",
        less_than: "Less than",
        greater_than: "Greater than",
        with_fortnox_finance: "with fortnox finance",
        inactivated: "Inactivated",
        added_customers: "Added customers",
        add_customers: "Add customers",
        add_products: "Add products",
        delete_segment: "Delete segment",
        number_of_product: "Number of product",
        login_successfully: "Login successfully",
        all_fields_are_required: "All fields are required",
        more_customer_detail: "More customer details",
        sign_out: "Sign out",
        profile_settings: "Profile settings",
        event_type: "Event category",
        event_message: "Event message",
        event_success: "Success",
        event_info: "Information",
        event_info_short: "Info",
        event_error: "Error",
        event_error_short: "Error",
        event_all: "All",
        event_view: "View event message",
        event_subject: "Subject",
        view_notifications: "View notifications",
        welcome_back: "Welcome back",
        inactivated_contracts: "Inactive",
        terminated_contracts: "Terminated",
        active_contracts: "Active",
        active_resources: "Active",
        inactive_resources: "Inactive",
        inactivated_resources: "Inactive",
        tag_contract: "Tag contract",
        activate_contract: "Activate contract",
        inactivate_contract: "Inactivate contract",
        add_tags: "Add tag",
        tags_assigned: "Tag(s) have been assigned",
        choose_contract: "Kindly select one or more contracts",
        choose_customer: "Kindly select one or more customers",
        choose_invoice: "Kindly select one or more invoices",
        choose_article: "Kindly select one or more articles",
        choose_columns: "Kindly select one or more columns",
        success_inactivate_contract: "Contract inactivated",
        success_activate_contract: "Contract activated",
        choose_action: "Kindly select an action to perform",
        limited_read_access: "You do not have permission to perform this action",
        sync_contracts: "Contract synchronization in progress",
        sync_customers: "Customer synchronization in progress",
        sync_invoices: "Invoice synchronization in progress",
        sync_articles: "Article synchronization in progress",
        fail_sync_contracts: "Contracts could not be synchronized",
        fail_sync_customers: "Customers could not be synchronized",
        fail_sync_invoices: "Invoices could not be synchronized",
        fail_sync_articles: "Articles could not be synchronized",
        updated_customer_info: "Customer status was successfully updated",
        request_failure:
            "Something went wrong while processing your request, kindly try again",
        invoice_number: "Invoice number",
        action_failed: "Action could not be perfomed",
        action_success: "Action performed successfully",
        updated_invoice_info: "Updated invoice info",
        payment_date: "Payment date",
        fields_required: "Kindly input data in all fields",
        register: "Register",
        status: "Status",
        invalid_amount_input: "Kindly input a valid amount",
        invoice_payment_registered: "Invoice payment was successfully registered",
        registering: "Registering",
        updated_article_info: "Article status updated",
        contract_terminated: "Contract successfully terminated",
        password_do_not_match: "The passwords do not match",
        password_not_valid: "The password must be at least 8 characters long",
        invalid_user_token:
            "Something went wrong, kindly reach out to the customer support team",
        password_confirmation: "Your password was set successfully",
        email_required: "Kindly input your email address",
        invalid_email: "Invalid email address",
        email_sent:
            "An email to reset your password has been sent to your email address",
        articles_added: "Articles were successfully added to segment",
        articles_not_added: "Articles could not be added to the segment",
        select_reason: "Kindly select your cancellation reason",
        write_reason: "Kindly input your cancellation reason",
        cannot_change_payment_plan_on_free:
            "You are currently subscribing to the Free package and can therefore not update your payment plan",
        paymentplan_updated: "Your payment plan was successfully updated",
        configuration_updated: "The configuration has been updated",
        configuration_not_updated: "The configuration was not updated",
        select_account_number: "Kindly input an account number",
        description_article_required: "Input article number, description or both",
        invalid_invoice_row: "Kindly input valid invoice row data",
        starred_fields_required: "Fields with an astericks are mandatory",
        customer_number_name_required:
            "Customer number and customer name are required",
        customer_deleted: "Customer successfully deleted",
        segment_removed: "The segment was successfully deleted",
        segment_added: "The segment was successfully created",
        segment_settings_confirmation:
            "Kindly confirm that you accept that all segment settings will be applied to all added resources",
        name_description_required: "Name and description are required",
        segment_updated: "The segment was successfully updated",
        customer_removed_segment:
            "The customer was successfully removed from the segment",
        article_removed_segment:
            "The article was successfully removed from the segment",
        invoice_voided: "The invoice was successfully voided",
        voiding: "Processing",
        invoice_booked: "The invoice was successfully booked",
        book_before_credit: "Only booked invoices can be credited",
        invalid_date: "Kindly supply a valid date",
        nox_paused: "Invoice successfully paused",
        nox_stopped: "Invoice successfully stopped",
        noxs_paused: "Invoices successfully paused",
        noxs_stopped: "Invoices successfully stopped",
        article_created: "Article successfully created",
        article_updated: "Article successfully updated",
        article_deleted: "Article successfully deleted",
        deleting: "Deleting",
        number_pricelist_price_required:
            "Article number, price list and price are mandatory",
        price_added: "Article price added successfully",
        price_updated: "Article price updated succesfully",
        price_deleted: "Article price deleted successfully",
        void_confirmation: "Are you sure you want to void",
        customer_segment: "Customer segment",
        article_segment: "Article segment",
        information_updated: "The information successfully updated",
        password_updated: "The password has been updated",
        invalid_file_type: "Invalid file type",
        select_file: "Choose an image to upload",
        image_uploaded: "Image uploaded succesfully",
        upload_image: "Upload image",
        max_users:
            "You have already invited the amount of users that your package allows for, upgrade to higher package to be able to invite more users",
        owner: "Owner",
        staff: "Staff",
        accountant: "Accountant",
        other: "Other",
        package_user_text_1: "You are currently on a plan that allows for",
        package_user_text_2: " users. To add more users than that, visit the",
        pacakge_user_text_3: "to upgrade your plan",
        full_access: "Full access",
        limited_access: "Limited access",
        read_access: "Read access",
        customization_contract: "Customize columns (Contracts)",
        customization_customer: "Customize columns (Customers)",
        customization_invoice: "Customize columns (Invoices)",
        customization_article: "Customize columns (Articles)",
        update: "Update",
        select_columns: "Choose which columns to show",
        choose_plan: "Choose this plan",
        current_package: "Current package",
        sla_addon:
            "SLA (Service-Level Agreement) is included in the above price with a monthly average cost of 1090 SEK excl. VAT",
        contracts_to_exclude: "Contracts to exclude from auto-renewal",
        contracts_to_exclude_helptext:
            "Check the check-box for the contracts you wish to exclude from the auto-renewal of contracts. Click Save once done.",
        please_wait: "Please wait while we process your request",
        cancel_service: "Cancel subscription",
        customers_added: "The customers were added to the segment",
        preselected_distribution_ways: "Pre-selected distribution ways",
        of_every_month_for_contract:
            "of every month, for contracts with period start within ",
        days_of_that_day: "days from that day",
        of_every_month_for_contract_workday: "of every month, for contracts with period start within the current/coming month", 
        last_workday_of_month: "last workday of month",
        first_workday_of_month: "first workday of month",
        schedule_contract_cancelling: "Schedule contract cancelling",
        date_to_cancel: "Date to cancel",
        date_to_change_price: "Date for price change",
        type_of_cancellation: "Type of cancellation",
        terminate: "Terminate",
        inactivate: "Inactivate",
        choose_an_option: "Choose an option",
        schedule_invoice_send_out: "Schedule invoice send out",
        date_to_send: "Date to send",
        bookkeep_invoice: "Bookkeep the invoice",
        send_failed_nox_as_reg_2:
            "Send directly to customer if Fortnox Fakturaservice fails",
        distribution_method: "Distribution method",
        regular: "Standard",
        fortnox_invoice_service: "Fortnox Fakturaservice",
        already_scheduled_modal_title: "Already scheduled",
        already_scheduled_modal_list_label:
            "The following resources have already been scheduled",
        already_scheduled_modal_note1:
            "You can edit existing scheduled events by clicking this icon",
        already_scheduled_modal_note2:
            "This icon is in the action column of each row in the table",
        select_unscheduled_row:
            "Select an unscheduled resource to carry out this action",
        proceed: "Proceed",
        delete: "Delete",
        update_contract_prices: "Update contract prices",
        already_price_change_modal_title: "Already scheduled price changes",
        already_price_change_modal_list_label:
            "The following contracts have already been scheduled",
        already_price_change_modal_note1:
            "You can edit existing scheduled price changes by clicking this icon",
        already_price_change_modal_note2:
            "This icon is in the action column of each contract row in the table",
        select_price_unchanged_row:
            "Select an unscheduled contract to carry out this action",
        type_of_price_change: "Type of price change",
        increase: "Increase",
        decrease: "Decrease",
        percentage_to_change_price: "Price change in percent",
        amount_to_change_price: "Price change in amount",
        ok: "Ok",
        invalid_row_selection_invoice:
            "No valid invoice selected for scheduling invoice",
        invalid_row_selection_schedule:
            "No valid contract selected for scheduling cancelling",
        invalid_row_selection_price_change:
            "No valid contract selected for updating price",
        update_contract_price: "Update contract price",
        no_graph_data: "No data to plot the graph with",
        price_incl_vat: "Prices incl. VAT",
        auto_bookkeep_invoices: "Bookkeep invoices",
        contract_price_updates: "Price updates in contracts",
        let_application_book_invoice: "Let Likvio bookkeep created invoices",
        incl: "Incl.",
        excl: "Excl.",
        incl_vat: "Including VAT",
        excl_vat: "Excluding VAT",
        let_application_update_prices:
            "Let Likvio automatically update contract prices",
        prices_and_vat: "The prices in Fortnox article register are",

        update_contracts_with_template_data: "Automatically update contracts with their template data",
        let_application_update_contract_data:
            "Let Likvio automatically update contract data",
        data_to_update: "Data to update in contracts",

        "sentToCustomer": "Sent to customer",
        "sentViaNox": "Sent via Fortnox Fakturaservice",
        "notSent": "Not sent",
        "invoiceInterval": "Invoice interval",
        "contractLength": "Contract length",
        "invoicesRemaining": "Invoices remaining on contract",
        "periodStartUntil": "Period start date",
        "periodEndUntil": "Period end date",
        select: "Select invoice rows",
        'increase_percentage': "Increase in percent",
        'decrease_percentage': "Decrease in percent",
        'increase_amount': "Increase in amount",
        'decrease_amount': "Decrease in amount",
        completed: "Completed",
        deletedSuccessfully: "Deleted successfully",
        pickScheduleAction: "Select action",
        createNewSchedule: "Create new schedule",
        searchInvoice: "Search invoice",
        searchContract: "Search contract",
        noData: "No data to show",
        recurringContractUpdate: "Recurring contract updates",
        updateUntilSpecificValue: "Update until specific value",
        updateNumberEachTime: "Update number of times",
        updateManyTimeUtilSpecificValue: 'Update this many times/update until this value...',
        eachTimeUpdateWithValue: "Each time, update with value...",
        updateManyTimeUtilValue: "Update this many times/update until this value *",
        lastUpdated: "Last updated",
        updateType: "Update Type",
        documentNumber: "Contract number",
        rowToExclude: "Rows to exclude",
        updateRecurringContract: "Update Recurring Contract",
        invoiceTextOnPriceChange: "Invoice text on the first invoice after the price change (max 1000 characters)",
        Owner: "Owner",
        Accountant: "Accountant",
        Staff: "Staff",
        Other: "Other",
        Limited: "Limited",
        Read: "Read",
        Full: "Full",
        videoGuides: "Video Guides",
        createRecurringContract: "Create a recurring contract update",
        invoiceRow: "Invoice row",
        InvoiceRowUpdate: "Invoice row update in contract",
        contractTermination: "Contract Termination",
        cancelContract: 'Cancel contract',
        changeCurrentValueWith: 'Change current value with...',
        create_contract_price: 'Update contract prices',
        increasePriceAmount: 'Increase price with amount',
        decreasePriceAmount: 'Decrease price with amount',
        increasePricePercentage: 'Increase price in percent',
        decreasePricePercentage: 'Decrease price in percent',
        unpaidBilling: "Unpaid",
        overdueBilling: "Overdue",
        finishContractConfirmation: "Are you sure you want to terminate contract ",
        finishContractsConfirmation: "Are you sure you want to terminate marked contracts",
        inactivateContractConfirmation: "Are you sure you want to inactivate contract "


    },
    se: {
        hello: "Hej",
        welcomeBack: "Välkommen tillbaka",
        go_to_login: "Gå till inloggningssidan",
        loginError: "Ogiltigt lösenord",
        fieldRequired: "Fältet är obligatoriskt",
        emailAddress: "E-postadress",
        password: "Lösenord",
        forgetPassword: "Glömt ditt lösenord",
        pleaseWait: "Vänligen vänta...",
        login: "Logga in",
        resetYourPassword: "Återställ ditt lösenord",
        reset_password: "Återställ lösenord",
        enter_password: "Input your new password",
        reset_password_text:
            "Vänligen ange den mejladress som är kopplad till ditt Likvio konto. Vi kommer att skicka dig ett mejl med en länk för att återställa ditt lösenord.",
        send_email: "Skicka mejl",
        sending_email: "Skickar mejl",
        homePage: "Hem",
        contracts: "Avtalsfakturering",
        schedules: "Schemalagda händelser",
        customerSegment: "Kundsegment",
        update_segment: "Uppdatera segment",
        customers: "Kunder",
        invoices: "Kundfakturor",
        noxFinanceInvoice: "Kundfakturor - Fortnox Fakturaservice",
        products: "Produkter",
        productSegment: "Produktsegment",
        statisticsData: "Statistik",
        billing: "Betalningar/ Prenumeration",
        settingsConfig: "Konfigurering/inställningar",
        scheduled: "Schemalagt",
        users: "Användare",
        re_auth_text:
            "Dessvärre har anslutningen till Fortnox avbrutits, vilket innebär att Likvio inte har tillgång till er Fortnox data. Vänligen klicka här för " +
            "att återansluta till Fortnox och undvika ytterligare avbrott/avvikelser i denna tjänst. Vi ber om ursäkt för besväret.",
        re_connect_now: "Återanslut nu",
        package_restriction_text:
            "Er nuvarande prenumeration inkluderar inte tillgång till denna modul. Klicka på Uppgradera nedan för att gå till prenumerationssidan.",
        go_back: "Gå till Hem",
        upgrade_plan: "Uppgradera paket",
        no_permission: "Du har inte behörighet till denna sida.",
        take_me_back: "Gå till Hem",
        free_offer:
            "Vi erbjuder 7 dagars kostnadsfri prövotid för våra premiumpaket",
        billed_since: "Fakturerad sedan",
        received_payment_since: "Mottagit betalningar sedan",
        latest_event: "Senaste händelsen",
        contract_close_to_ending: "Avtal nära avslut",
        contract: "Avtal",
        alerts: "Notifikationer",
        end_date: "Slutdatum",
        invoice_left: "Fakturor kvar",
        type: "Typ",
        filter: "Filtrera",
        event: "Event",
        occurred_at: "Datum",
        see_all_contract: "Se alla kontrakt",
        see_all_events: "Se alla event",
        see_all_alerts: "Se alla notifikationer",
        no_unarchived_alert: "Inga notifikationer i inkorgen",
        no_latest_contract_ending: "Inga avtal nära avslut",
        no_latest_event: "Inga event",
        not_available: "Inte tillgänlig",
        upcoming: "Kommande",
        active: "Aktiv",
        inactive: "Inaktivera",
        terminated: "Avslutad",
        search: "Sök",
        all: "Alla",
        eventType: "Händelsetyp",
        handled: "Redan hanterade",
        applyFilter: "Applicera filter",
        selectType: "Välj typ av schemaläggning",
        contractCancel: "Avtalsavslut",
        contractPriceChange: "Prisändring i avtal",
        invoiceSendout: "Faktura utskick",
        deleteEventConfirmation: "Är du säker på att du vill radera denna schemalagda händelse för dokument",
        scheduleRowChange: "Schemalägg förändring på fakturarad",
        dateOfAction: "Datum för händelse",
        increaseDiscount: "Höj rabatt",
        decreaseDiscount: "Sänk rabatt",
        removeDiscount: "Ta bort rabatt",
        increaseDeliveredQuantity: "Öka levererad kvantitet",
        decreaseDeliveredQuantity: "Minska levererad kvantitet",
        removeRow: "Ta bort fakturarad",
        newValue: "Nytt värde",
        changeCurrentValue: "Förändra nuvarande värde med...",
        changeType: "Förändringstyp",
        increasePrice: "Höj pris",
        decreasePrice: "Sänk pris",
        changeArticle: "Byt artikel",
        customize: "Anpassa",
        of: "av",
        tags: "Taggar",
        apply: "Utför",
        actions: "Actions",
        increase_invoice_count: "Öka faktura-antalet",
        finished: "Avsluta",
        create_invoice: "Skapa faktura",
        applying: "Applicerar",
        deleted: "Raderad",
        vat_included: "Moms inkluderad",
        period_end: "Periodslut",
        contract_length: "Avtalslängd",
        cost_center: "Kostnadsställe",
        gross: "Brutto",
        category: "Kategori",
        invoice_discount: "Fakturarabatt",
        external_invoice_reference2: "Extern fakturareferense 2",
        your_order_number: "Ert ordernummer",
        contribution_value: "Fakturatillskott (belopp)",
        project: "Projekt",
        customer_details: "Kunddetaljer",
        customer_name: "Kundnamn",
        invoice_interval: "Fakturaintervall",
        language: "Språk",
        exclude_from_auto_renewal: "Exkludera från automatisk avtalsförnyelse",
        continuous: "Löpande",
        customer_number: "Kundnummer",
        our_reference: "Vår referens",
        document_number: "Dokumentnummer",
        ocr_number: "OCR-NUMMER",
        voucher_numbers: "VERIFIKATIONSNUMMER",
        contract_date: "Avtalsdatum",
        period_start: "Periodstart",
        invoices_remaining: "Fakturor kvar",
        total_vat: "Total moms",
        contribution_percent: "Fakturatillskott (procent)",
        your_reference: "Er referens",
        house_work: "Husarbete",
        terms_of_payment: "Betalningsvillkor",
        external_invoice_reference1: "Extern fakturareferens 1",
        last_invoice_date: "Senast fakturerad",
        net: "Netto",
        currency: "Valuta",
        freight: "Frakt",
        total: "Totalt",
        create_new_contract: "Skapa nytt avtal",
        start: "start",
        end: "slut",
        contract_detail: "Avtal",
        name: "Namn",
        invoice_address: "Fakturaadress",
        invoice_address2: "Fakturaadrress 2",
        postal_code: "Postnummer",
        country: "Land",
        city: "Stad",
        phone: "Telefonnummer",
        organization_number: "Organisations- /personnummer",
        description: "Beskrivning",
        unit: "Enhet",
        price_per_unit: "Pris per enhet",
        discount: "Rabatt",
        vat: "Moms",
        account: "Konto",
        article_number: "Artikelnummer",
        price_template: "Prismall",
        invoice_text: "Fakturatext",
        comment: "Kommentar",
        yes: "Ja",
        no: "Nej",
        ongoing: "Löpande",
        terms_of_delivery: "Leveransvillkor",
        way_of_delivery: "Leveranssätt",
        price_list: "Prislista",
        other_detail: "Andra detaljer",
        print_template: "Utskriftsmall",
        remarks: "Fakturatext",
        total_exclude_vat: "Totalt exklusive moms",
        invoice_rows: "Fakturarader",
        quantity: "Kvantitet",
        edit_contract: "Redigera avtal",
        terminating: "Avslutar",
        terminate_contract: "Avsluta avtal",
        updating: "Uppdaterar",
        update_contract: "Uppdatera avtal",
        customer_detail: "Kunddetaljer",
        information: "Information",
        enabled: "Aktivera",
        invoice_administration_fee: "Fakturaavgift",
        delivery_phone1: "Leveranstelefonnummer",
        email_offer: "Mejladress för offert",
        email_order_bcc: "Mejladress för order (BCC)",
        email_invoice_cc: "Mejladress för faktura (CC)",
        organisation_number: "Organisations- /personnummer",
        visiting_zip_code: "Postnummer (besöksadress)",
        delivery_country: "Leveransland",
        visiting_country: "Besöksland",
        fax: "Fax",
        visiting_city: "Besöksstad",
        email_invoice_bcc: "Mejladress för faktura (BCC)",
        email_invoice: "Mejladress för faktura",
        address1: "Adress 1",
        phone1: "Telefonnummer 1",
        gln: "GLN",
        segment: "Segment",
        delivery_zip_code: "Postnummer (leveransadress)",
        external_reference: "Extern referens",
        email_order_cc: "Mejladress för order (CC)",
        delivery_phone2: "Telefonnummer 2 (leverans)",
        delivery_fax: "Fax (leverans)",
        delivery_city: "Leveransstad",
        delivery_address: "Leveransadress",
        phone2: "Telefonnummer 2",
        email: "Mejla",
        as_email: "Mejl",
        vat_number: "VAT-nummer",
        delivery_country_code: "Leveranslandskod",
        zip_code: "Postnummer",
        email_offer_bcc: "Mejladress för offert (BCC)",
        delivery_name: "Leveransnamn",
        gln_delivery: "GLN (leverans)",
        gln_number_for_delivery: "GLN-nummer för leverans",
        delivery_address1: "Leveransadress 1",
        delivery_address2: "Leveransadress 2",
        address2: "Adress 2",
        email_offer_cc: "Mejladress för offert (CC)",
        visiting_address: "Besöksadress",
        email_order: "Mejladress för order",
        loading: "Laddar",
        create_new_customer: "Skapa ny kund",
        basic_detail: "Grunduppgifter",
        invoice_detail: "Faktureringsuppgifter",
        company: "Företag",
        private: "Privat",
        organization: "Företag",
        personal: "Personnummer",
        number: "Nummer",
        web_address: "Hemsida",
        more: "Fler",
        // stats page translations (switch values)
        most: "Mest",
        least: "Minst",
        best: "Bäst",
        worst: "Sämst",
        highest: "Högsta",
        lowest: "Lägsta",
        earning: "Tjänande",
        highest_earning: "Högst tjänande",
        lowest_earning: "Lägst tjänande",
        articles: "Produkter",
        most_sold_articles: "Mest sålda artiklar",
        least_sold_articles: "Minst sålda artiklar",
        // stats page translations (chart titles)
        contracts_created_12_months: "Avtal skapade senaste 12 månaderna",
        average_recurring_billing: "Genomsnittlig återkommande fakturering",
        contracts_per_length: "Antal avtal per avtalslängd",
        most_common_invoice_intervals: "Mest använda fakturaintervall",
        sales_prognosis_coming_12_months: "Säljprognos kommande 12 månaderna",
        sales_prognosis_for_article: "Säljprognos för artikel",
        sales_prognosis_for_customer: "Säljprognos för kund",
        sales_prognosis_for_segment: "Säljprognos för segment",
        highest_recurring_billings_per_customer:
            "Högst återkommande fakturering per kund",
        lowest_recurring_billings_per_customer:
            "Lägst återkommande fakturering per kund",
        sales_for_article: "Försäljning av artikel",
        sales_for_customer: "Försäljning till kund",
        sales_for_segment: "Försäljning till segment",
        late_paying_customers: "Kunder som betalar försent",
        profit: "Vinst",
        benefit: "Fördel",
        cost: "Kostnad",
        benefit_cost_ratio: "benefit-cost ratio",
        historically_most_late_paying_customers:
            "Kunder som historiskt ofta betalat sent",
        historically_least_late_paying_customers:
            "Kunder som historiskt sällan betalat sent",
        // stats page translations (x and y axes)
        number_of_contracts: "Antal avtal",
        amount_billed: "Fakturerat belopp",
        amount_received: "Mottaget belopp",
        month: "Månad",
        period: "Period",
        created: "Skapad",
        billed: "Fakturerad",
        sales: "Försäljning",
        late: "Försenad",
        faq: "FAQ",
        support: "Support",
        frequently_asked_questions: "Vanliga frågor",
        contact_support: "Kontakta kundtjänst",
        enter_subject: "Ämne",
        enter_message: "Meddelande",
        message: "Meddelande",
        submit: "Skicka",
        detail: "detaljer",
        note: "Anteckningar",
        continue: "Fortsätt",
        cancel_service: "Avsluta prenumeration",
        cancel: "Avbryt",
        update_customer: "Uppdatera kund",
        invoice: "Faktura",
        payment_and_delivery_terms: "Betalnings- & leveransvillkor",
        invoicing: "Fakturering",
        show_price_vat_included: "Visa priser inklusive moms",
        references: "Referenser",
        accounting: "Bokföring",
        type_of_vat: "Momstyp",
        sales_account: "Försäljningskonto",
        e_invoice_detail: "E-dokument",
        default_delivery_types: "Default distributionsmetoder",
        e_invoice: "E-faktura",
        do_not_send_nox: "Skicka ej till kund",
        print: "Utskrift",
        copy: "Kopia",
        confidential: "Hemlig",
        for: "För",
        delivery: "Leverans",
        quote: "Offert",
        order: "Order",
        invoice_remark: "Fakturatext",
        templates: "Mallar",
        preselected_templates: "Förvalda mallar",
        cash_invoice: "Kontaktfaktura",
        create_customer: "Skapa kund",
        customer: "Kund",
        creating: "Skapar",
        back: "Tillbaka",
        administration_fee: "Administrationsavgift",
        administration_fee_vat: "Moms på administrationsavgift",
        basis_tax_reduction: "Basis tax reduction",
        comments: "Kommentar",
        eu_quarterly_report: "EU kvartalsvisrapport",
        freight_vat: "Moms på fraktavgift",
        invoice_reference: "Fakturareferens",
        last_remind_date: "Senaste påminnelsen",
        not_completed: "Ej klar",
        outbound_date: "Ut-datum",
        payment_way: "Betalsätt",
        reminders: "Påminnelser",
        round_off: "Avrundning",
        tax_reduction: "Skatteavdrag",
        tax_reduction_type: "Typ av skatteavdrag",
        time_basis_reference: "Tidsbaserad referens",
        total_to_pay: "Totalt att betala",
        voucher_year: "Verifikationsår",
        warehouse_ready: "Leveransklar",
        failed_to_pause: "Misslyckades att pausas",
        file: "Fil",
        credit: "Kredit",
        url_tax_reduction_list: "Skatteavdragslista URL",
        delivery_date: "Leveransdatum",
        currency_rate: "Valutakurs",
        cancelled: "Makulerad",
        sent: "Skickad",
        voucher_number: "Verifikationsnummer",
        credit_invoice_reference: "Kreditfaktura-referens",
        invoice_period_end: "Fakturans periodslut",
        invoice_number: "Fakturanummer",
        currency_unit: "Valutaenhet",
        invoice_type: "Fakturatyp",
        id: "ID",
        accounting_method: "Bokföringsmetod",
        order_reference: "Orderreferens",
        due_date: "Förfallodatum",
        invoice_period_start: "Fakturans periodstart",
        nox_finans: "Fortnox Fakturaservice",
        offer_reference: "Vår referens",
        final_pay_date: "Slutbetalningsdatum",
        balance: "Balans",
        voucher_series: "Verifikationsserie",
        contract_reference: "Avtalsreferens",
        invoice_date: "Fakturadatum",
        ocr: "OCR",
        url: "URL",
        send: "Skicka",
        bookkeep: "Bokför",
        customize_invoice: "Anpassa",
        select_table_header_multiple: "Välj kolumner",
        send_invoices: "Skicka fakturor",
        send_method: "Typ av utskick",
        sending: "Skickar",
        letter: "Utskrift",
        register_payment: "Registrera inbetalning",
        register: "Registrera",
        payment_amount: "Belopp",
        failed_invoices: "Misslyckade fakturor",
        mode_of_payment_account: "Betalningskonto",
        the: "",
        following: "Följande",
        failed: "misslyckades",
        total_excluding_vat: "Totalt exklusive moms",
        edit_invoice: "Redigera faktura",
        credit_invoice: "Kreditera faktura",
        delivery_detail: "Leveransdetaljer",
        send_via_nox_finans: "Skicka via Fortnox Fakturaservice",
        processing: "Behandlar",
        saving: "Sparar",
        save_invoice: "Spara faktura",
        bookkeeping: "Bokför",
        construction_account: "Byggkonto",
        depth: "Djup",
        eu_account: "Intäktskonto EU",
        euvat_account: "Intäktskonto EU (momspliktig)",
        export_account: "Intäktskonto Export",
        height: "Höjd",
        housework: "Husarbete",
        housework_type: "Typ av husarbete",
        purchase_account: "Inköpskonto",
        stock_warning: "Lagervarning",
        weight: "Vikt",
        purchase_price: "Inköpspris",
        unit_sold: "Sålda enheter",
        other_cost: "Andra kostander",
        stock_value: "Lagervärde",
        supplier_number: "Leverantörsnummer",
        quantity_in_stock: "Antal i lager",
        stock_goods: "Lagervara",
        manufacturer_article_number: "Tillverkarens artikelnummer",
        disposable_quantity: "Disponibelt antal",
        stock_place: "Lagerplats",
        reserved_quantity: "Reserverat antal",
        cost_calculation_method: "Kostnadskalkylsmetod",
        supplier_name: "Leverantörsnamn",
        direct_cost: "Direktkostnad",
        width: "Bredd",
        stock_change_account: "Lagerförändringskonto",
        manufacturer: "Tillverkare",
        expired: "Utgången",
        default_stock_point: "Standard lagerplats",
        webshop_article: "Webbutiksartikel",
        ean: "EAN",
        freight_cost: "Fraktkostnad",
        amount_sold: "Sålt belopp",
        stock_account: "Lagerkonto",
        bulky: "Skrymmande",
        sales_price: "Försäljningspris",
        customization: "Anpassning",
        assigning: "Lägger till",
        assign: "Lägg till",
        product: "Produkt",
        save: "Spara",
        create_product: "Skapa produkt",
        stock_detail: "Lageruppgifter",
        supplier: "Leverantör",
        item_type: "Produkttyp",
        goods: "Vara",
        services: "Tjänst",
        external_webshop: "Extern webbutik",
        discontinued: "Utgången",
        stock_item: "Lagervara",
        account_detail: "Bokföringsuppgifter",
        in_warehouse: "I lager",
        available_amount: "Tillgängligt antal",
        edit_product: "Redigera produkt",
        prices: "Priser",
        price: "Pris",
        article: "Produkt",
        margin: "Marginal",
        adding: "Lägger till",
        add: "Lägg till",
        delete_product: "Radera produkt",
        update_product: "Uppdatera produkt",
        delete_confirmation_text: "Är du säker på att du vill radera",
        void_confirmation_text: "Är du säker på att du vill makulera",
        cancel_delete_request: "Nej, ta mig tillbaka",
        accept_delete_request: "Ja, jag är säker",
        billing_detail_and_history: "Faktureringsuppgifter och historik",
        billing_details: "Faktureringsuppgifter",
        billing_email: "Faktureringsmejladress",
        update_billing_detail: "Uppdatera faktureringsuppgifter",
        overdue: "Förfallna",
        overdue_unpaid_invoices: "Obetalda & förfallna fakturor",
        days_since_due_date: "Dagar sedan förfallodatum",
        paid: "Betalda",
        unpaid: "Förfallna",
        booked: "Bokförd",
        not_book: "Icke-bokförda",
        fully_paid: "Slutbetalda",
        voided: "Makulerade",
        company_name: "Företagsnamn",
        amount_due: "Balans",
        date: "Datum",
        current_plan: "Nuvarande paket",
        plan_detail: "Prenumerationsdetaljer",
        you: "Ni",
        are: "prenumerar",
        currently: "nu",
        on: "på",
        plan: "paketet",
        paying: "och betalar",
        your: "ert",
        current: "nuvarande",
        tier: "tier",
        cover: "täcker",
        up: "upp till",
        as: "",
        a: "",
        to: "till",
        signed: "signade",
        monthly: "per månad",
        binding: "bindningstid",
        average: "i genomsnitt",
        covers: "täcker",
        months: "månader",
        plan_details: "Tillgängliga paket",
        subscription: "prenumerationssidan",
        details: "Detaljer",
        cancel_subscription_text_1:
            "Genom att klicka på bekräfta uppsägning nedan bekräftar du att er prenumeration avslutas",
        cancel_subscription_text_2: "och att ert konto raderas",
        cancel_subscription_text_3:
            "Vänligen ange orsaken till att ni önskar avsluta prenumerationen",
        cancel_reason_1: "Jag har inte finanserna för denna tjänst",
        cancel_reason_2: "Tjänsten är för dyr",
        cancel_reason_3: "Vi ska lämna Fortnox",
        others: "Annan",
        cancelling: "Avslutar",
        confirm_cancellation: "Bekräfta uppsägning",
        create_invoice_based_on: "Skapa fakturor baserat på",
        contract_period_start: "Avtalens periodstartsdatum",
        the_day_of_month: "Kalendardag",
        workday_of_month: "Första/sista arbetsdagen i månaden",
        days_before_period_start: "dagar innan periodstartsdatum",
        auto_create_invoice_by_system:
            "Låt Likvio automatiskt skapa avtalsfakturor",
        use_customer_default_send_method:
            "Använd respektive kunds förvalda distrubutionssätt för utskick av fakturor",
        send_invoice: "Skicka fakturor",
        some: "Somliga",
        let_application_renew_contract:
            "Låt Likvio automatiskt förnya avtal som är påväg att löpa ut",
        renew_contract: "Förnya kontrakt",
        apply_current_price_new_contract:
            "Applicera nuvarande artikelpris och artikelbeskrivning i nya kontrakt",
        row_with_inactive_article_text:
            "Vad ska Likvio göra med fakturarader som innehåller artiklar som inte längre är aktiva i Fortnox?",
        row_with_inactive_article_text_option_1:
            "Inkludera i nytt avtal utan artikelnummer",
        row_with_inactive_article_text_option_2: "Exkludera från nytt avtal",
        exclude_contract_text:
            "för att specificera avtal som ska exkluderas från autoförnyelsen",
        click_here: "Klicka här",
        cancelled_contract: "Cancellerade avtal",
        cancelled_contract_text_1: "Vi anser följande vara cancellationer",
        cancelled_contract_text_2: "Avslutade löpande avtal",
        cancelled_contract_text_3: "Inaktiverade löpande avtal",
        cancelled_contract_text_4:
            "Icke-löpande avtal som avslutas innan slutdatum",
        cancelled_contract_text_5:
            "Icke-löpande avtal som avslutas på slutdatum utan ett nytt avtal för samma kund",
        all_contract_that_are: "Alla avtal som är",
        with_comments_containing_text: "med en avtalskommentar som innehåller",
        save_changes: "Spara ändringar",
        add_config: "Lägg till kriteria",
        amount: "Belopp",
        customer_type: "Kundtyp",
        with_fortnox_finans: "med Fortnox Fakturaservice",
        contract_configuration: "Avtalskonfiguration",
        invoice_configuration: "Fakturakonfiguration",
        go_to_information_quide: "Gå till informationsguiden",
        fortnox_finans_invoices: "Fortnox Fakturaservice fakturor",
        auto_pause_unpaid_and_overdue_invoices:
            "Auto-pausa förfallna och obetalda Fortnox Fakturaservice fakturor",
        pause_invoice_for: "Pausa fakturor i",
        days: "dagar",
        pause_invoice_days_after_due_date: "dagar efter förfallodatum",
        apply_this_to: "Applicera detta på",
        invoice_for_customer_segment: "Fakturor för kunder i kundsegment",
        invoice_with_terms_of_payment: "Fakturor med betalningsvillkor",
        lost_revenue: "Förlorade intäkter",
        we_consider_invoice_unpaid: "Vi anser fakturor obetalda",
        we_consider_invoice_unpaid_1:
            "dagar efter förfallodatum vara förlorade intäkter",
        notifications: "Notifikationer",
        receive_alert: "Vi vill motta notifikationer",
        an_invoice_is_unpaid: "Obetalda fakturor",
        receive_notification_days_after_due_date: "dagar efter förfallodatum",
        page: "Sida",
        upgrade: "för att uppgradera",
        visit: "gå till",
        user: "användarkonton",
        accounts: "konton",
        role: "Roll",
        access: "Tillgång",
        current_plan_help_text:
            "Ni prenumererar på ett paket som tillåter upp till",
        add_more_users: "För att lägga till fler användare än så",
        invite_user: "Bjud in en användare",
        invite_sent: "Inbjudan har skickats",
        user_removed: "Användare har tagits bort",
        invite_new_member_text: "Bjud in en ny medlem till ditt team!",
        inviting: "Bjuder in",
        paused: "Pausad",
        closed: "Stängd",
        open: "Öppen",
        stop: "Stoppa",
        next_event: "Nästa event",
        next_event_date: "Datum för nästa event",
        pause_invoice: "Pausa faktura",
        pause_until: "Pausa tills",
        segment_details: "Segmentdetaljer",
        create_new_customer_segment: "Skapa ett nytt kundsegment",
        shipping_charge: "Fraktavgift",
        invoice_charge: "Fakturaavgift",
        complete: "Klar",
        edit_segment: "Redigera segment",
        edit: "Redigera",
        update_settings: "Uppdatera inställningar",
        go_back_to_segment_overview: "Gå tillbaka till segmentvyn",
        remove_segment: "Radera segment",
        add_customer_to_segment: "Lägg till kunder i segment",

        see_all_segment_setting: "Se alla segmentinställningar",
        e_document_distribution_way: "Distributionssätt för e-dokument",
        segment_agreement_text:
            "Jag förstår att ovan inställningar kommer att appliceras på samtliga kunder i segmentet",
        no_of_customers: "Antal kunder",
        no_of_customers_text: "Består av",
        segment_name: "Segmentnamn",
        no_of_products: "antal produkter",
        create_new_product_segment: "Skapa nytt artikelsegment",
        product_segment: "Produktsegment",
        product_type: "Produkttyp",
        sales_account_eu_vat: "Intäktskonto EU (momspliktig)",
        sales_account_se: "Intäktskonto Sverige",
        sales_account_export: "Intäktskonto Export",
        product_segment_agreement:
            "Jag förstår att ovan inställningar kommer att appliceras på samtliga kunder i segmentet",
        previous: "Föregående",
        assign_product: "Tilldela produkter",
        subject: "Ämne",
        received: "Mottagen",
        payments_received: "Mottagna betalningar",
        alert: "Notifikation",
        see_full_message: "Se hela meddelandet",
        add_product_to: "Lägg till produkter i",
        add_product_to_segment: "Lägg till produkter i segment",
        create_segment: "Skapa segment",
        added_products: "Tillagda produkter",
        inbox: "Inkorg",
        see_archived_alert: "Se arkiverade notifikationer",
        empty_alert: "Du har för nuvarande inga notifikationer",
        unarchived_message: "Är du säker på att du vill av-arkivera",
        archived_message: "Är du säker på att du vill arkivera",
        unarchived_alert: "Notifikationen har flyttats tillbaka till inkorgen",
        deleted_alert: "Notifikationen har raderats",
        archived_alert: "Notifikationen har arkiverats",
        alert_message: "Meddelande",
        unarchiving: "Av-arkivera",
        archiving: "Arkiverar",
        archive: "Arkivera",
        go_back_inbox: "Gå tillbaka till inkorgen",
        archive_message: "Arkivera meddelande",
        product_detail: "Produktdetaljer",
        create_invoices_on_the: "Skapa faktura den",
        of_every_month_for_contract:
            "varje månad, för kontrakt med periodstart inom",
        days_of_that_day: "dagar från den dagen",
        last_workday_of_month: "sista arbetsdagen i månaden",
        first_workday_of_month: "första arbetsdagen i månaden",
        of_every_month_for_contract_workday: "varje månad, för kontrakt med periodstart inom nuvarande/kommande månad",
        let_application_send_invoice:
            "Låt applikationen automatiskt skicka avtalsfakturor",
        send_invoice_with_fortnox_finance:
            "Skicka fakturor med Fortnox Fakturaservice",
        use_eprint_when_customer_missing_email:
            "Skicka fakturan via Fortnox utskriftstjänst om kunden saknar mejladress",
        use_eprint_for_print:
            "Använd Fortnox utskriftstjänst istället för vanlig utskrift",
        send_failed_nox_as_reg:
            "Fakturor som ej kan skickas via Fortnox Fakturaservice ska skickas direkt till kund",
        auto_create_invoice:
            "Let the application automatically send contract invoices",
        send_all_invoices_through: "Skicka alla fakturor via",
        send_with_condition: "Skicka fakturor med",
        configuration: "Konfiguration",
        all_invoice: "Alla fakturor",
        go_back_to_configuration: "Gå tillbaka till konfigurationssidan",
        invoice_configuration_page: "Guide för konfiguration av faktura",
        contract_configuration_page: "Kontrakt  konfiguration guide",
        remove_from_segment: "Ta bort från segment",
        add_invoice_row: "Lägg till fakturarad",
        quarterly: "Kvartalsvis",
        semi_annually: "Halvårsvis",
        yearly: "Årsvis",
        account_settings: "Kontoinställningar",
        update_your_information: "Uppdatera din information",
        full_name: "Ditt namn",
        phone_number: "Telefonnummer",
        change_password: "Byt lösenord",
        new_password: "Nytt lösenord",
        confirm_password: "Bekräfta nytt lösenord",
        current_password: "Nuvarande lösenord",
        update_password: "Uppdatera lösenord",
        update_profile: "Uppdatera profil",
        update_profile_picture: "Uppdatera profilbild",
        current_profile_picture: "Nuvarande profilbild",
        choose_a_new_picture: "Välj ny profilbild",
        go_back_to_home_page: "Gå tillbaka",
        void: "Makulera",
        preselected_template: "Förvalda mallar",
        preselected_distribution_ways: "Förvalda distributionssätt",
        you_are_currently_on_the: "Ni prenumererar nu på",
        plan_paying: "paketet och betalar",
        as_a_monthly_average_your_current_tier_cover_up_to:
            "per månad i genomsnitt. Er nuvarande tier täcker upp till",
        active_contract: "aktiva avtal.",
        you_signed_up_for: "Ni signade upp er på",
        months_binding_on: "månaders bindningstid",
        none: "Inga",
        equal: "Lika med",
        less_than: "Mindre än",
        greater_than: "Mer än",
        with_fortnox_finance: "med Fortnox Fakturaservice",
        inactivated: "avslutade",
        added_customers: "Tillagda kunder",
        add_customers: "Lägg till kunder",
        add_products: "Lägg till produkter",
        delete_segment: "Radera segment",
        number_of_product: "Antal produkter",
        login_successfully: "Inloggning lyckades",
        all_fields_are_required: "alla fält är obligatoriska",
        more_customer_detail: "Fler kunddetaljer",
        sign_out: "Logga ut",
        profile_settings: "Kontoinställningar",
        event_type: "Kategori",
        event_message: "Händelse",
        event_success: "Lyckad",
        event_info: "Information",
        event_info_short: "Info",
        event_error: "Misslyckad",
        event_error_short: "Fel",
        event_all: "Alla",
        event_view: "Se hela",
        event_subject: "Ämne",
        view_notifications: "Se notifikationer",
        welcome_back: "Välkommen tillbaka",
        inactivated_contracts: "Inaktiva",
        terminated_contracts: "Avslutade",
        active_contracts: "Aktiva",
        active_resources: "Aktiva",
        inactive_resources: "Inaktiv",
        inactivated_resources: "Inaktiva",
        tag_contract: "Tagga avtal",
        activate_contract: "Aktivera avtal",
        inactivate_contract: "Inaktivera avtal",
        add_tags: "Lägg till tagg",
        tags_assigned: "Tagg(ar) har lagts till",
        choose_contract: "Vänligen välj ett eller flera avtal",
        success_inactivate_contract: "Avtal inaktiverades",
        success_activate_contract: "Avtal aktiverades",
        choose_action: "Vänligen välj en action att utföra",
        choose_customer: "Vänligen välj en eller flera kunder",
        choose_invoice: "Vänligen välj en eller flera fakturor",
        choose_article: "Vänligen välj en eller flera produkter",
        choose_columns: "Vänligen välj en eller flera kolumner",
        limited_read_access: "Du har inte behörighet att utföra denna handling",
        sync_contracts: "Avtal synkas nu i bakgrunden",
        sync_customers: "Kunder synkas nu i bakgrunden",
        sync_invoices: "Fakturor synkas nu i bakgrunden",
        sync_articles: "Artiklar synkas nu i bakgrunden",
        fail_sync_contracts: "Avtal kunde ej synkas",
        fail_sync_customers: "Kunder kunde ej synkas",
        fail_sync_invoices: "Fakturor kunde ej synkas",
        fail_sync_articles: "Artiklar kunde ej synkas",
        updated_customer_info: "Kundstatus uppdaterades",
        request_failure: "Någonting gick fel, vänligen prova igen",
        action_failed: "Action kunde ej genomföras",
        action_success: "Action genomfördes",
        updated_invoice_info: "Fakturainformationen uppdaterades",
        payment_date: "Betaldatum",
        fields_required: "Vänligen fyll i samtliga fält",
        invalid_amount_input: "Vänligen ange ett giltigt belopp",
        invoice_payment_registered: "Fakturabetalning registrerades",
        registering: "Registrerar",
        status: "Status",
        updated_article_info: "Artikelstatus uppdaterades",
        contract_terminated: "Avtal avslutades",
        password_do_not_match: "Lösenorden matchar inte",
        password_not_valid: "Lösenordet måste vara minst 8 tecken långt",
        invalid_user_token:
            "Någonting gick fel, vänligen kontakta vårt kundsupport team",
        password_confirmation: "Ditt lösenord har uppdaterats",
        email_required: "Vänligen ange din mejladress",
        invalid_email: "Ogiltig mejladress",
        email_sent:
            "Ett mejl för att ställa om ditt lösenord har skickats till din mejladress",
        articles_added: "Artiklarna lades till i segmentet",
        articles_not_added: "Artiklarna kunde inte läggas till i segmentet",
        customers_added: "Kunderna lades till i segmentet",
        select_reason: "Vänligen ange varför du vill avsluta tjänsten",
        write_reason: "Vänligen beskriv varför du vill avsluta tjänsten",
        already_on_package:
            "Ni prenumrerar redan på detta paket med vald betalningsperiod",
        cannot_change_payment_plan_on_free:
            "Ni prenumerar för nuvarande på Gratis-paketet och kan därför inte byta betalningsperiod",
        paymentplan_updated: "Er betalningsperiod har uppdaterats",
        configuration_updated: "Konfigurationen har uppdaterats",
        configuration_not_updated: "Konfigurationen har uppdaterats",
        select_account_number: "Vänligen ange bokföringskonto",
        description_article_required:
            "Ange artikelnummer, beskrivning eller både och",
        invalid_invoice_row: "Vänligen ange giltig fakturarad data",
        starred_fields_required: "Fält med en asterick är obligatoriska",
        customer_number_name_required: "Kundnummer och kundnamn är obligatoriska",
        customer_deleted: "Kund raderades",
        segment_removed: "Segmentet raderades",
        segment_settings_confirmation:
            "Vänligen bekräfta att du godkänner att samtliga segmentinställningar appliceras på samtliga tillagda resurser",
        segment_added: "Segmentet skapades",
        segment_updated: "Segmentet uppdaterades",
        name_description_required: "Namn och beskrivning är obligatoriska",
        customer_removed_segment: "Kunden togs bort från segmentet",
        article_removed_segment: "Artikeln togs bort från segmentet",
        invoice_voided: "Fakturan makulerades",
        voiding: "Makulerar",
        invoice_booked: "Fakturan bokfördes",
        book_before_credit: "Endast bokförda fakturor kan krediteras",
        invalid_date: "Vänligen ange ett giltigt datum",
        nox_paused: "Faktura har pausats",
        nox_stopped: "Faktura har stoppats",
        noxs_paused: "Fakturor har pausats",
        noxs_stopped: "Fakturor har stoppats",
        article_created: "Artikel skapades",
        article_updated: "Artikel uppdaterades",
        article_deleted: "Artikel raderades",
        deleting: "Raderar",
        number_pricelist_price_required:
            "Artikelnummer, prislista och pris är obligatoriska",
        price_added: "Artikelpris lades till",
        price_updated: "Artikelpris uppdaterades",
        price_deleted: "Artikelpris raderades",
        void_confirmation: "Är du säker på att du vill makulera",
        customer_segment: "Kundsegment",
        article_segment: "Artikelsegment",
        information_updated: "Informationen har uppdaterats",
        password_updated: "Lösenordet har uppdaterats",
        invalid_file_type: "Ogiltig filtyp",
        image_uploaded: "Bilden har laddats upp",
        select_file: "Välj en bild att ladda upp",
        upload_image: "Ladda upp bild",
        max_users:
            "Ert Likvio team består av max tillåtna användare som ert paket tillåter, uppgradera till ett annat paket för att kunna bjuda in fler användare",
        owner: "Ägare",
        staff: "Anställd",
        accountant: "Ekonom",
        other: "Annan",
        package_user_text_1: "Ni prenumerar just nu på ett paket som tillåter",
        package_user_text_2: " användare. Gå till ",
        pacakge_user_text_3:
            "och uppgradera ert paket för att lägga till fler användare.",
        full_access: "Full tillgång",
        limited_access: "Begränsad tillgång",
        read_access: "Läsbehörighet",
        customization_contract: "Anpassa kolumner (Avtal)",
        customization_customer: "Anpassa kolumner (Kunder)",
        customization_invoice: "Anpassa kolumner (Fakturor)",
        customization_article: "Anpassa kolumner (Artiklar)",
        update: "Uppdatera",
        select_columns: "Välj vilka kolumner som ska visas",
        choose_plan: "Välj detta paket",
        current_package: "Nuvarande paket",
        sla_addon:
            "I ovan pris ingår SLA (Service-Level Agreement) på 1090 SEK/mån exkl. moms",
        contracts_to_exclude: "Avtal att exkludera från autoförnyelse",
        contracts_to_exclude_helptext:
            "Klicka i checkboxen för de avtal du vill exkludera från autoförnyelse. Klicka på Spara när du är klar.",
        please_wait: "Vänligen vänta medan vi behandlar din förfrågan",
        create_contract: "Skapa avtal",
        settings: "Inställningar",
        date_to_change_price: "Datum för prisändring",
        schedule_contract_cancelling: "Schemalägg avtalsavslut",
        date_to_cancel: "Datum för avslut",
        type_of_cancellation: "Typ av avslut",
        terminate: "Avsluta",
        inactivate: "Inaktivera",
        choose_an_option: "Välj ett alternativ",
        schedule_invoice_send_out: "Schemalägg fakturautskick",
        date_to_send: "Datum för utskick",
        bookkeep_invoice: "Bokför fakturan",
        send_failed_nox_as_reg_2:
            "Skicka direkt till kund om Fortnox Fakturaservice misslyckas",
        distribution_method: "Distributionsmetod",
        regular: "Standard",
        fortnox_invoice_service: "Fortnox Fakturaservice",
        already_scheduled_modal_title: "Redan schemalagt",
        already_scheduled_modal_list_label:
            "Följande resurser har redan schemalagts",
        already_scheduled_modal_note1:
            "Du kan redigera befintliga schemaläggningar genom att klicka på den här ikonen",
        already_scheduled_modal_note2:
            "Denna ikon finns i action-kolumnen på respektive rad i tabellen",
        select_unscheduled_row:
            "Välj en resurs som inte redan schemalagts för att utföra denna åtgärd",
        proceed: "Fortsätt",
        delete: "Radera",
        update_contract_prices: "Uppdatera avtalspriser",
        already_price_change_modal_title: "Redan schemalagda prisändringar",
        already_price_change_modal_list_label:
            "Prisändingar har redan schemalagts för följande avtal",
        already_price_change_modal_note1:
            "Du kan redigera den befintliga schemalagda prisändringen genom att klicka på denna ikon",
        already_price_change_modal_note2:
            "Denna ikon finns i action-kolumnen på avtalsraden i tabellen",
        select_price_unchanged_row:
            "Välj ett avtal som inte redan schemalagts för prisändring för att utföra denna åtgärd",
        type_of_price_change: "Typ av prisändring",
        increase: "Höj",
        decrease: "Minska",
        percentage_to_change_price: "Procententuell prisändring",
        ok: "Ok",
        invalid_row_selection_invoice:
            "Ingen giltig faktura har valts för schemaläggning av faktura",
        invalid_row_selection_schedule:
            "Inget giltigt avtal har valts för schemaläggning av avslut",
        invalid_row_selection_price_change:
            "Inget giltigt avtal har valts för uppdatering av pris",
        update_contract_price: "Uppdatera avtalspriser",
        no_graph_data: "Finns ingen data att fylla grafen med",
        price_incl_vat: "Priser inkl. moms",
        auto_bookkeep_invoices: "Bokför fakturor",
        contract_price_updates: "Prisuppdateringar i avtal",
        let_application_book_invoice: "Låt Likvio bokföra skapade fakturor",
        incl: "Inkl.",
        excl: "Exkl.",
        incl_vat: "Inklusive moms",
        excl_vat: "Exklusive moms",
        let_application_update_prices: "Låt Likvio auto-uppdatera avtalspriser",
        prices_and_vat: "Priserna i Fortnox artikelregister är",

        update_contracts_with_template_data: "Uppdatera automatiskt avtalsinställningar baserat på avtalsmallen",
        let_application_update_contract_data:
            "Låt Likvio uppdatara avtal med inställningarna i dess avtalsmall",
        data_to_update: "Inställningar att uppdatera",

        "sentToCustomer": "Skickade till kund",
        "sentViaNox": "Skickade via Fortnox Fakturaservice",
        "notSent": "Ej skickade",
        "invoiceInterval": "Fakturaintervall",
        "contractLength": "Avtalslängd",
        "invoicesRemaining": "Fakturor kvar på avtal",
        "periodStartUntil": "Periodstartsdatum",
        "periodEndUntil": "Periodslutsdatum",
        Owner: "Ägare",
        Accountant: "Ekonom",
        Staff: "Anställd ",
        Other: " Annan ",
        Full: "Full ",
        Limited: "Begränsad",
        Read: "Läsbehörighet",
        createRecurringContract: "Skapa en återkommande avtalsföränding",
        InvoiceRowUpdate: "Uppdatering i fakturarad i avtal",
        contractTermination: "Avtalsavslut",
        amount_to_change_price: "Prisändringsbelopp",
        create_contract_price: "Uppdatera avtalspriser",
        updateRecurringContract: "Återkommande avtalsförändring",
        invoiceTextOnPriceChange: "Fakturatext på första fakturan efter prisförändringen (max 1000 tecken)",
        deletedSuccessfully: "Raderades",
        pickScheduleAction: "Välj åtgärd",
        completed: "Avklarad",
        createNewSchedule: "Skapa ny",
        searchInvoice: "Sök efter faktura",
        searchContract: "Sök efter avtal",
         searchArticle: "Sök efter artikel",
        noData: "Det finns ingen data att visa",
        recurringContractUpdate: "Återkommande avtalsuppdateringar",
        updateUntilSpecificValue: "Uppdatera avtalet tills ett specifikt värde har nåtts",
        updateNumberEachTime: "Uppdatera avtalet ett specifikt antal gånger",
        updateManyTimeUtilSpecificValue: '',
        eachTimeUpdateWithValue: "Uppdatera med detta belopp vid varje uppdatering",
        updateManyTimeUtilValue: "Uppdatera så här många gånger / tills att detta värde uppnåtts",
        lastUpdated: "Senaste uppdateringen",
        updateType: "Typ av uppdatering",
        videoGuides: "Videoguider",
        documentNumber: "Avtalsnummer",
        rowToExclude: "Fakturarader att exkludera",
        select: "Välj fakturarader",
        'increase_percentage': "Höj pris i procent",
        'decrease_percentage': "Sänk pris i procent",
        'increase_amount': "Höj pris med belopp",
        'decrease_amount': "Sänk pris med belopp",
        cancelContract: 'Avsluta avtal',
        changeCurrentValueWith: 'Ändra nuvarande värde med',
        increasePriceAmount: 'Höj pris med belopp',
        decreasePriceAmount: 'Minska pris med belopp',
        increasePricePercentage: 'Höj pris i procent',
        decreasePricePercentage: 'Minska pris i procent',
        unpaidBilling: "Obetalda",
        overdueBilling: "Förfallna",
        finishContractConfirmation: "Är du säker på att du vill avsluta avtal ",
        finishContractsConfirmation: "Är du säker på att du vill avsluta markerade avtal",
        inactivateContractConfirmation: "Är du säker på att du vill inaktivera avtal ",
        period_start_from: "Periodstart från",
        period_end_from: "Periodslut från",
        period_end_to: "Periodslut till",
        period_start_to: "Periodstart till",
        search_for_an_article: "Sök efter artikel",
    },
};

const i18n = createI18n({
    locale: "se", // set locale
    fallbackLocale: "en", // set fallback locale
    messages, // set locale messages
});

export default i18n;
