const TYPES = {
  UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
  UPDATE_USERS: "UPDATE_USERS",
  UPDATE_CONTRACTS: "UPDATE_CONTRACTS",
  UPDATE_SINGLE_CONTRACTS: "UPDATE_SINGLE_CONTRACTS",
  UPDATE_CUSTOMERS: "UPDATE_CUSTOMERS",
  UPDATE_SINGLE_CUSTOMER: "UPDATE_SINGLE_CUSTOMER",
  UPDATE_INVOICE_FORTNOX: "UPDATE_INVOICE_FORTNOX",
  UPDATE_INVOICE: "UPDATE_INVOICE",
  UPDATE_SINGLE_INVOICE: "UPDATE_SINGLE_INVOICE",
  UPDATE_BILLING: "UPDATE_BILLING",
  UPDATE_BILLING_HISTORY: "UPDATE_BILLING_HISTORY",
  UPDATE_USER_SUBSCRIPTION: "UPDATE_USER_SUBSCRIPTION",
  UPDATE_NOTIFICATION_ALERT: "UPDATE_NOTIFICATION_ALERT",
  UPDATE_NOTIFICATION_EVENTS: "UPDATE_NOTIFICATION_EVENTS",
  UPDATE_SINGLE_EVENTS: "UPDATE_SINGLE_EVENTS",
  UPDATE_ARTICLES: "UPDATE_ARTICLES",
  UPDATE_SINGLE_ARTICLE: "UPDATE_SINGLE_ARTICLE",
  UPDATE_SINGLE_NOTIFICATION_ALERT: "UPDATE_SINGLE_NOTIFICATION_ALERT",
  UPDATE_SEGMENT_ARTICLES: "UPDATE_SEGMENT_ARTICLES",
  UPDATE_SEGMENT_ARTICLE_INFO: "UPDATE_SEGMENT_ARTICLE_INFO",
  UPDATE_CUSTOMER_SEGMENT: "UPDATE_CUSTOMER_SEGMENT",
  UPDATE_SINGLE_CUSTOMER_SEGMENT: "UPDATE_SINGLE_CUSTOMER_SEGMENT",
  UPDATE_COST_CENTER: "UPDATE_COST_CENTER",
  UPDATE_CURRENCY: "UPDATE_CURRENCY",
  UPDATE_PRICELIST: "UPDATE_PRICELIST",
  UPDATE_TERMS_OF_DELIVERY: "UPDATE_TERMS_OF_DELIVERY",
  UPDATE_TERMS_OF_PAYMENT: "UPDATE_TERMS_OF_PAYMENT",
  UPDATE_WAY_OF_DELIVERY: "UPDATE_WAY_OF_DELIVERY",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_TAGS: "UPDATE_TAGS",
  UPDATE_PLAN: "UPDATE_PLAN",
  UPDATE_SUBSCRIPTION_PACKAGE: "UPDATE_SUBSCRIPTION_PACKAGE",
  UPDATE_CURRENT_CUSTOMER: "UPDATE_CURRENT_CUSTOMER",
  GET_SCHEDULES: "GET_SCHEDULES",
  UPDATED_SCHEDULE_LIST: "UPDATED_SCHEDULE_LIST",
};

export default TYPES;
