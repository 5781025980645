import endpoints from "./../../../../services/endpoints";
import HTTP from "./../../../../services/vue-axios";
import HTTP_ from "./../../../../services/vue-axios/common-http";
import TYPES from "./../types";

export default {
    async getCurrentUserInfo({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.USERS_ENDPOINT + "/me")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CURRENT_USER, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getUsers({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.USERS_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_USERS, res.data.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateUser({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.USERS_ENDPOINT + `/${payload.uuid}`, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateUserPassword({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.AUTH_ENDPOINT + "password/change", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async uploadUserPicture({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.USERS_ENDPOINT + "/image", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async inviteUser({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.USERS_ENDPOINT + "/invite", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getTags({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.TAG_ENDPOINT + `?type=${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_TAGS, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteSelectedUser({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.USERS_ENDPOINT + `/${payload.uuid}`)
                .then((res) => {
                    dispatch("getUsers")
                        .then((r) => {
                            resolve(res);
                        })
                        .catch((e) => {
                            resolve(res);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getContracts({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.CONTRACTS_ENDPOINT + `?${payload}`
                : endpoints.CONTRACTS_ENDPOINT;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CONTRACTS, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleContract({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONTRACTS_ENDPOINT + `/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_CONTRACTS, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addContract({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.CONTRACTS_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContract({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.CONTRACTS_ENDPOINT + `/${payload.document_number}`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContractTag({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.CONTRACTS_ENDPOINT + `/${payload.document_number}/tags`,
                {tags: payload.tags}
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateCustomerTag({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.CUSTOMER_ENDPOINT + `/${payload.customer_number}/tags`,
                {tags: payload.tags}
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateArticleTag({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.ARTICLE_ENDPOINT + `/${payload.article_number}/tags`, {
                tags: payload.tags,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCustomers({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.CUSTOMER_ENDPOINT + `?${payload}`
                : endpoints.CUSTOMER_ENDPOINT;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CUSTOMERS, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleCustomer({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_ENDPOINT + `/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_CUSTOMER, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateCustomerInfo({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMER_ENDPOINT + `/${payload.id}`, payload.data)
                .then((res) => {
                    if (res.status === 200) {
                        // commit(TYPES.UPDATE_SINGLE_CUSTOMER, res.data)
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async createCustomer({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.CUSTOMER_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getInvoiceFortnox({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.INVOICE_ENDPOINT +
                `/${endpoints.NOX_FINANS_INVOICE}?${payload}`
                : endpoints.INVOICE_ENDPOINT + `/${endpoints.NOX_FINANS_INVOICE}`;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_INVOICE_FORTNOX, res.data.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getInvoice({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.INVOICE_ENDPOINT + `?${payload}`
                : endpoints.INVOICE_ENDPOINT;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_INVOICE, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleInvoice({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.INVOICE_ENDPOINT + `/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_INVOICE, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async customPutRequestInvoice({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.URL, payload.data)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateInvoice({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.INVOICE_ENDPOINT + `/${payload.id}`, payload.data)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addInvoice({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.INVOICE_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getBilling({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.BILLING_ENDPOINTS + "detail")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_BILLING, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getBillingHistory({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.BILLING_ENDPOINTS + `?${payload}`
                : endpoints.BILLING_ENDPOINTS;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_BILLING_HISTORY, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateBilling({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.BILLING_ENDPOINTS + "detail", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getUserSubscription({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SUBSCRIPTION_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_USER_SUBSCRIPTION, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async cancelSubscription({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.SUBSCRIPTION_ENDPOINT + `${payload.id}/cancel`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updatePaymentPlan({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.SUBSCRIPTION_ENDPOINT + `${payload.uuid}/change_plan/`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updatePackagePlan({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.SUBSCRIPTION_ENDPOINT + `${payload.uuid}/${payload.mode}`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getNotificationAlert({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.NOTIFICATION_ENDPOINT +
                `alerts?archived=${payload.archived}`
                : endpoints.NOTIFICATION_ENDPOINT + "alerts";
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_NOTIFICATION_ALERT, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async archiveAlertMessage({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.NOTIFICATION_ENDPOINT + `alerts/${payload.id}`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getAlertFullMessage({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.NOTIFICATION_ENDPOINT + `alerts/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_NOTIFICATION_ALERT, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async markAlertRead({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.NOTIFICATION_ENDPOINT + `alerts/${payload}/read`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteAlertMessage({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.NOTIFICATION_ENDPOINT + `alerts/${payload.id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getNotificationEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.NOTIFICATION_ENDPOINT + `events?${payload}`
                : endpoints.NOTIFICATION_ENDPOINT + "events";
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_NOTIFICATION_EVENTS, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.NOTIFICATION_ENDPOINT + `events/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_EVENTS, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getProduct({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.ARTICLE_ENDPOINT + `?${payload}`
                : endpoints.ARTICLE_ENDPOINT;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_ARTICLES, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // get current customer
    async getCurrentCustomer({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = endpoints.CUSTOMER_ENDPOINT + `/${payload}`;
            HTTP.get(URL)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CURRENT_CUSTOMER, res.data.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getSingleArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ARTICLE_ENDPOINT + `/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_ARTICLE, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateArticleStatus({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.ARTICLE_ENDPOINT + "/status", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleArticlePrice({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.ARTICLE_ENDPOINT +
                `/prices/?article__article_number=${payload}`
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addArticlePrice({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.ARTICLE_ENDPOINT + "/prices/", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateArticle({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.ARTICLE_ENDPOINT + `/${payload.article_number}`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async createArticle({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.ARTICLE_ENDPOINT, payload.data)
                .then((res) => {
                    if (res.status === 201) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async removeArticle({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.ARTICLE_ENDPOINT + `/${payload.id}`)
                .then((res) => {
                    if (res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async removeArticlePrice({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.ARTICLE_ENDPOINT + `/prices/${payload.id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateArticlePrice({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.ARTICLE_ENDPOINT + `/prices/${payload.id}`, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getConfigurationUtility({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONFIGURATION_ENDPOINT + `/${payload.mode}`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateConfiguration({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.CONFIGURATION_ENDPOINT +
                `/${payload.mode}/${payload.payload.id}/`,
                payload.payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getGuide({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GUIDE_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async createProductSegment({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SEGMENT_ENDPOINT + "articles/", payload)
                .then((res) => {
                    if (res.status === 201) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getProductSegment({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SEGMENT_ENDPOINT + `articles`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SEGMENT_ARTICLES, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSegmentProductDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SEGMENT_ENDPOINT + `articles/${payload}/`)
                .then((res) => {
                    commit(TYPES.UPDATE_SEGMENT_ARTICLE_INFO, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async removeProductSegment({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.SEGMENT_ENDPOINT + `articles/${payload.id}/`)
                .then((res) => {
                    if (res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCustomerSegment({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SEGMENT_ENDPOINT + `customers?${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CUSTOMER_SEGMENT, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteCustomerSegment({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.SEGMENT_ENDPOINT + `customers/${payload["id"]}/`)
                .then((res) => {
                    if (res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleCustomerSegment({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SEGMENT_ENDPOINT + `customers/${payload}/`)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_SINGLE_CUSTOMER_SEGMENT, res.data);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCostCenter({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "costcenters")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_COST_CENTER, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCurrencies({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "currencies")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_CURRENCY, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getPricelists({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "pricelists")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_PRICELIST, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getTermsOfDelivery({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "termsofdelivery")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_TERMS_OF_DELIVERY, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getTermsOfPayment({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "termsofpayment")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_TERMS_OF_PAYMENT, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getWayOfDelivery({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "wayofdelivery")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_WAY_OF_DELIVERY, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getProjects({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ACCOUNT_REGISTERS_ENDPOINT + "projects")
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_PROJECT, res.data.results);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getContractCustomizeHeader({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMIZATION_ENDPOINT + "contract")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCustomerCustomizeHeader({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMIZATION_ENDPOINT + "customer")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteSchedules({commit}, payload) {
        return new Promise((resolve, reject) => {
            // const URL = payload
            //   ? endpoints.SCHEDULES_ENDPOINT + `list` + `${payload}`
            //   : endpoints.SCHEDULES_ENDPOINT + `list`;
            const URL = endpoints.SCHEDULES_ENDPOINT + payload;
            HTTP.delete(URL)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSchedules({commit}, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload
                ? endpoints.SCHEDULES_ENDPOINT + `list` + `${payload}`
                : endpoints.SCHEDULES_ENDPOINT + `list`;
            HTTP.get(URL)
                .then((res) => {
                    commit(TYPES.GET_SCHEDULES, res.data.results);
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getInvoiceCustomizeHeader({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMIZATION_ENDPOINT + "invoice")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getArticleCustomizeHeader({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMIZATION_ENDPOINT + "article")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContractCustomization({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMIZATION_ENDPOINT + "contract", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateCustomerCustomization({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMIZATION_ENDPOINT + "customer", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateInvoiceCustomization({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMIZATION_ENDPOINT + "invoice", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateInvoiceAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.INVOICE_ENDPOINT + "/bulkactions", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateNoxFinanceAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.INVOICE_ENDPOINT +
                "/" +
                endpoints.NOX_FINANS_INVOICE +
                "bulk/send_invoice",
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async sendInvoiceToNoxFinance({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(
                endpoints.INVOICE_ENDPOINT +
                "/" +
                endpoints.NOX_FINANS_INVOICE +
                "send_invoice",
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async updateBulkNoxFinanceAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.INVOICE_ENDPOINT +
                "/" +
                endpoints.NOX_FINANS_INVOICE +
                `bulk/${payload["action"]}`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async postNoxFinanceAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.INVOICE_ENDPOINT +
                "/" +
                endpoints.NOX_FINANS_INVOICE +
                `${payload["id"]}/${payload["action"]}/`,
                payload
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async postInvoicePayment({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.INVOICE_PAYMENT_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateArticleCustomization({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMIZATION_ENDPOINT + "article", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getCustomRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async downloadFile({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL, {
                responseType: "blob",
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async deleteCustomer({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.CUSTOMER_ENDPOINT + `/${payload.id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getNotification({commit}, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.NOTIFICATION_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        commit(TYPES.UPDATE_NOTIFICATION);
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addTags({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.TAG_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async removeTags({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.TAG_ENDPOINT + `${payload}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async syncResource({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SYNCHRONIZE_ENDPOINT + `${payload}/`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async assignBulkTag({_}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.URL, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateCustomerStatus({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CUSTOMER_ENDPOINT + "/status", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContractAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CONTRACTS_ENDPOINT + "/bulkactions", payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContractSingleAction({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(
                endpoints.CONTRACTS_ENDPOINT + `/${payload["id"]}/${payload["action"]}`
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async customPostRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async customPutRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.URL, payload.data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async customDeleteRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getPlanDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
                .then((res) => {
                    commit(TYPES.UPDATE_PLAN, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSubscriptionPackages({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SUBSCRIPTION_PACKAGE)
                .then((res) => {
                    commit(TYPES.UPDATE_SUBSCRIPTION_PACKAGE, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addScheduleContractCancelation({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SCHEDULE_CONTRACT_CANCELATION, {
                contracts: payload.contracts,
                date_to_cancel: payload.date_to_cancel,
                action: payload.type_of_cancellation,
                handled: false,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addScheduleEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SCHEDULE_EVENT, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateScheduleEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.SCHEDULE_EVENT + `/${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteScheduleEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.SCHEDULE_EVENT + `/${payload.id}/`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteScheduleContractCancelation({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.SCHEDULE_CONTRACT_CANCELATION + `/${payload}`)
                .then((res) => {
                    if (res.status === 200 || res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateScheduleContractCancelation({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.SCHEDULE_CONTRACT_CANCELATION + `/${payload.id}`, {
                date_to_cancel: payload.date_to_cancel,
                action: payload.type_of_cancellation,
                handled: false,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addScheduleInvoiceSendout({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SCHEDULE_INVOICE_SENDOUT, {
                invoices: payload.invoices,
                date_to_send: payload.date_to_send,
                send_channel: payload.send_channel,
                send_method: payload.send_method,
                bookkeep: payload.bookkeep,
                send_failed_nox_as_reg: payload.send_failed_nox_as_reg,
                handled: false,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteScheduleInvoiceSendout({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.SCHEDULE_INVOICE_SENDOUT + `/${payload}`)
                .then((res) => {
                    if (res.status === 200 || res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateScheduleInvoiceSendout({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.SCHEDULE_INVOICE_SENDOUT + `/${payload.id}`, {
                date_to_send: payload.date_to_send,
                send_channel: payload.send_channel,
                send_method: payload.send_method,
                handled: false,
                bookkeep: payload.bookkeep,
                send_failed_nox_as_reg: payload.send_failed_nox_as_reg,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.UPDATE_CONTRACT_PRICE, {
                contracts: payload.contracts,
                date_to_update: payload.date_to_update,
                change_type: payload.change_type,
                percentage: payload.percentage,
                handled: false,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async onAddContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.UPDATE_CONTRACT_PRICE, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async initiateContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.UPDATE_CONTRACT_PRICE, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.UPDATE_CONTRACT_PRICE + `/${payload.id}`, {
                date_to_update: payload.date_to_update,
                change_type: payload.change_type,
                percentage: payload.percentage,
                handled: false,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async createContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.UPDATE_CONTRACT_PRICE, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async onUpdateContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.UPDATE_CONTRACT_PRICE + `/${payload.id}`, payload.data)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteContractPriceUpdate({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.UPDATE_CONTRACT_PRICE + `/${payload}`)
                .then((res) => {
                    if (res.status === 200 || res.status === 204) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async addContractPriceUpdateToday({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.UPDATE_CONTRACT_PRICE_TODAY, {
                selected: payload.selected,
                change_type: payload.change_type,
                percentage: payload.percentage,
                // handled: false
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async onAddcontractPriceUpdateToday({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.UPDATE_CONTRACT_PRICE_TODAY, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async contractPriceActionToday({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.UPDATE_CONTRACT_PRICE_TODAY, payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getLast12MonthContractStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.CONTRACT_STATISTICS_ENDPOINT + "created_last_12_months"
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getAverageRecurringContractStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONTRACT_STATISTICS_ENDPOINT + "recurring_revenue")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getNumberOfContractStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.CONTRACT_STATISTICS_ENDPOINT + "contracts_per_contractlength"
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getCommonInvoiceIntervalsStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.CONTRACT_STATISTICS_ENDPOINT + "most_common_invoice_interval"
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getHighestRecurringlyBilledCustomersStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_STATISTICS_ENDPOINT + "recurrently_billed")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getLatePayingCustomersStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_STATISTICS_ENDPOINT + "average_lateness")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getBenefitCostRatioStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_STATISTICS_ENDPOINT + "benefit_cost_ratio")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getHistoricSalesStatistics({commit}, payload) {
        const params =
            payload.type == "segment"
                ? {
                    segment_id: payload.segment_id,
                }
                : {
                    customer_number: payload.customer_number,
                };
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_STATISTICS_ENDPOINT + "historic_sales", {
                params,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getLatePayingHistoryStatistics({commit}) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CUSTOMER_STATISTICS_ENDPOINT + "late_paying")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getBilledInvoiceStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.INVOICE_STATISTICS_ENDPOINT + "billed")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getReceivedPaymentsStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.INVOICE_STATISTICS_ENDPOINT + "received_payments")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getUnpaidOverdueStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.INVOICE_STATISTICS_ENDPOINT + "unpaid_overdue")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getLostRevenueStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.INVOICE_STATISTICS_ENDPOINT + "lost_revenue")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getArticleEarningStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ARTICLE_STATISTICS_ENDPOINT + "highest_lowest_earning")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getArticleSalesStatistics({commit}, payload) {
        const params =
            payload.type == "segment"
                ? {
                    segment_id: payload.segment_id,
                }
                : {
                    article_number: payload.article_number,
                };

        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ARTICLE_STATISTICS_ENDPOINT + "historic_sales", {
                params,
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getMostSoldArticlesStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ARTICLE_STATISTICS_ENDPOINT + "most_least_sold")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getArticleBenefitCostRatioStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.ARTICLE_STATISTICS_ENDPOINT + "benefit_cost_ratio")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getSalesPrognosisStatistics({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GENERAL_STATISTICS_ENDPOINT + "sales_prognosis")
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getSalesPrognosisForArticleStatistics({commit}, payload) {
        const params =
            payload.type == "segment"
                ? {
                    segment_id: payload.segment_id,
                }
                : {
                    article_number: payload.article_number,
                };

        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.GENERAL_STATISTICS_ENDPOINT + "sales_prognosis_article",
                {
                    params,
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getSalesPrognosisForCustomerStatistics({commit}, payload) {
        const params =
            payload.type == "segment"
                ? {
                    segment_id: payload.segment_id,
                }
                : {
                    customer_number: payload.customer_number,
                };

        return new Promise((resolve, reject) => {
            HTTP.get(
                endpoints.GENERAL_STATISTICS_ENDPOINT + "sales_prognosis_customer",
                {
                    params,
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // Blog actions
    async getBlogPosts({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP_.get(endpoints.BLOG_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getBlogCategories({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP_.get(endpoints.BLOG_CATEGORIES_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getSingleBlogPost({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP_.get(endpoints.BLOG_ENDPOINT + payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getFAQs({commit}, payload) {
        return new Promise((resolve, reject) => {
            let query = {};
            if (payload && payload.category) {
                query = {
                    category__eng_category: payload.category.eng_category,
                    category__swe_category: payload.category.swe_category,
                };
            } else if (payload && payload.search) {
                query = {
                    search: payload.search,
                };
            }
            // add query parameters
            HTTP.get(endpoints.FAQ_ENDPOINT, {params: query})
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async getFAQCategories({commit}) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.FAQ_CATEGORIES_ENDPOINT)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async openSupportTicket({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SUPPORT_TICKET_ENDPOINT, payload)
                .then((res) => {
                    if (res.status === 201 || res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getScheduleList({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.RECURRING_CONTRACT_UPDATE + `/${payload}`)
                .then((res) => {
                    commit(TYPES.UPDATED_SCHEDULE_LIST, res.data)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getSingleSchedule({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.RECURRING_CONTRACT_UPDATE + `/${payload.id}`)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async deleteSchedule({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.RECURRING_CONTRACT_UPDATE + `/${payload.id}/`)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async createRecurringSchedule({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.RECURRING_CONTRACT_UPDATE, payload.data)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async updateRecurringSchedule({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.RECURRING_CONTRACT_UPDATE + `/${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async getVideoGuide({commit}, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.VIDEO_ENDPOINT + `${payload}`)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
