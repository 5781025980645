import { saveToken, destroyToken } from './../../../../services/services-jwt'

export default {
    UPDATE_USER_TOKEN (state, payload) {
        if (payload.data.token) {
            const { token } = payload.data
            state.userToken = token
            saveToken(token)
        }
    },
    CLEAR_AUTH_USER (state, payload) {
        state.userToken = null
        // state.refreshToken = null
        destroyToken()
    },
}