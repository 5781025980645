import endpoints from './../../../../services/endpoints'
import HTTP from './../../../../services/vue-axios'
import HTTP_ from "../../../../services/vue-axios/common-http";
import TYPES from './../types'

export default {
    async loginUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP_.post(endpoints.AUTH_ENDPOINT + 'login', payload) // , { withCredentials: true, credentials: 'include' }
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_TOKEN, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async forgetUserPassword ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.AUTH_ENDPOINT + 'password/reset', payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async resetUserPassword ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP_.post(endpoints.AUTH_ENDPOINT + 'password/update_password', payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
