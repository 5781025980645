export default {
    notification(message, status) {
        if (status === 'success') {
            window.Bus.emit('notification', {message, status})
        }
        if (status === 'error') {
            window.Bus.emit('notification', {message, status})
        }
        if (status === 'warning') {
            window.Bus.emit('notification', {message, status})
        }
    },
    capitalizeNames: function (name) {
        return name !== null ? name.charAt(0).toUpperCase() + name.toLowerCase().slice(1) : name
    },
    validateEmailAddress(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    },
    removeUnderscoreAndCapString(name) {
        return name !== undefined ? name.split('_').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : name
    },
    removePercentage(data) {
        return data !== undefined ? data.toString().replace('%', '') : data
    },
    parseContent(item) {
        if (typeof item === 'boolean') {
            if (item === true) {
                return 'Yes'
            } else {
                return 'No'
            }
        } else {
            return item
        }
    },
    concatWord(item) {
        if (typeof item === 'object') {
            if (item !== '' && item !== null) {
                try {
                    let selected = []
                    item.map(item => {
                        if (selected.length <= 2) {
                            selected.push(item)
                        }
                    })
                    return `${selected.join(',')}...`
                } catch (e) {
                    return ''
                }
            }

        }

    },
    error_message(err) {
        try {
            if ([400, 406].includes(err.response.status)) {
                if ('error' in err.response.data) {
                    if (Array.isArray(err.response.data.error)) {
                        this.$services.helpers.notification(err.response.data.error.join(','), 'error', this)
                    } else if (typeof err.response.data.error === 'object') {
                        for (const [key, value] of Object.entries(err.response.data.error)) {
                            if (Array.isArray(value)) {
                                this.$services.helpers.notification(key.replace('_', ' ') + ': ' + value.join(','), 'error', this)
                            } else {
                                this.$services.helpers.notification(value, 'error', this)
                            }
                            return false
                        }
                    } else {
                        this.$services.helpers.notification(err.response.data.error, 'error', this)
                    }
                } else if ('message' in err.response.data) {
                    if (Array.isArray(err.response.data.message)) {
                        this.$services.helpers.notification(err.response.data.message.join(''), 'error', this)
                    } else {
                        this.$services.helpers.notification(err.response.data.message, 'error', this)
                    }
                }
            } else {
                this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
            }
        } catch (e) {
            this.$services.helpers.notification('Action could not be perform', 'error', this)
        }
    }
}
