<template>
    <div>
        <div class="modal-custom" v-if="open">
            <div class="modal-custom__backdrop" @click="closeNotification"/>
            <div class="general-user-confirm-popup">
                <div class="popup-box">
                    <div class="d-flex justify-content-center">
                        <img v-if="notificationObject.status === 'success'" src="./../assets/images/modal/notify-check.svg" alt="success | notification">
                        <img v-else-if="notificationObject.status === 'warning'" style="height: 72px;width: 72px" src="./../assets/images/modal/notify-warning.png" alt="success | notification">
                        <img v-else src="./../assets/images/modal/notify-times.svg" alt="success | notification">
                    </div>
                    <h4>{{ notificationObject.message }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'NotificationHandler',
        data () {
            return {
                notificationObject : {},
                open : false
            }
        },
        mounted () {
            let timer
            window.Bus.on('notification', e => {
                clearTimeout(timer)

                this.notificationObject = e
                this.open = true

                timer = setTimeout(() => {
                    this.closeNotification()
                }, 2500)
            })
        },
        methods : {
            closeNotification () {
                this.open = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal-custom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    //background: rgba(10, 4, 28, 0.36);
    //backdrop-filter: blur(3px);
    z-index: 1;
  }
}
// .users-edit-popup {
// //   width: 100%;
// //   height: 100%;
//   position: relative;
// //   display: -webkit-box;
// //   display: -webkit-flex;
// //   display: -moz-box;
// //   display: -ms-flexbox;
//   display: flex;
// //   -webkit-box-align: center;
// //   -webkit-align-items: center;
// //      -moz-box-align: center;
// //       -ms-flex-align: center;
// //           align-items: center;
// //   -webkit-box-pack: center;
// //   -webkit-justify-content: center;
// //      -moz-box-pack: center;
// //       -ms-flex-pack: center;
//           justify-content: center;
//   background: rgba(10, 4, 28, 0.36);
// //   overflow: auto;
// //   top: 0;
// //   left: 0;
// //   z-index: 999999;
// }
// .users-edit-popup .popup-box {
//   min-width: 300px;
//   max-width: 90%;
//   width: 430px;
//   position: relative;
//   z-index: 9;
//   background: #ffffff;
//   -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
//           box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
//   border-radius: 5px;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -moz-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -webkit-flex-direction: column;
//      -moz-box-orient: vertical;
//      -moz-box-direction: normal;
//       -ms-flex-direction: column;
//           flex-direction: column;
// }
.general-user-confirm-popup {
    // width: 100%;
    height: 100%;
//   position: fixed;
    margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(10, 4, 28, 0.36);
  overflow: auto;
  top: 0;
  left: 0;
//   z-index: 444;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
    position: relative;
}
.general-user-confirm-popup .popup-box {
  min-width: 300px;
  max-width: 90%;
  width: 430px;
  background: #ffffff;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px 40px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.general-user-confirm-popup .popup-box h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 25px;
  text-align: center;
}
.general-user-confirm-popup .popup-box .popup-bottons {
  text-align: center;
}
.general-user-confirm-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 140px;
  height: 32px;
  line-height: 22px;
  border: none;
  margin: 5px 10px;
}
.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}
.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type {
  background: #419e6a;
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #1f8c4d;
}
</style>