<template>
  <header>
    <nav>
      <div class="logo">
        <img
          src="./../../../assets/Landing/img/Likvio(4).png"
          alt="Likvio Logga"
          srcset=""
        />
      </div>

      <div class="nav-btns">
        <a href="#about" class="links">Om Likvio</a>
        <a href="#contact" class="links">Kontakt</a>
        <a href="#faq" class="links">FAQ</a>
        <a href="#price" class="links">Priser</a>
        <router-link class="links" to="/blog">Blogg</router-link>
        <a
          href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio"
          class="buttons"
          >Kom igång</a
        >
        <router-link :to="{ name: 'login' }" class="buttons"
          >Logga in</router-link
        >
      </div>
    </nav>
    <div class="banner">
      <div class="banner-text">
        <span class="banner-heading"
          >Att fakturera sina kunder har aldrig varit smidigare</span
        >
        <p>
          Likvio är en plattform för dig som använder Fortnox och vill samla
          dina ekonomiska ärenden i en lättnavigerad och högt funktionell portal
          med en modern och illustrerande design.
        </p>
        <a href="#price" class="main-btn">Se tillgängliga paket</a
        ><br /><br /><br /><br />
      </div>
      <div class="banner-img">
        <img
          src="./../../../assets/Landing/img/Coworking.png"
          alt="Kollegor samarbetar"
        />
      </div>
    </div>
    <h1>Vad tjänar jag som Fortnox användare på att använda Likvio?</h1>
    <div class="detail">
      <div class="box">
        <img
          src="./../../../assets/Landing/img/icons8-project-setup-100_1_75.png"
          alt="Konfigurera"
        />
        <div class="content">
          <span>Skräddarsydd konfiguration</span>
          <p>Skräddarsy applikationen utefter ditt bolags unika behov.</p>
        </div>
      </div>
      <div class="box">
        <img
          src="./../../../assets/Landing/img/icons8-process-100_75.png"
          alt="Automatisk fakturering"
        />
        <div class="content">
          <span>Automatisera faktureringen</span>
          <p>Låt applikationen sköta den återkommande avtalsfaktureringen.</p>
        </div>
      </div>
      <div class="box">
        <img
          src="./../../../assets/Landing/img/icons8-growing-money-100_1_75.png"
          alt="Avtalshantering"
        />
        <div class="content">
          <span>Förnyar utgångna avtal</span>
          <p>
            Riskera inte att förlora intäkter p.g.a. avtal som löpt ut utan er
            kännedom.
          </p>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="box">
        <img
          src="./../../../assets/Landing/img/icons8-name-tag-100_75.png"
          alt="Kund- och artikelsegment"
        />
        <div class="content">
          <span>Skapa och hantera segment</span>
          <p>
            Dela upp era kunder och produkter i passande segment för enkel
            masshantering.
          </p>
        </div>
      </div>
      <div class="box">
        <img
          src="./../../../assets/Landing/img/icons8-analytics-100_75.png"
          alt="Finansiell statistik"
        />
        <div class="content">
          <span>Samla ekonomisk statistik</span>
          <p>
            Informativa grafer baserade på datan i Fortnox ger er en god
            överblick av finanserna.
          </p>
        </div>
      </div>
      <div class="box">
        <img
          src="./../../../assets/Landing/img/schedule-75.png"
          alt="Schemalägg avtalsavslut och fakturautskick"
        />
        <div class="content">
          <span>Schemalägg händelser</span>
          <p>
            Glöm kalendernotiser! Använd Likvio för att schemalägga
            fakturautskick, avtalsavslut och pris-/rabatt-/kvantitetsförändringar i era
            avtal.
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  mounted() {
    setTimeout(() => {
      this.runStickyHeader();
    }, 100);
  },
  methods: {
    runStickyHeader() {
      let totopbtn = document.querySelector(".to-top-btn");
      let nav = document.querySelector("nav");
      if (totopbtn && nav) {
        window.addEventListener("scroll", () => {
          let svalue = window.scrollY;
          if (svalue > 40) {
            totopbtn.classList.add("active");
            nav.classList.add("sticky");
          } else {
            totopbtn.classList.remove("active");
            nav.classList.remove("sticky");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
h1 {
  font-size: 30px;
}
</style>
