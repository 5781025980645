<template>
  <router-view></router-view>
</template>

<script>
import { getLocale, saveLocale } from "@/services/storage-window";
import { mapGetters } from "vuex";

export default {
  name: "App",
  mounted() {
    if (!getLocale()) {
      saveLocale("se");
    } else {
      this.$i18n.locale = getLocale();
    }
    this.getUserSubscription();
  },
  methods: {
    fetchPage() {
      if (
        ![
          "index-page",
          "login",
          "forget",
          "reset",
          "finalize",
          "not-found",
        ].includes(this.$route.name)
      ) {
        if (this.USER_TOKEN) {
          this.getUserSubscription();
        }
      }
    },
    getUserSubscription() {
      this.$store
        .dispatch("clients/getUserSubscription")
        .then((res) => {
          this.getPackageInfo();
        })
        .catch((err) => {});
    },
    getPackageInfo() {
      const payload = {
        URL:
          this.$services.endpoints.PACKAGE_ENDPOINT +
          `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
      };
      this.$store.dispatch("clients/getPlanDetails", payload).then(() => {});
    },
  },
  computed: {
    ...mapGetters({
      USER_TOKEN: "auth/USER_TOKEN",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
    }),
  },
};
</script>

<style lan="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.content {
  padding-left: 80px !important;
}

.style-404 {
  background-color: #e9fcff !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .w-half {
    width: 50%;
  }
}

.unset-pad {
  padding: unset !important;
}

.p-left {
  padding-left: 70px;
}

.rounded-full-image {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
}

.rounded-img-full {
  border-radius: 50%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.loader {
  margin: 6px auto;
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #445ea5;
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid rgba(58, 53, 53, 0.2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.asterisk {
  color: rgba(235, 17, 17, 0.904);
}

.no-underline {
  text-decoration: none !important;
}

.w-full {
  width: 100% !important;
}

.bg-gray {
  background: rgba(196, 196, 196, 0.32) !important;
}

.h-5 {
  height: 1rem;
}

.w-5 {
  width: 1rem;
}

.tags-box {
  margin-left: 6rem;
  margin-top: -0.5rem;
}

.tooltip-custom {
  position: absolute;
  margin-top: -0.4rem;
  margin-left: 0.6rem;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #333269;
  /* background-color: #555; */
  color: #fff !important;
  /* text-align: center; */
  border-radius: 6px;
  font-size: 0.9rem !important;
  font-weight: normal !important;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.text-xs {
  font-size: 0.8rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#app {
  position: relative;
}
</style>
