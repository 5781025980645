<template>

  <div style="width:100%; z-index:99" v-if="openNotification" @click="openNotification = false"
       class="position-absolute top-0 right-0 left-0 bottom-0 bg-dange"></div>
  <div class="header">
    <div class="container-fluid d-flex justify-content-between">
      <div class="hide-on-med-and-down"><span class="ms-5 h-5"><img src="./../../../assets/likvio_logo.png" width="110"
                                                                    alt="logo"></span></div>
      <div class="move-right d-flex">
        <div class="line">
          <svg width="2" height="41" viewbox="0 0 2 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.2" x1="1.1792" y1="0.5" x2="1.1792" y2="40.5" stroke="#07102F"></line>
          </svg>
        </div>
        <div class="bubble" id="profile-locale">
                <span class="flag-image-box">
                    <img v-if="GET_LOCALE === 'en'" src="./../../../assets/images/flags/gb.svg" alt="British flag">
                    <img v-if="GET_LOCALE === 'se'" src="./../../../assets/images/flags/se.svg" alt="British flag">
                </span>
          <!-- <svg width="4" height="15" viewbox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="1.6649" cy="1.49619" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
              <ellipse cx="1.6649" cy="7.48057" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
              <ellipse cx="1.6649" cy="13.4659" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
          </svg> -->
          <nav id="locale-menu">
            <a @click="changeLocale('en')">
              <span><img class="me-2" src="./../../../assets/images/flags/4x3/GB.svg" alt="British flag">English</span>
            </a>
            <a @click="changeLocale('se')">
              <span><img class="me-2" src="./../../../assets/images/flags/4x3/SE.svg" alt="Swedish flag">Swedish</span>
            </a>
          </nav>
        </div>
        <div class="nofication cursor-pointer" @click="openNotification = true">
          <svg width="52" height="51" viewbox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <ellipse cx="23.7086" cy="22.5" rx="15.023" ry="15" fill="white"></ellipse>
              <path
                  d="M23.7083 7.0625C15.183 7.0625 8.24707 13.9879 8.24707 22.5C8.24707 31.0121 15.183 37.9375 23.7083 37.9375C32.2335 37.9375 39.1694 31.0121 39.1694 22.5C39.1694 13.9879 32.2335 7.0625 23.7083 7.0625ZM23.7083 32C22.1547 32 20.9193 31.3424 20.7535 29.953C20.7491 29.9115 20.7536 29.8695 20.7665 29.8298C20.7795 29.7901 20.8007 29.7536 20.8288 29.7227C20.8568 29.6917 20.8911 29.667 20.9294 29.6502C20.9676 29.6333 21.009 29.6248 21.0509 29.625H26.3619C26.4042 29.6247 26.446 29.6334 26.4846 29.6505C26.5232 29.6676 26.5577 29.6927 26.5859 29.7241C26.614 29.7556 26.6351 29.7927 26.6478 29.8329C26.6604 29.8732 26.6643 29.9156 26.6593 29.9575C26.4697 31.3209 25.2455 32 23.7083 32ZM30.9928 28.4375H16.4237C15.5436 28.4375 15.0857 27.3242 15.5919 26.7305C16.8057 25.2951 17.6635 24.6917 17.6635 20.7336C17.6635 17.1065 19.5768 15.8196 21.1572 15.1917C21.2659 15.1464 21.364 15.0789 21.445 14.9935C21.526 14.9082 21.5883 14.8068 21.6277 14.6959C21.902 13.7867 22.678 13 23.7083 13C24.7385 13 25.513 13.7867 25.7896 14.6966C25.8292 14.8075 25.8916 14.9088 25.9728 14.9942C26.0539 15.0796 26.152 15.1471 26.2608 15.1924C27.8374 15.8188 29.7545 17.108 29.7545 20.7343C29.7545 24.6924 30.6115 25.2958 31.8254 26.7312C32.3264 27.3242 31.8677 28.4375 30.9928 28.4375Z"
                  fill="#5855D6"></path>
              <path v-if="GET_NOTIFY_ALERT_READ_STATUS.length > 0"
                    d="M33.4102 17C33.4102 18.7946 31.953 20.25 30.1548 20.25C28.3565 20.25 26.8994 18.7946 26.8994 17C26.8994 15.2054 28.3565 13.75 30.1548 13.75C31.953 13.75 33.4102 15.2054 33.4102 17Z"
                    fill="#FF2C55" stroke="#5855D6" stroke-width="0.5"></path>
            </g>
            <defs>
              <filter id="filter0_d" x="-3.3208" y="-3.5" width="58.0583" height="58" filterunits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                <feflood flood-opacity="0" result="BackgroundImageFix"></feflood>
                <fecolormatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"></fecolormatrix>
                <feoffset dx="2" dy="3"></feoffset>
                <fegaussianblur stddeviation="5"></fegaussianblur>
                <fecolormatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></fecolormatrix>
                <feblend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feblend>
                <feblend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feblend>
              </filter>
            </defs>
          </svg>
        </div>
        <div v-if="openNotification" style="width: 20rem;margin-top:5rem; z-index:9999"
             class="position-absolute top-0 border shadow bg-light rounded-3 notification-box">
          <div class="d-flex flex-column position-relative">
            <small class="p-2"><b>{{ ($t('notifications')) }}</b></small>
            <div class="d-flex flex-column mt-1"
            >
              <div style="max-height: 400px;height: 400px;overflow-x: hidden;overflow-y: scroll">
                    <span class="d-flex flex-column p-2 bg-gray" v-for="(item, i) in GET_NOTIFY_ALERT.slice(1,15)"
                          :key="i">
                        <small v-if="item.alert_code">{{ item.alert_code.code }}</small>
                        <span style="font-size:0.8rem">
                            {{ item.message }}
                        </span>
                        <small class="text-xs">{{ item.received.split('T')[0] }}</small>
                        </span>
              </div>

              <router-link :to="{ name : 'alertinbox'}"
                           class="d-flex justify-content-between w-full p-3 text-center bg-secondary cursor-pointer no-underline">
                <small class="relative text-white w-full">{{ ($t('view_notifications')) }}</small>
              </router-link>
            </div>
          </div>
        </div>
        <div class="profile d-flex">
          <div v-if="!loading" class="me-3">
            <img class="rounded-img-full" v-if="GET_CURRENT_USER.image" :src="GET_CURRENT_USER.image"
                 alt="user image | likvio">
            <img v-else src="./../../../assets/images/profile-img/profile.png" alt="user image | likvio">
          </div>
          <div class="d-flex justify-content-between" v-if="loading">
            <div class="skeleton-img ml-5"></div>
            <div class="d-flex flex-column">
              <div class="skeleton-name mb-2"></div>
              <div class="skeleton-access-sub"></div>
            </div>
          </div>
          <div class="desc" v-else>
            <h6 class="whitespace-nowrap">{{ GET_CURRENT_USER.full_name }} </h6><span>{{
              GET_USER_SUBSCRIPTION.length && GET_USER_SUBSCRIPTION[0].company.name
            }}</span>
          </div>
        </div>
        <div class="bubble" id="profile-btn">
          <svg width="4" height="15" viewbox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="1.6649" cy="1.49619" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
            <ellipse cx="1.6649" cy="7.48057" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
            <ellipse cx="1.6649" cy="13.4659" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
          </svg>
          <nav id="profile-menu">
            <router-link :to="{ name : 'accountSettings' }">
              <span>{{ $t('profile_settings') }}</span>
            </router-link>
            <a href="javascript:void(0);" @click="signOutUser">
              <span>{{ $t('sign_out') }}</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import i18n from '@/i18n'

export default {
  name: 'navbarComponent',
  data() {
    return {
      loading: false,
      openNotification: false
    }
  },
  computed: {
    ...mapGetters({
      GET_CURRENT_USER: 'clients/GET_CURRENT_USER',
      GET_LOCALE: 'locale/GET_LOCALE',
      GET_NOTIFY_ALERT: 'clients/GET_NOTIFY_ALERT',
      GET_NOTIFY_ALERT_READ_STATUS: 'clients/GET_NOTIFY_ALERT_READ_STATUS',
      GET_USER_SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION',
    })
  },
  mounted() {
    this.initializeNavbarDropdown()
    this.initializeLocaleDropdown()
    this.getUserInformation()
    this.getNotification()
  },
  methods: {
    getUserInformation() {
      this.loading = true
      this.$store.dispatch('clients/getCurrentUserInfo')
          .then(_ => {
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    getNotification() {
      this.$store.dispatch('clients/getNotificationAlert')
          .then(_ => {
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    initializeNavbarDropdown() {
      const profilebtn = document.getElementById('profile-btn')
      const profilemenu = document.getElementById('profile-menu');
      profilebtn.addEventListener('click', function (e) {
        profilemenu.classList.toggle('active');
      })
    },
    initializeLocaleDropdown() {
      const profileLocale = document.getElementById('profile-locale')
      const localMenu = document.getElementById('locale-menu');
      profileLocale.addEventListener('click', function (e) {
        localMenu.classList.toggle('active');
      })
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$store.commit('locale/UPDATE_LOCALE', locale)
    },
    signOutUser() {
      this.$store.commit('auth/CLEAR_AUTH_USER', null)
      window.Bus.emit('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
.flag-image-box {
  display: block;
  width: 31px;
  height: 31px;
}

.flag-image-box img {
  border-radius: 50%;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  height: 83px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.skeleton-name {
  width: 120px;
  height: 1rem;
  background-color: #cecece;
  border-radius: .25rem;
}

.skeleton-access-sub {
  width: 70px;
  height: 1rem;
  background-color: #cecece;
  border-radius: .25rem;
}

.skeleton-img {
  width: 40px;
  height: 40px;
  background-color: #cecece;
  border-radius: 50%;
  margin-right: 1rem;
}
</style>