import axios from 'axios'


const HTTP_ = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})
export default HTTP_