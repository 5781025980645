const LIKVIO_TOKEN = '__likvio__'

export const saveToken = token => {
    window.localStorage.setItem(LIKVIO_TOKEN, token)
}
export const getToken = () => {
    return window.localStorage.getItem(LIKVIO_TOKEN)
}
export const destroyToken = () => {
    window.localStorage.removeItem(LIKVIO_TOKEN)
}

export default { saveToken, getToken, destroyToken }
