import moment from 'moment'
import endpoints from './endpoints'
import helpers from './helpers'

export default {
    install : (app, options) => {
        app.config.globalProperties.$services = {
            helpers,
            moment,
            endpoints
        }
    }
}