const endpoints = {
    BASE_ENDPOINT: "",
    AUTH_ENDPOINT: "auth/",
    ARTICLE_ENDPOINT: "articles",
    SEGMENT_ENDPOINT: "segments/",
    BILLING_ENDPOINTS: "billings/",
    CONFIGURATION_ENDPOINT: "configurations",
    CONTRACTS_ENDPOINT: "contracts",
    CUSTOMER_ENDPOINT: "customers",
    VIDEO_ENDPOINT: "video_guides",
    CUSTOMIZATION_ENDPOINT: "customizations/",
    SCHEDULES_ENDPOINT: "schedules/",
    INVOICE_ENDPOINT: "invoices",
    INVOICE_PAYMENT_ENDPOINT: "invoice_payment/",
    GUIDE_ENDPOINT: "guides",
    NOTIFICATION_ENDPOINT: "notifications/",
    NOX_FINANS_INVOICE: "noxfinansinvoices/",
    REGISTER_ENDPOINT: "register/",
    SUBSCRIPTION_ENDPOINT: "subscriptions/",
    PACKAGE_ENDPOINT: "package-details",
    TIER_ENDPOINT: "tier-details",
    SUBSCRIPTION_PACKAGE: "subscription-packages",
    USERS_ENDPOINT: "users",
    TAG_ENDPOINT: "tags/",
    STATS_ENDPOINT: "stats/",

    // statistics endpoints
    STATISTICS_ENDPOINT: "statistics/",
    CONTRACT_STATISTICS_ENDPOINT: "statistics/contracts/",
    CUSTOMER_STATISTICS_ENDPOINT: "statistics/customers/",
    INVOICE_STATISTICS_ENDPOINT: "statistics/invoices/",
    ARTICLE_STATISTICS_ENDPOINT: "statistics/articles/",
    GENERAL_STATISTICS_ENDPOINT: "statistics/general/",

    // Blog endpoints
    BLOG_ENDPOINT: "blog/",
    BLOG_CATEGORIES_ENDPOINT: "blog_categories/",

    // FAQ and Support endpoints
    FAQ_ENDPOINT: "faq/",
    FAQ_CATEGORIES_ENDPOINT: "faq_categories/",
    SUPPORT_TICKET_ENDPOINT: "open_ticket/",

    HELPTEXT_ENDPOINT: "helptexts",
    ACCOUNT_REGISTERS_ENDPOINT: "register/",
    SYNCHRONIZE_ENDPOINT: "/synchronize/",
    CONFIG_ROW_ENDPOINT: "/sub-contract/",
    SCHEDULE_CONTRACT_CANCELATION: "schedules/contract_termination",
    SCHEDULE_INVOICE_SENDOUT: "schedules/invoice_sendout",
    UPDATE_CONTRACT_PRICE: "schedules/contract_price_change",
    SCHEDULE_EVENT: "schedules/invoice_row_change",
    UPDATE_CONTRACT_PRICE_TODAY: "contracts/price_update",
    SCHEDULE_LISTING: 'schedules/list',
    RECURRING_CONTRACT_UPDATE: 'schedules/recurring_contract_update',
};

export default endpoints;
