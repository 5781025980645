export default {
    currentUser: {},
    users: [],
    customers: [],
    schedules: [],
    customersCopy: [],
    singleCustomer: {},
    contracts: [],
    contractsCopy: [],
    singleContract: {},
    invoice: [],
    invoiceCopy: [],
    invoiceFortnox: [],
    singleInvoice: {},
    billing: {},
    billingHistory: [],
    userSubscription: {},
    productArticles: [],
    productArticlesCopy: [],
    singleArticle: {},
    notificationAlert: [],
    notificationEvent: [],
    singleNotificationEvent: {},
    singleAlertMessage: {},
    contractConfig: {},
    invoiceConfig: {},
    articleSegment: {
        results: [],
    },
    articleSegmentInfo: {},
    customerSegments: [],
    singleCustomerSegment: {},
    costCenter: [],
    currency: [],
    priceList: [],
    termOfDelivery: [],
    termOfPayment: [],
    wayOfDelivery: [],
    projects: [],
    notification: [],
    Tags: [],
    planDetails: [],
    subscriptionPackages: [],
    vatConstant: 1.12,
    currentCustomer: {},
    scheduleList: {results: []},
    contractFilter: {},
    customerFilter: {},
    invoiceFilter: {},
    scheduledFilter: {},
    articleFilter: {},
    recurringUpdateFilter: {},
};
