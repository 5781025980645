import mutations from './mutations'
import getters from './getters'
import state from './state'

const locale = {
    namespaced : true,
    mutations,
    getters,
    state
}

export default locale
