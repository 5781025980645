<template>
  <div>
    <HeaderComponent />
    <main>
      <div class="section-1">
        <div class="img">
          <img
            src="./../../assets/Landing/img/Webp.net-resizeimage.png"
            alt="Portal för alla ekonomiska ärenden"
          />
        </div>
        <div class="content">
          <span
            >Visst vore det bra att kunna hålla koll på det mesta inom ekonomin
            på ett och samma ställe?</span
          >
          <p>
            Genom att använda Likvio har du och dina kollegor en tillförlitlig
            plattform att samarbeta i och se över allt från fakturautskick till
            den nuvarande och historiska finansiella situationen.
          </p>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Automatisk avtalsfakturering"
            />
            <p>Fakturerar automatiskt era prenumererande kunder.</p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Automatisk avtalsförnyelse"
            />
            <p>Förnyar automatiskt avtal som är påväg att löpa ut.</p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Schemalägg avtalsavslut och fakturautskick"
            />
            <p>
              Låter dig schemalägga fakturautskick, avtalsavslut och
              pris-/rabatt-/kvantitetsförändringar i avtal.
            </p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Lägg in återkommande avtalsförändringar"
            />
            <p>
              Behöver du öka/minska pris, rabatt eller kvantitet i avtal
              efter varje fakturering? Likvio fixar det för er. 
            </p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Masshantera fakturor, kunder, artiklar och avtal"
            />
            <p>
              Möjliggör masshantering av fakturor, kunder, avtal & produkter.
            </p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Detaljerad finansiell statistik"
            />
            <p>Ger tillgång till välöverskådliga och detaljerade grafer.</p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Notiser om förfallna, obetalda fakturor"
            />
            <p>
              Skickar er notiser när en faktura kvarstår som obetald ett förvalt
              antal dagar efter förfallodatum.
            </p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Fortnox Fakturaservice"
            />
            <p>
              Ger tillgång till en informativ sida dedikerad till fakturor som
              skickats via Fortnox Fakturaservice.
            </p>
          </div>
          <div class="check">
            <img
              src="./../../assets/Landing/img/check_icon.png"
              alt="Stödjer avtalsperiodiseringar"
            />
            <p>
              Likvio kan automatiskt uppdatera eller skapa avtalsperiodiseringar 
              vid skapande/uppdatering av avtal.
            </p>
          </div>
        </div>
      </div>

      <div id="about"></div>
      <div class="section_2">
        <h2>Jag är redo att komma igång! Hur går jag tillväga?</h2>
        <p>
          <b>Att komma igång är busenkelt!</b><br />Klicka på "Kom igång" uppe
          till höger för att komma direkt till Fortnox Marknadsplats där du
          aktiverar integrationen.<br />Kika därefter i din mejlinkorg efter ett
          välkomstmejl. Klicka på länken i mejlet för att verifiera din
          mejladress och sätta ditt lösenord.<br />Nu är det fritt fram att
          logga in i ditt nya Likvio konto för att konfigurera och börja använda
          appen.
        </p>
        <div class="box-main">
          <div class="box">
            <img
              src="./../../assets/Landing/img/undraw_3.png"
              alt="Kom igång med Likvio via Fortnox"
            /><br />
            <div class="number">Steg 1</div>
            <span
              >Klicka på "Kom igång" uppe till höger för att aktivera appen i
              Fortnox Marknadsplats.</span
            >
          </div>
          <div class="box">
            <img
              src="./../../assets/Landing/img/undraw_2.png"
              alt="Kom igång med Likvio via Fortnox"
            />
            <div class="number">Steg 2</div>
            <span
              >Verifiera din mejladress och sätt ditt lösenord genom att klicka
              på länken i mejlet du mottar efter att du aktiverat appen.</span
            >
          </div>
          <div class="box">
            <img
              src="./../../assets/Landing/img/undraw_1.png"
              alt="Kom igång med Likvio via Fortnox"
            /><br />
            <div class="number">Steg 3</div>
            <span
              >Logga in i ditt nya Likvio konto och gör konfigurationen. Luta
              dig sedan tillbaka och njut av automatiseringen som kommer med
              Likvio.</span
            >
          </div>
        </div>
      </div>

      <div class="section_3">
        <span>Tids- & kostnadseffektivt.</span>
        <p>
          Spara tid och pengar genom att använda Likvio för att hantera och
          automatisera delar av den återkommande faktureringen.<br />
          Låt Likvio skapa och skicka avtalsfakturor, förnya utgångna avtal samt
          skicka dig notiser om förfallna och obetalda fakturor.<br />
          Segmentera dina kunder och artiklar för en bättre överblick och
          hantering.<br />
          Håll koll på ditt bolags ekonomiska hälsa med Likvios statistik modul.
        </p>
      </div>
      <div class="section_4">
        <span>Premiumtjänster</span>
        <p>
          Likvio passar bolag i alla storlekar och branscher. Vi erbjuder paket
          i olika prisklasser för att ge er möjligheten<br />att använda precis
          vad ni behöver - varken mer eller mindre. För de små bolagen som inte
          behöver<br />konfigurera eller ha tillgång till en portal med mer
          överblick och funktionaliteter erbjuder vi ett gratispaket.<br />För
          de bolag som vill ha mer kontroll, insyn och funktionalitet erbjuder
          vi premiumtjänster som inkluderar<br />allt som ingår i gratispaketet
          plus lite till.<br /><br />
          <b>Vad ingår i premiumtjänsterna?</b>
        </p>
        <div class="box-pad-min box-main">
          <div class="img">
            <img
              src="./../../assets/Landing/img/imageonline-co-roundcorner.png"
              alt="Premiumtjänster"
            />
          </div>
          <div class="content">
            <div>
              <div class="check">
                <span>
                  <img
                    src="./../../assets/Landing/img/check_icon.png"
                    alt="Skräddarsydd konfiguration"
                  />
                </span>
                <p>Konfigurera applikationen utefter era unika behov.</p>
              </div>
              <div class="check">
                <span>
                  <img
                    src="./../../assets/Landing/img/check_icon.png"
                    alt="Hantera fakturor, kunder, artiklar och avtal"
                  />
                </span>
                <p>
                  Se, hantera, skapa och redigera kunder, artiklar, avtal och
                  fakturor.
                </p>
              </div>
              <div class="check">
                <span>
                  <img
                    src="./../../assets/Landing/img/check_icon.png"
                    alt="Schemalägg faktura- & avtalshändelser"
                  />
                </span>
                <p>
                  Schemalägg automatiska faktura- & avtalshändelser.
                </p>
              </div>
              <div class="check">
                <span>
                  <img
                    src="./../../assets/Landing/img/check_icon.png"
                    alt="Kundsegment och artikelsegment"
                  />
                </span>
                <p>
                  Segmentera kunder och artiklar och applicera inställningar per
                  segment.
                </p>
              </div>
              <div class="check">
                <span>
                  <img
                    src="./../../assets/Landing/img/check_icon.png"
                    alt="Detaljerad finansiell statistik"
                  />
                </span>
                <p>
                  Analysera företagets ekonomiska hälsa med lättförståelig och
                  informativ statistik.
                </p>
              </div>
            </div>
            <!-- <div class="price"><sub>Premiumtjänster fr.  </sub><strong>490:-</strong> <sub>/ mån</sub></div> -->
          </div>
        </div>
      </div>
      <br />

      <!--  -->
      <div class="another-section" id="price">
        <h2>Paketpriser</h2>
        <p>
          Ange hur många aktiva avtal ni har i Fortnox, samt hur ni önskar
          betala för att se er månadskostnad.<br />
          Priser anges exklusive moms och i genomsnittlig månadskostnad.
        </p>

        <div class="middle-package-dropdown">
          <div class="w-20 mar-1">
            <select
              id="active-contract"
              class="package-select"
              @change="activeContracts"
            >
              <option value="" selected disabled>
                Antal aktiva avtal i Fortnox
              </option>
              <option value="100">Upp till 100</option>
              <option value="150">Upp till 250</option>
              <option value="200">Upp till 500</option>
              <option value="250">Upp till 1000</option>
              <option value="300">Upp till 3000</option>
              <option value="350">Upp till 5000</option>
              <option value="450">Upp till 10000</option>
            </select>
          </div>
          <div class="w-20">
            <select
              id="payment-plan"
              class="package-select"
              @change="paymentPlan"
            >
              <option selected disabled>Välj betalningsperiod</option>
              <option value="">Årlig</option>
              <option value="80">Kvartalsvis</option>
            </select>
          </div>
        </div>

        <div class="price-card-main">
          <div>
            <div class="price-card">
              <div>
                <h5>GRATIS</h5>
                <h2>0</h2>
                <p>kr per månad</p>
              </div>
              <ul>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Automatisk avtalsfakturering"
                    srcset=""
                  /><span>Skapar avtalsfakturor på periodstartsdagen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Automatiskt fakturautskick"
                    srcset=""
                  /><span
                    >Skickar skapade avtalsfakturor med kunders förvalda
                    distributionssätt</span
                  >
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Skicka fakturor via Fortnox Fakturaservice"
                    srcset=""
                  /><span
                    >Skickar samtliga skapade avtalsfakturor via Fortnox
                    Fakturaservice</span
                  >
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/x-icon3.png"
                    alt="Support"
                    height="20"
                    width="20"
                    srcset=""
                  /><span>Mejl- & telefonsupport ingår ej</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/x-icon3.png"
                    alt="Likvio Portal"
                    height="20"
                    width="20"
                    srcset=""
                  /><span>Begränsad tillgång till Likvio portalen</span>
                </li>
                <br /><br /><br />
              </ul>
              <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio">Aktivera</a>
            </div>
          </div>
          <div>
            <div class="price-card">
              <div>
                <h5>Small</h5>
                <h2 class="price-value">250</h2>
                <p>kr per månad</p>
              </div>
              <ul>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Skräddarsydd konfiguration"
                    srcset=""
                  /><span>Konfigurera appen efter era behov</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Support"
                    srcset=""
                  /><span>Gratis mejlsupport</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Avtalsfakturering"
                    srcset=""
                  /><span>Tillgång till faktura- & avtalsmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Användare"
                    srcset=""
                  /><span>Upp till 1 användare</span>
                </li>
                <br /><br /><br />
              </ul>
              <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio">Aktivera</a>
            </div>
          </div>
          <div>
            <div class="price-card">
              <div>
                <h5>Standard</h5>
                <h2 class="price-value">350</h2>
                <p>kr per månad</p>
              </div>
              <ul>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Skräddarsydd konfiguration"
                    srcset=""
                  /><span>Konfigurera appen efter era behov</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Support"
                    srcset=""
                  /><span>Gratis mejlsupport</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Avtalsfakturering"
                    srcset=""
                  /><span>Tillgång till faktura- & avtalsmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Kunder och artiklar"
                    srcset=""
                  /><span>Tillgång till kund- & artikelmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Användare"
                    srcset=""
                  /><span>Upp till 1 användare</span>
                </li>
                <br />
              </ul>
              <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio">Aktivera</a>
            </div>
          </div>
          <div>
            <div class="price-card">
              <div class="popular">Populär</div>
              <div>
                <h5>Plus</h5>
                <h2 class="price-value">450</h2>
                <p>kr per månad</p>
              </div>
              <ul>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Skräddarsydd konfiguration"
                    srcset=""
                  /><span>Konfigurera appen efter era behov</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Support"
                    srcset=""
                  /><span>Gratis mejlsupport</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Avtalsfakturering"
                    srcset=""
                  /><span>Tillgång till faktura- & avtalsmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Kunder och artiklar"
                    srcset=""
                  /><span>Tillgång till kund- & artikelmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Detaljerad finansiell statistik"
                    srcset=""
                  /><span>Tillgång till statistikmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Användare"
                    srcset=""
                  /><span>Upp till 3 användare</span>
                </li>
              </ul>
              <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio" class="active">Aktivera</a>
            </div>
          </div>
          <div>
            <div class="price-card">
              <div>
                <h5>Enterprise</h5>
                <h2 class="price-value">550</h2>
                <p>kr per månad</p>
              </div>
              <ul>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Skräddarsydd konfiguration"
                    srcset=""
                  /><span>Konfigurera appen efter era behov</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Support"
                    srcset=""
                  /><span>Gratis mejlsupport</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Avtalsfakturering"
                    srcset=""
                  /><span>Tillgång till faktura- & avtalsmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Kunder och artiklar"
                    srcset=""
                  /><span>Tillgång till kund- & artikelmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Detaljerad finansiell statistik"
                    srcset=""
                  /><span>Tillgång till statistikmodulen</span>
                </li>
                <li>
                  <img
                    src="./../../assets/Landing/img/check_no_bg.png"
                    alt="Användare"
                    srcset=""
                  /><span>Upp till 6 användare</span>
                </li>
              </ul>
              <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio">Aktivera</a>
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="section_5">
        <div class="box-main">
          <div class="content">
            <h2>Om Fortnox</h2>
            <br />
            <span>Småföretgarnas bästa vän.</span>
            <p>
              Fortnox är ett av Sveriges mest använda ekonomiprogram med många
              funktioner som underlättar redovsining för små och stora bolag.
              Som integrationspartner och Fortnox proffs har grundarna av Likvio
              sedan 2019 levererat över 40 andra kopplingar som hjälper
              företagare och byråer med effektivisering och automtisering.
            </p>
            <hr />
            <img
              src="./../../assets/Landing/img/fortnox-integrationspartner2.png"
              alt="Fortnox integrationspartner"
              width="500"
              height="200"
            />
          </div>
          <div class="checks-and-btn">
            <div class="check-main">
              <div class="check">
                <img
                  src="./../../assets/Landing/img/check_no_bg.png"
                  alt="Molnbaserat"
                />
                <p>Helt molnbaserat</p>
              </div>
              <div class="check">
                <img
                  src="./../../assets/Landing/img/check_no_bg.png"
                  alt="Automatiserat"
                />
                <p>Öppet API för skräddarsydda flöden</p>
              </div>
              <div class="check">
                <img
                  src="./../../assets/Landing/img/check_no_bg.png"
                  alt="Integrationer"
                />
                <p>Mer än 300 integrationer</p>
              </div>
              <div class="check">
                <img
                  src="./../../assets/Landing/img/check_no_bg.png"
                  alt="Redovisningsbyrå"
                />
                <p>Populär bland byråer</p>
              </div>
              <a href="https://www.fortnox.se/paket/">Utforska Fortnox</a>
            </div>
          </div>
        </div>
      </div>

      <div id="faq"></div>
      <div class="section_6">
        <h2>Vanliga frågor</h2>
        <div class="accordian-main">
          <div class="accordion-1">
            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Tillkommer det några uppstartskostnader?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Nej, det utgår inga obligatoriska uppstartskostnader. Ni har
                  däremot möjlighet att köpa till Onboarding Care för att få
                  hjälp med att sätta upp och konfigurera appen utefter era
                  specifika behov. Detta är helt valfritt.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">Hur lång är bindningstiden?</h2>
              <div class="accordionItemContent">
                <p>
                  Ni väljer själva om ni vill ha en betalperiod på 3 eller 12
                  månader. Ni kan säga upp appen när som helst och så länge det
                  görs senast 30 dagar innan nuvarande betalperiod utgår
                  avslutas prenumerationen i samband med att betalperioden
                  utgår. Det utgår ingen ångerrätt efter att appen är beställd.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Hur lång är uppsägningstiden?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Ni har möjlighet att säga upp er prenumeration när som helst.
                  Så länge detta görs minst 30 dagar innan nästa betalperiod
                  börjar avslutas er prenumeration innan starten av nästa
                  betalperiod.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">Hur kommer man igång?</h2>
              <div class="accordionItemContent">
                <p>
                  Börja med att aktivera appen i Fortnox Marknadsplats genom att
                  klicka på "Kom igång" uppe till höger. Följ sedan
                  instruktionerna i välkomstmejlet för att sätta ditt lösenord.
                  Därefter är det fritt fram att logga in i ditt Likvio konto
                  för att konfigurera och börja använda appen.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Kan man testa på ett premiumpaket?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Ja, alla nya kunder får 7 dagars kostnadsfri prövotid. Ni kan
                  när som helst under dessa 7 dagar avsluta tjänsten utan någon
                  kostnad.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-2">
            <div class="accordionItem close">
              <h2 class="accordionItemHeading">Vad ingår i gratispaketet?</h2>
              <div class="accordionItemContent">
                <p>
                  Med gratispaketet automatiseras skapandet och utskicken av era
                  avtalsfakturor. Fakturorna skapas på periodstartsdagen och
                  skickas via Fortnox Fakturaservice med kundens förvalda
                  distributionssätt för fakturor. Det finns ingen möjlighet att
                  konfigurera appen i gratispaketet och tillgången till Likvios
                  portal är begränsad.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">Vad är månadskostnaden?</h2>
              <div class="accordionItemContent">
                <p>
                  Månadskostanden beror på vilket paket ni väljer, hur många
                  aktiva avtal ni har i Fortnox samt om ni väljer en betalperiod
                  på 3 eller 12 månader. Klicka på Priser uppe till höger för
                  att se slutpris för ert bolag.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Vad händer om vårt antal aktiva avtal stiger?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Om totalt antal aktiva avtal överstiger er nuvarande nivå
                  kommer en uppgradering automatiskt att ske för att täcka er
                  nya avtalsvolym och kostnaden stiger därefter.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Är det möjligt att byta paket efter start?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Absolut! I Likvio portalen har ni möjlighet att enkelt upp-
                  eller nedgradera ert paket om så skulle önskas. Notera att det
                  inte utgår någon återbetalning på tidigare betalningar om ni
                  skulle vilja nedgradera till ett billigare paket, alternativt
                  avsluta er prenumeration.
                </p>
              </div>
            </div>

            <div class="accordionItem close">
              <h2 class="accordionItemHeading">
                Vår ekonom skulle gärna vilja ha tillgång, går det?
              </h2>
              <div class="accordionItemContent">
                <p>
                  Självklart! Välj ett av paketen som inkluderar mer än 1
                  användare och bjud in ekonomen som en del av ert team. På så
                  vis har ekonomen tillgång via ett separat konto.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="section_7">
      <h1>Sagt om MaxFaktura</h1>
      <p>Detta säger kunderna om tjänsten</p>
      <div class="swiper-container mySwiper">
          <div class="swiper-wrapper">
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          <div class="swiper-slide">
              <span>Viezh Robert</span><br>
              <small>Warsaw, Poland</small>
              <p>“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there
              have been no problems. LaslesVPN always the best”.</p>
          </div>
          </div>
          <div class="swiper-button-next">
          <i class="fas fa-long-arrow-alt-right"></i>
          </div>
          <div class="swiper-button-prev">
          <i class="fas fa-long-arrow-alt-left"></i>
          </div>
      </div>

      </div> -->
      <div id="contact"></div>

      <div class="another-section contact-us-section">
        <h2>Kontakt</h2>
        <!-- <p>Inga uppsagningsavgifter | inga bindningstider | Gratis meji-support</p> -->

        <a href="mailto:info@likvio.se" class="email-link">
          <img
            src="./../../assets/Landing/img/email.png"
            alt="Kontakt"
            srcset=""
          />
          Mejla oss</a
        >
      </div>
    </main>
    <FooterComponent />
    <a href="#" class="to-top-btn"><i class="fas fa-sort-up"></i></a>
  </div>
</template>

<script>
import HeaderComponent from "./components/Header";
import FooterComponent from "./components/Footer";

export default {
  name: "Landing",
  components: { HeaderComponent, FooterComponent },
  data() {
    return {
      DEFAULT: [250, 350, 450, 550],
    };
  },
  mounted() {
    this.runAccordion();
    this.addFontAwesomeToHead();
  },
  methods: {
    runAccordion() {
      const accHD = document.getElementsByClassName("accordionItemHeading");
      for (let i = 0; i < accHD.length; i++) {
        accHD[i].addEventListener("click", this.toggleItem, false);
      }
    },
    toggleItem(e) {
      const accItem = document.getElementsByClassName("accordionItem");
      const itemClass = e.target.parentNode.className;
      for (let i = 0; i < accItem.length; i++) {
        accItem[i].className = "accordionItem close";
      }
      if (itemClass == "accordionItem close") {
        e.target.parentNode.className = "accordionItem open";
      }
    },
    paymentPlan(e) {
      if (parseInt(e.target.value)) {
        const slides = document.querySelectorAll(".price-value");
        for (let i = 0; i < slides.length; i++) {
          const activeContract =
            document.getElementById("active-contract").value;
          if (parseInt(activeContract)) {
            slides[i].innerText =
              parseInt(e.target.value) +
              parseInt(this.DEFAULT[i]) +
              parseInt(activeContract);
          } else {
            slides[i].innerText =
              parseInt(e.target.value) + parseInt(this.DEFAULT[i]);
          }
        }
      } else {
        const slides = document.querySelectorAll(".price-value");
        for (let i = 0; i < slides.length; i++) {
          const activeContract =
            document.getElementById("active-contract").value;
          if (parseInt(activeContract)) {
            slides[i].innerText =
              parseInt(this.DEFAULT[i]) + parseInt(activeContract);
          } else {
            slides[i].innerText = parseInt(this.DEFAULT[i]);
          }
        }
      }
    },
    activeContracts(e) {
      if (parseInt(e.target.value)) {
        const slides = document.querySelectorAll(".price-value");
        for (let i = 0; i < slides.length; i++) {
          const quartelyValue = document.getElementById("payment-plan").value;
          if (parseInt(quartelyValue)) {
            slides[i].innerText =
              parseInt(e.target.value) +
              parseInt(this.DEFAULT[i]) +
              parseInt(quartelyValue);
          } else {
            slides[i].innerText =
              parseInt(e.target.value) + parseInt(this.DEFAULT[i]);
          }
        }
      }
    },
    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/landing-style.css";

a {
  text-decoration: none !important;
}
</style>
