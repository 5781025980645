<template>
    <div class="modal-custom">
        <div class="modal-custom__backdrop" @click="$emit('handleClose')"/> <!--$emit('handleClose')-->
        <div class="users-edit-popup users-invite-popup">
            <div class="popup-box" :class="width ? 'expand-width' : customize">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            width : {
                type : Boolean,
                require : false
            },
            customize : {
                type : String,
                require : false
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal-custom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    background: #000;
    backdrop-filter: blur(3px);
    z-index: 1;
  }
}
.users-edit-popup {
//   width: 100%;
//   height: 100%;
  position: relative;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -moz-box;
//   display: -ms-flexbox;
  display: flex;
//   -webkit-box-align: center;
//   -webkit-align-items: center;
//      -moz-box-align: center;
//       -ms-flex-align: center;
//           align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//      -moz-box-pack: center;
//       -ms-flex-pack: center;
          justify-content: center;
//   background: rgba(10, 4, 28, 0.36);
//   overflow: auto;
//   top: 0;
//   left: 0;
//   z-index: 999999;
}
.users-edit-popup .expand-width{
    width: 500px !important;
}
.users-edit-popup .customize{
    /*width: unset !important;*/
}
.users-edit-popup .popup-box {
  min-width: 300px;
  max-width: 90%;
  width: 450px;
  position: relative;
  z-index: 9;
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.users-edit-popup .popup-box.large-popup {
  min-width: 980px !important;
}
.users-edit-popup .popup-box h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0e1566;
  padding: 25px 40px 20px;
  border-bottom: 1px solid #cbd4e1;
  margin: 0;
  text-align: center;
}
.users-edit-popup .popup-box .popup-body {
  padding: 30px 40px 40px;
  margin: 0;
}
.users-edit-popup .popup-box .popup-body label {
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}
.users-edit-popup .popup-box .popup-body label span {
  color: #ff3333;
  font-weight: bold;
}
.users-edit-popup .popup-box .popup-body input,
.users-edit-popup .popup-box .popup-body select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 37.83px;
  color: #0f1a2a;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  margin: 10px 0 20px;
  padding: 0 10px;
}
.users-edit-popup .popup-box .popup-body input:focus,
.users-edit-popup .popup-box .popup-body select:focus {
  outline: none;
  border: none;
}
.users-edit-popup .popup-box .popup-bottons {
  text-align: right;
  margin-top: 20px;
}
.users-edit-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}
.users-edit-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.users-edit-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}
.users-edit-popup .popup-box .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.users-edit-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}
.users-invite-popup .popup-box .popup-footer {
  position: relative;
}
.users-invite-popup .popup-box .popup-footer img {
  position: absolute;
  left: -30px;
  bottom: 0;
  max-width: 100%;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons {
  text-align: right;
  margin-top: 20px;
  width: -webkit-calc(100% - 92px);
  width: calc(100% - 92px);
  margin-left: auto;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>