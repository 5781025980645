<template>
  <div class="header">
    <div class="container-fluid">
      <router-link :to="{ name : 'login' }"><img class="ps-md-3" src="./../../assets/images/logo/logo-dark.png">
      </router-link>
    </div>
  </div>
  <div class="row g-0 overflow-hidden align-items-md-center justify-content-center ps-lg-5 content">
    <div class="col-lg-4 col-xl-3 col-12">
      <div class="desc">
        <h2>Hoppsan!</h2>
        <p>You currently do not have access to this page,Kindly upgrade to higher package...</p>
        <router-link :to="{ name : 'dashboard' }">Till inloggningssidan</router-link>
      </div>
    </div>
    <div class="col-lg-8 col-xl-9 col-12"><img class="ms-auto d-block" src="./../../assets/images/error/not-available.png"></div>
  </div>
</template>

<script>
export default {
  name: "AccessPage"
}
</script>

<style scoped>

</style>