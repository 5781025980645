<template>
  <footer>
    <div class="footer-overlay">
      <h1>För att det ska vara <br> busenkelt att fakturera.</h1>
      <a href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio" class="main-btn">Skapa ditt Likvio konto</a>
    </div>
    <div class="footer">
      <div class="content">
        <div class="logo">
          <img src="./../../../assets/Landing/img/likvio_logo5.png" alt="">
        </div>
        <p>Likvio är en tjänst som hjälper bolag att fakturera sina kunder och underlätta faktureringshanteringen.<br>
          Tjänsten tillhandahålls av grundarna av <a href="https://automatiseramera.se/">Automatisera Mera</a>.</p>
        <h6>Connectivo AB</h6>
        <h6>Mässans gatan 10, 412 51 Göteborg</h6>
        <span class="copyright">©{{ new Date().getFullYear() }} Likvio</span>
      </div>
      <div class="links">
        <a href="#" class="active">Info</a>
        <a href="#about">Om Likvio</a>
        <a :href="'LIKVIO_TERMS_OF_PURCHASE.pdf'" target="_blank" download>Likvio köpvillkor</a>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
</style>