const LOCALE = '__LOCAL_TRANS__'

export const saveLocale = (payload) => {
    window.localStorage.setItem(LOCALE, payload)
}
export const getLocale = () => {
    return window.localStorage.getItem(LOCALE)
}

export default { getLocale, saveLocale }
