import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

const auth = {
    namespaced : true,
    actions,
    mutations,
    getters,
    state
}

export default auth
