<template>
  <NavbarComponent/>
  <div class="row g-0">
    <SidebarComponent/>
    <router-view></router-view>
  </div>
  <NotificationModalVue/>
  <ModalBoxVue
      v-if="contractTagSetup.openTagAddition"
      @handleClose="contractTagSetup.openTagAddition = false"
      :close="!true"
  >
    <h4>Tags</h4>
    <div class="container p-3">
      <!-- <small>Select table heads (Multiple)</small> -->
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(item, key) in GET_TAGS" :key="key">
          <span
              class="position-absolute tags-box cursor-pointer"
              @click="removeTag('contract', item.name)"
              v-if="contractTagSetup.tags.includes(item.name)"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style="color: red"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </span>
          <div class="border rounded p-2 bg-gray">
            <span class="d-flex align-items-center">
              <input
                  class="form-check-input"
                  :value="item.name"
                  type="checkbox"
                  v-on:change="onTagChecked('contract', item.name)"
                  v-if="contractTagSetup.tags"
                  :checked="contractTagSetup.tags.includes(item.name)"
              />
              <small class="ml-2 tag-label">{{ item.name }}</small>
            </span>
          </div>
        </div>
      </div>
      <form class="row" @submit.prevent="updateTag">
        <div class="col-md-8">
          <input
              class="form-control"
              v-model="contractTagSetup.tag"
              v-on:focusout="addToTag('contract')"
              type="text"
              :placeholder="$t('add_tags')"
          />
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button
              type="submit"
              :disabled="updating"
              class="btn btn-primary w-100"
          >
            <span v-if="updating">{{ $t("updating") }}...</span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
      v-if="customerTagSetup.openTagAddition"
      @handleClose="customerTagSetup.openTagAddition = false"
      :close="!true"
  >
    <h4>Tags</h4>
    <div class="container p-3">
      <!-- <small>Select table heads (Multiple)</small> -->
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(item, key) in GET_TAGS" :key="key">
          <span
              class="position-absolute tags-box cursor-pointer"
              @click="removeTag('customer', item.name)"
              v-if="customerTagSetup.tags.includes(item.name)"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style="color: red"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </span>
          <div class="border rounded p-2 bg-gray">
            <span class="d-flex align-items-center">
              <input
                  class="form-check-input"
                  :value="item.name"
                  type="checkbox"
                  v-on:change="onTagChecked('customer', item.name)"
                  :checked="customerTagSetup.tags.includes(item.name)"
              />
              <small class="ml-2 tag-label">{{ item.name }}</small>
            </span>
          </div>
        </div>
      </div>
      <form class="row" @submit.prevent="updateCustomerTag">
        <div class="col-md-8">
          <input
              class="form-control"
              v-model="customerTagSetup.tag"
              v-on:focusout="addToTag('customer')"
              type="text"
              :placeholder="$t('add_tags')"
          />
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button
              type="submit"
              :disabled="updating"
              class="btn btn-primary w-100"
          >
            <span v-if="updating">{{ $t("updating") }}...</span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
      v-if="articleTagSetup.openTagAddition"
      @handleClose="articleTagSetup.openTagAddition = false"
      :close="!true"
  >
    <h4>Tags</h4>
    <div class="container p-3">
      <!-- <small>Select table heads (Multiple)</small> -->
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(item, key) in GET_TAGS" :key="key">
          <span
              class="position-absolute tags-box cursor-pointer"
              @click="removeTag('article', item.name)"
              v-if="articleTagSetup.tags.includes(item.name)"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style="color: red"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </span>
          <div class="border rounded p-2 bg-gray">
            <span class="d-flex align-items-center">
              <input
                  class="form-check-input"
                  :value="item.name"
                  type="checkbox"
                  v-on:change="onTagChecked('article', item.name)"
                  :checked="articleTagSetup.tags.includes(item.name)"
              />
              <small class="ml-2 tag-label">{{ item.name }}</small>
            </span>
          </div>
        </div>
      </div>
      <form class="row" @submit.prevent="updateArticleTag">
        <div class="col-md-8">
          <input
              class="form-control"
              v-model="articleTagSetup.tag"
              v-on:focusout="addToTag('article')"
              type="text"
              :placeholder="$t('add_tags')"
          />
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button
              type="submit"
              :disabled="updating"
              class="btn btn-primary w-100"
          >
            <span v-if="updating">{{ $t("updating") }}...</span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalAlertVue
      v-if="openAccessDenied"
      @handleClose="openAccessDenied = false"
      :close="!true"
  >
    <div class="container p-3">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <img
              class="ms-auto d-block"
              src="./../../assets/images/error/access-denied.png"
              style="height: 196px !important"
          />
        </div>
        <div class="col-md-12">
          <p>{{ $t("no_permission") }}</p>
          <button
              type="button"
              v-on:click="takeMeBack"
              style="
              background: #0d6efd;
              color: #fff;
              text-decoration: none;
              padding: 10px;
              border-radius: 0px;
              margin: auto;
              display: table;
              border: 1px solid transparent;
            "
          >
            {{ $t("take_me_back") }}
          </button>
        </div>
      </div>
    </div>
  </ModalAlertVue>
  <!-- remove the v-if logic below in production -->
  <ModalAlertVue v-if="notFound" @handleClose="notFound = false" :close="!true">
    <div class="container p-3">
      <div class="row">
        <div class="col-md-12">
          <img
              class="ms-auto d-block"
              src="./../../assets/images/error/not-available.png"
              style="height: 196px !important"
          />
        </div>
        <div class="col-md-12">
          <p>
            {{ $t("package_restriction_text") }}
          </p>
          <div class="col-md-12 row mx-auto">
            <button
                type="button"
                v-on:click="takeMeBack"
                class="col-5"
                style="
                background: transparent;
                color: #0d6efd;
                text-decoration: none;
                padding: 10px;
                border-radius: 0px;
                margin: auto;
                display: table;
                border: 1px solid #0d6efd;
              "
            >
              {{ $t("go_back") }}
            </button>
            <button
                class="col-5"
                type="button"
                v-on:click="takeMeToPlanBack"
                style="
                background: #0d6efd;
                color: #fff;
                text-decoration: none;
                padding: 10px;
                border-radius: 0px;
                margin: auto;
                display: table;
                border: 1px solid transparent;
              "
            >
              {{ $t("upgrade_plan") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ModalAlertVue>
</template>

<script>
import NavbarComponent from "./components/Navbar";
import SidebarComponent from "./components/Sidebar";
import NotificationModalVue from "../../components/NotificationModal.vue";
import ModalBoxVue from "../../components/ModalBox.vue";
import ModalAlertVue from "../../components/ModalAlert.vue";
import {mapGetters} from "vuex";

export default {
  name: "RootMain",
  components: {
    NavbarComponent,
    SidebarComponent,
    NotificationModalVue,
    ModalBoxVue,
    ModalAlertVue,
  },
  data() {
    return {
      contractTagSetup: {
        openTagAddition: false,
        data: {},
        tags: [],
        tag: "",
        showAssignBtn: false,
        tagsArray: [],
      },
      customerTagSetup: {
        openTagAddition: false,
        data: {},
        tags: [],
        tag: "",
        showAssignBtn: false,
        tagsArray: [],
      },
      articleTagSetup: {
        openTagAddition: false,
        data: {},
        tags: [],
        tag: "",
        showAssignBtn: false,
        tagsArray: [],
      },
      updating: false,
      contractTags: [],
      customerTags: [],
      articleTags: [],
      openAccessDenied: false,
      notFound: false,
    };
  },
  mounted() {
    this.getUserSubscription();

    window.Bus.on("logout", (e) => {
      if (this.$route.path !== "/login") {
        this.$router.replace({name: "login"});
      }
    });
    window.Bus.on("open-single-tag-contract", (e) => {
      this.contractTagSetup.openTagAddition = true;
      this.contractTagSetup.data = e;
      if (e.tags !== null) {
        this.contractTagSetup.tags = e.tags;
      } else {
        this.contractTagSetup.tags = [];
      }
      this.getContractTags();
    });
    window.Bus.on("open-single-tag-customer", (e) => {
      this.customerTagSetup.openTagAddition = true;
      this.customerTagSetup.data = e;
      this.customerTagSetup.tags = e.tags;
      this.getCustomerTags();
    });
    window.Bus.on("open-single-tag-article", (e) => {
      this.articleTagSetup.openTagAddition = true;
      this.articleTagSetup.data = e;
      this.articleTagSetup.tags = e.tags;
      this.getArticleTags();
    });
    window.Bus.on("access-denied", (e) => {
      if (["billings", "user", "video-guides"].includes(e)) {
        this.openAccessDenied = true;
      } else {
        this.notFound = true;
      }
    });
  },
  methods: {
    takeMeBack: function () {
      this.openAccessDenied = false;
      this.notFound = false;
      this.$router.replace({name: "dashboard"});
    },
    takeMeToPlanBack: function () {
      this.notFound = false;
      this.$router.replace({name: "planDetails"});
    },
    getUserSubscription() {
      this.$store
          .dispatch("clients/getUserSubscription")
          .then((res) => {
            this.getPackageInfo();
          })
          .catch((err) => {
            // left blank
          });
    },
    fetchPage() {
      if (
          ![
            "index-page",
            "login",
            "forget",
            "reset",
            "finalize",
            "not-found",
          ].includes(this.$route.name)
      ) {
        if (this.USER_TOKEN) {
          this.getPackageInfo();
        }
      }
    },
    async getPackageInfo() {
      const payload = {
        URL:
            this.$services.endpoints.PACKAGE_ENDPOINT +
            `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
      };
      if (!this.GET_PACKAGE.dashboard_page_access) {
        await this.$store.dispatch("clients/getPlanDetails", payload);
      }
      if (
          !this.GET_PACKAGE.dashboard_page_access.includes(this.$route.name) &&
          this.$route.name !== "planDetails" &&
          this.$route.name !== "faq" //&&
          //this.$route.name !== "support"
      ) {
        this.notFound = true;
        return;
      }
    },
    updateTag: function () {
      this.updating = true;
      this.$store
          .dispatch("clients/updateContractTag", {
            document_number: this.contractTagSetup.data.document_number,
            tags: this.contractTagSetup.tags,
          })
          .then((resp) => {
            this.updating = false;
            this.$toast.success(resp.data.message);
            let index = this.GET_CONTRACT.data.findIndex(
                (item) =>
                    item.document_number ===
                    this.contractTagSetup.data.document_number
            );
            if (index !== -1) {
              this.contractTagSetup.data.tags = this.contractTagSetup.tags;
              this.GET_CONTRACT.data[index] = this.contractTagSetup.data;
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$toast.warning(error.response.data.message);
          });
    },
    updateCustomerTag: function () {
      this.updating = true;
      this.$store
          .dispatch("clients/updateCustomerTag", {
            customer_number: this.customerTagSetup.data.customer_number,
            tags: this.customerTagSetup.tags,
          })
          .then((resp) => {
            this.updating = false;
            this.$toast.success(resp.data.message);
            let index = this.GET_CUSTOMERS.data.findIndex(
                (item) =>
                    item.customer_number ===
                    this.customerTagSetup.data.customer_number
            );
            if (index !== -1) {
              this.customerTagSetup.data.tags = this.customerTagSetup.tags;
              this.GET_CUSTOMERS.data[index] = this.customerTagSetup.data;
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$toast.warning(error.response.data.message);
          });
    },
    updateArticleTag: function () {
      this.updating = true;
      this.$store
          .dispatch("clients/updateArticleTag", {
            article_number: this.articleTagSetup.data.article_number,
            tags: this.articleTagSetup.tags,
          })
          .then((resp) => {
            this.updating = false;
            this.$toast.success(resp.data.message);
            let index = this.GET_ARTICLES.data.findIndex(
                (item) =>
                    item.article_number === this.articleTagSetup.data.article_number
            );

            if (index !== -1) {
              this.articleTagSetup.data.tags = this.articleTagSetup.tags;
              this.GET_ARTICLES.data[index] = this.articleTagSetup.data;
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$toast.warning(error.response.data.message);
          });
    },
    onTagChecked: function (type, name) {
      if (type === "contract") {
        if (!this.contractTagSetup.tags.includes(name)) {
          this.contractTagSetup.tags.push(name);
        } else {
          this.contractTagSetup.tags.pop(name);
        }
      } else if (type === "customer") {
        if (!this.customerTagSetup.tags.includes(name)) {
          this.customerTagSetup.tags.push(name);
        } else {
          this.customerTagSetup.tags.pop(name);
        }
      } else if (type === "article") {
        if (!this.articleTagSetup.tags.includes(name)) {
          this.articleTagSetup.tags.push(name);
        } else {
          this.articleTagSetup.tags.pop(name);
        }
      }
    },
    addToTag: function (type) {
      if (type === "contract") {
        if (this.contractTagSetup.tag !== "") {
          let tag = this.contractTagSetup.tag;
          if (
              !this.contractTagSetup.tagsArray.includes(this.contractTagSetup.tag)
          ) {
            this.$store
                .dispatch("clients/addTags", {name: tag, type: "CONTRACT"})
                .then((resp) => {
                  this.contractTagSetup.tags.push(tag);
                  this.contractTags.push(resp.data.data);
                  this.contractTags.map((item) => {
                    if (!this.contractTagSetup.tagsArray.includes(item.name)) {
                      this.contractTagSetup.tagsArray.push(item.name);
                    }
                  });
                });
          } else {
            this.contractTagSetup.tags.push(tag);
          }
          this.contractTagSetup.tag = "";
        }
      } else if (type === "customer") {
        if (this.customerTagSetup.tag !== "") {
          let tag = this.customerTagSetup.tag;
          if (
              !this.customerTagSetup.tagsArray.includes(this.customerTagSetup.tag)
          ) {
            this.$store
                .dispatch("clients/addTags", {name: tag, type: "CUSTOMER"})
                .then((resp) => {
                  this.customerTagSetup.tags.push(tag);
                  this.customerTags.push(resp.data.data);
                  this.customerTags.map((item) => {
                    if (!this.customerTagSetup.tagsArray.includes(item.name)) {
                      this.customerTagSetup.tagsArray.push(item.name);
                    }
                  });
                  this.updateCustomerTag();
                });
          } else {
            this.customerTagSetup.tags.push(tag);
          }
          this.customerTagSetup.tag = "";
        }
      } else if (type === "article") {
        if (this.articleTagSetup.tag !== "") {
          let tag = this.articleTagSetup.tag;
          if (
              !this.articleTagSetup.tagsArray.includes(this.articleTagSetup.tag)
          ) {
            this.$store
                .dispatch("clients/addTags", {name: tag, type: "ARTICLE"})
                .then((resp) => {
                  this.articleTagSetup.tags.push(tag);
                  this.articleTags.push(resp.data.data);
                  this.articleTags.map((item) => {
                    if (!this.articleTagSetup.tagsArray.includes(item.name)) {
                      this.articleTagSetup.tagsArray.push(item.name);
                    }
                  });
                });
          } else {
            this.articleTagSetup.tags.push(tag);
          }
          this.articleTagSetup.tag = "";
        }
      }
    },
    removeTag: function (type, name) {
      if (type === "contract") {
        let index = this.contractTagSetup.tags.findIndex(
            (item) => item === name
        );
        if (index !== -1) {
          this.contractTagSetup.tags.splice(index, 1);
        }
      } else if (type === "article") {
        let index = this.articleTagSetup.tags.findIndex(
            (item) => item === name
        );
        if (index !== -1) {
          this.articleTagSetup.tags.splice(index, 1);
        }
      } else {
        let index = this.customerTagSetup.tags.findIndex(
            (item) => item === name
        );
        if (index !== -1) {
          this.customerTagSetup.tags.splice(index, 1);
        }
      }
    },
    getContractTags: function () {
      this.$store
          .dispatch("clients/getTags", "CONTRACT")
          .then((res) => {
            this.contractTagSetup.tagsArray = [];
            this.contractTags = res.data.results;
            res.data.results.map((item) => {
              this.contractTagSetup.tagsArray.push(item.name);
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.commit("auth/CLEAR_AUTH_USER", null);
              window.Bus.emit("logout");
            }
          });
    },
    getCustomerTags: function () {
      this.$store
          .dispatch("clients/getTags", "CUSTOMER")
          .then((res) => {
            this.customerTagSetup.tagsArray = [];
            this.customerTags = res.data.results;
            res.data.results.map((item) => {
              this.customerTagSetup.tagsArray.push(item.name);
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.commit("auth/CLEAR_AUTH_USER", null);
              window.Bus.emit("logout");
            }
          });
    },
    getArticleTags: function () {
      this.$store
          .dispatch("clients/getTags", "ARTICLE")
          .then((res) => {
            this.articleTagSetup.tagsArray = [];
            this.articleTags = res.data.results;
            res.data.results.map((item) => {
              this.articleTagSetup.tagsArray.push(item.name);
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.commit("auth/CLEAR_AUTH_USER", null);
              window.Bus.emit("logout");
            }
          });
    },
  },
  watch: {
    "$route.path"() {
      this.getPackageInfo();
    },
  },

  computed: {
    ...mapGetters({
      GET_CONTRACT: "clients/GET_CONTRACTS",
      GET_CUSTOMERS: "clients/GET_CUSTOMERS",
      GET_ARTICLES: "clients/GET_ARTICLES",
      GET_TAGS: "clients/GET_TAGS",
      USER_TOKEN: "auth/USER_TOKEN",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
      GET_PACKAGE: "clients/GET_PLAN_DETAIL",
    }),
  },
};
</script>

<style lang="scss">
body {
  background-color: #f6f8fc;
}

body > .header ~ .row {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media (min-width: 1200px) {
  .responsive-width {
    overflow: hidden;
  }
}

@media (max-width: 1199.98px) {
  .responsive-width {
    width: -webkit-calc(100% - 64px);
    width: calc(100% - 64px);
    padding-left: 64px;
  }
}

.box-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.header > .move-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .line {
  margin-right: 5%;
}

.header .nofication {
  margin: 0 10px;
}

.header .profile {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header .profile img {
  max-width: 100%;
  width: 40.06px;
  height: 40px;
  margin: 0 10px;
}

.header .profile .desc {
  color: #0a043c;
  margin-right: 20px;
}

.header .profile .desc h6 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.header .profile .desc span {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  display: block;
}

.header .bubble {
  position: relative;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: -5px;
}

.header .bubble nav {
  padding: 10px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: -101px;
  width: 140px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2),
  0px 1px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2),
  0px 1px 1px rgba(9, 30, 66, 0.31);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 33;
  min-height: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

.header .bubble nav.active {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.header .bubble nav a {
  position: relative;
  text-decoration: none;
  color: #0a043c;
}

.header .bubble nav a:hover:before,
.header .bubble nav a:hover:after {
  width: 100%;
}

.header .bubble nav a span {
  position: relative;
  z-index: 3;
  padding: 10px 10px;
  padding: 5px 10px;
  display: block;
  font-size: 12px;
  opacity: 0.7;
}

.header .bubble nav a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0;
  background-color: #e3eefc;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.header .bubble nav a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #f8fbff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.4;
}

.header .bubble nav a:not(:last-of-type) {
  margin-bottom: 10px;
}

.sidebar {
  padding-top: 26px;
  background: #333269;
  position: relative;
  z-index: 3333;
  padding-right: 0;
  margin-left: 0;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  position: relative;
  z-index: 333;
}

@media (max-width: 1199.98px) {
  .sidebar {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  .sidebar:not(.close) {
    overflow: hidden auto;
  }
}

.sidebar .arrow-left {
  text-align: right;
}

.sidebar .arrow-left svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 0;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar .logo {
  text-align: center;
  max-width: 100%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  padding: 0 20px;
}

.sidebar .logo img {
  max-width: 100%;
}

.sidebar nav {
  margin-top: 0px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

@media (max-width: 1199.98px) {
  .sidebar nav {
    margin-top: 5px;
  }
}

.sidebar nav ul {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

@media (max-width: 1199.98px) {
  .sidebar nav ul {
    margin: 0;
  }
}

.sidebar nav ul li {
  cursor: pointer;
}

.sidebar nav ul li > a {
  border-radius: 7px 0 0 7px;
  -webkit-transition: background 0.2s ease-in, color 0.1s ease-in;
  -o-transition: background 0.2s ease-in, color 0.1s ease-in;
  transition: background 0.2s ease-in, color 0.1s ease-in;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #c0beff;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-right: 0px;
}

.sidebar nav ul li > a .icon {
  margin-right: 10px;
  display: inline-block;
  margin-left: 25px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar nav ul li > a .icon path {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar nav ul li > a .icon rect {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar nav ul li > a .icon ~ span {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar nav ul li.active > a {
  background-color: #f6f8fc;
  color: #333269;
}

.sidebar nav ul li.active > a path {
  fill: #333269;
}

.sidebar nav ul li.active > a rect {
  stroke: #333269;
}

.sidebar nav ul li.active > a .settings path {
  fill: none;
  stroke: #333269;
}

.sidebar nav li.parent-active > a,
.sidebar nav ul li > a:hover {
  background-color: #f6f8fc;
  color: #333269;
}

.sidebar nav ul li > a:hover path {
  fill: #333269;
}

.sidebar nav ul li > a:hover rect {
  stroke: #333269;
}

.sidebar nav ul li > a:hover .settings path {
  fill: none;
  stroke: #333269;
}

.sidebar nav ul:not(.child-list) {
  padding: 10px 0 30px 10px;
  position: relative;
  z-index: 33;
}

@media (max-width: 1199.98px) {
  .sidebar nav ul:not(.child-list) {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar nav ul:not(.child-list):first-of-type {
    margin-bottom: 1px;
  }
}

.sidebar nav ul:not(.child-list) > li {
  background-color: #333269;
}

.sidebar nav ul:not(.child-list) > li.hover {
  -webkit-animation: disable-pointer-events 0.8s;
  animation: disable-pointer-events 0.8s;
}

.sidebar nav li.parent-active ul.child-list,
.sidebar nav ul:not(.child-list) > li.active .child-list,
.sidebar nav ul:not(.child-list) > li:hover .child-list {
  margin-top: 0;
  visibility: visible;
  max-height: 300px;
}

.sidebar nav ul:not(.child-list) > li > a {
  height: 46px;
}

.sidebar nav ul:not(.child-list) > li:not(:last-of-type) {
  margin-bottom: 15px;
}

.sidebar nav ul.child-list {
  margin-top: 0;
  width: auto;
  margin-left: auto;
  visibility: hidden;
  -webkit-transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
  -o-transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
  transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
  padding-left: 40px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s;
}

.sidebar nav ul.child-list.active {
  margin-top: 0;
  visibility: visible;
}

.sidebar nav ul.child-list li {
  color: #333269;
}

.sidebar nav ul.child-list li.active a::before,
.sidebar nav ul.child-list li:hover a::before {
  background-color: #333269;
}

.sidebar nav ul.child-list li a {
  height: 30px;
  font-size: 13px;
  line-height: normal;
  padding-left: 15px;
}

.sidebar nav ul.child-list li a::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #c0beff;
  margin-right: 10px;
  display: inline-block;
  margin-left: 7%;
  position: absolute;
  opacity: 0;
}

.sidebar nav ul.child-list li:first-of-type {
  margin-top: 5px;
}

.sidebar nav ul.child-list li:not(:last-of-type) {
  margin-bottom: 15px;
}

.sidebar.close {
  margin-left: -125px;
  width: 191px;
}

.sidebar.close #btn {
  -webkit-transform: rotate(0.5turn);
  -ms-transform: rotate(0.5turn);
  transform: rotate(0.5turn);
  margin-right: 30px;
}

.sidebar.close .logo {
  max-width: 0;
}

.sidebar.close nav {
  padding-top: 20px;
}

@media (max-width: 1199.98px) {
  .sidebar.close nav {
    padding-top: 5px;
  }
}

.sidebar.close ul:not(.child-list) {
  margin: 0 !important;
  padding: 0;
}

.sidebar.close ul:not(.child-list):last-of-type {
  margin-top: 15px !important;
}

.sidebar.close ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.sidebar.close ul li a {
  padding: 7px 15px;
  margin-right: 9px;
  border-radius: 7px !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.sidebar.close ul li a .icon {
  margin: 0;
}

.sidebar.close ul li a .icon ~ span {
  display: none;
}

.sidebar.close ul li .child-list {
  display: none;
}

.fs-8 {
  font-size: 0.8rem;
}

.fs-7 {
  font-size: 0.9rem;
}

.users-edit-popup {
  max-height: 96vh;
}

.users-edit-popup .popup-box {
  width: 550px !important;
}

.users-edit-popup .popup-box h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0e1566;
  padding: 25px 40px 20px;
  border-bottom: 1px solid #cbd4e1;
  margin: 0;
  text-align: center;
}

.users-edit-popup .popup-box .popup-body {
  padding: 30px 40px 40px;
  margin: 0;
}

.users-edit-popup .popup-box .popup-body label {
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.users-edit-popup .popup-box .popup-body label span {
  color: #ff3333;
  font-weight: bold;
}

.users-edit-popup .popup-box .popup-body input,
.users-edit-popup .popup-box .popup-body select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 37.83px;
  color: #0f1a2a;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  margin: 10px 0 20px;
  padding: 0 10px;
}

.users-edit-popup .popup-box .popup-body input:focus,
.users-edit-popup .popup-box .popup-body select:focus {
  outline: none;
  border: none;
}

.users-edit-popup .popup-box .popup-bottons {
  text-align: right;
  margin-top: 20px;
}

.users-edit-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.users-invite-popup .popup-box .popup-footer {
  position: relative;
}

.users-invite-popup .popup-box .popup-footer img {
  position: absolute;
  left: -30px;
  bottom: 0;
  max-width: 100%;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons {
  text-align: right;
  margin-top: 20px;
  width: -webkit-calc(100% - 92px);
  width: calc(100% - 92px);
  margin-left: auto;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-invite-popup
.popup-box
.popup-footer
.popup-bottons
button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup
.popup-box
.popup-footer
.popup-bottons
button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-invite-popup
.popup-box
.popup-footer
.popup-bottons
button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup
.popup-box
.popup-footer
.popup-bottons
button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.general-user-confirm-popup {
  position: relative;
  display: flex;
  background: rgba(10, 4, 28, 0.36);
}

.general-user-confirm-popup .popup-box {
  min-width: 300px;
  max-width: 90%;
  width: 530px;
  background: #ffffff;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 40px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.general-user-confirm-popup .popup-box h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 25px;
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons {
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: auto;
  min-width: 140px;
  height: 32px;
  line-height: 22px;
  border: none;
  margin: 5px 10px;
}

.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup
.popup-box
.popup-bottons
button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type {
  background: #419e6a;
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup
.popup-box
.popup-bottons
button:last-of-type:hover {
  background-color: #1f8c4d;
}

.tag-label {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.sidebar nav ul > li li > a.router-link-exact-active {
  border-radius: 7px 0 0 7px;
  background-color: transparent !important;
  color: #fff !important;
  font-weight: bold;
}

.sidebar nav > ul > li > a.router-link-exact-active {
  border-radius: 7px 0 0 7px;
}

.sidebar.close {
}
</style>
