import {createApp} from 'vue'
import VueAxios from 'vue-axios'
import Toaster from "@meforma/vue-toaster";
import axios from 'axios'
import router from './router'
import store from './store'
import mitt from 'mitt';
import VueApexCharts from 'vue3-apexcharts'
import services from './services'
import i18n from './i18n'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './assets/css/lib/bootstrap.min.css'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import './assets/css/style.css'

window.Bus = mitt()
const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(store)
    .use(ElementPlus, {zIndex: 99999999})
    .use(VueApexCharts)
    .use(Toaster, {
        position: "top-right",
        duration: 5000
    })
    .use(services)
    .use(VueAxios, axios)
app.component('Datepicker', Datepicker);
app.mount('#app')
